import React from "react";
import { Route, Redirect } from "react-router";
import SideBar from "../layouts/sidebar/components/SideBar";
import "./Dashlayout.css";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

const Layout = ({ children }) => {
  return (
    <>
      <SideBar />
      <main className="main">{children}</main>
    </>
  );
};

const ProtectedRoutes = ({ component: Component, auth: {isAuthenticated}, ...rest }) => (
  
  <Route {...rest} render={(props) => (
    isAuthenticated
          ? <Layout>
            <Component {...props} />
          </Layout>
          : <Redirect to='/signin' />
  )} />
)

ProtectedRoutes.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps) (ProtectedRoutes);
