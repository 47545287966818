import React, { useEffect } from "react";
import nigeriaflag from "../../asset/nigeria-flag-country.png";
import "../styles/balance.css";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../js/actions/profile";
import ghanaflag from "../../asset/ghana.png";
import kenyaflag from "../../asset/kenya.jpg";

const Balance = ({ getProfile, profile }) => {
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  useEffect(() => {
    getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section className="balance">
      <div className="">
        <h4>Wallet Balance</h4>

        {userCountry === "nigeria" && (
          <p className="wallet-amount wallet-balance">
            ₦ {profile.profile.wallet}.00
          </p>
        )}
        {userCountry === "kenya" && (
          <p className="wallet-amount wallet-balance">
            K {profile.profile.wallet}.00
          </p>
        )}
        {userCountry === "ghana" && (
          <p className="wallet-amount wallet-balance">
            ₵ {profile.profile.wallet}.00
          </p>
        )}
        <div className="countryflag">
        {userCountry === "nigeria" && (
          <img src={nigeriaflag} className="img-fluid" alt="nigeria flag" />)}
          {userCountry === "ghana" && (
          <img src={ghanaflag} className="img-fluid" alt="ghana flag" />)}
          {userCountry === "kenya" && (
          <img src={kenyaflag} className="img-fluid" alt="kenya flag" />)}
        </div>
      </div>
    </section>
  );
};

Balance.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(Balance);
