import React, {useEffect} from "react";
import useGlobalContext from "../custom-hook/Context";
import LeaderBoardMain from "../content/leaderboard/components/LeaderBoardMain";

const LeaderBoard = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return (
    <>
      <LeaderBoardMain />
    </>
  );
};

export default LeaderBoard;
