
export const tradeData = [
  {
    height: "14",
    color: "#1B1C1D",
    month: "Jan",
    amount: "",
  },
  {
    height: "14",
    color: "#EFEFEF",
    month: "Feb",
    amount: "",
  },
  {
    height: "60",
    color: "#1B1C1D",
    month: "Mar",
    amount: "$1,000,000",
  },
  {
    height: "6",
    color: "#1B1C1D",
    month: "Apr",
    amount: "",
  },
  {
    height: "14",
    color: "#1B1C1D",
    month: "May",
    amount: "",
  },
];

export const countryList = [
  {
    id: 1,
    name: "USD",
    country: "USA",
    currency: "USD",
    value: "usd",
    // icon: `${usa}`,
    selected: false,
   
  },
  {
    id: 2,
    name: "GBP",
    country: "UK",
    currency: "GBP",
    selected: false,
    value: "gbp",
    // icon: `${unitedKing}`,
  },
  {
    id: 3,
    name: "EUR",
    country: "Euro",
    currency: "EUR",
    selected: false,
    value: "eur",
    // icon: `${euro}`,
  },
  {
    id: 4,
    name: "ESP",
    country: "Spain",
    currency: "ESP",
    selected: false,
    value: "esp",
    // icon: `${unitedKing}`,
  },
  {
    id: 5,
    name: "AUD",
    country: "Australia",
    currency: "AUD",
    selected: false,
    value: "aud",
    // icon: `${unitedKing}`,
  },
  {
    id: 6,
    name: "CAD",
    country: "Canada",
    currency: "CAD",
    selected: false,
    value: "cad",
    // icon: `${canada}`,
  },
];


export const cardTypeList = [
  {
    id: 1,
    value: "Physical",
  },
  {
    id: 2,
    value: "E-Code",
  },
];

export const cardReceiptList = [
  {
    id: 1,
    value: "0",
    text:"Yes",
  },
  {
    id: 2,
    value: "1",
    text: "No"
  } 
];
