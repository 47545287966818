import React, {useEffect} from "react";
import useGlobalContext from "../custom-hook/Context";
import NotificationsMain from "../content/notification/components/NotificationsMain";

const Notifications = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return (
    <div>
      <NotificationsMain />
    </div>
  );
};

export default Notifications;
