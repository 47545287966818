import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/leader-board.css";
import LeaderList from "./LeaderList";
// import { LeaderboardTopTradersItemData } from "../data/traders";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getLeaderboardMonthlyData } from "../../js/actions/leaderboard";
import useGlobalContext from "../../custom-hook/Context";

const LeaderBoard = ({ leaderboardMonthlyList, getLeaderboardMonthlyData }) => {
  useEffect(() => {
    getLeaderboardMonthlyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { mediumDevice } = useGlobalContext();
  const currentLocation = window.location.href.includes("/transactions");
  return (
    <section
      className="card leader-board"
      style={{
        marginBottom: currentLocation && mediumDevice ? "6.25rem" : 0,
      }}
    >
      <header className="leader-header">
        <h3>Leaderboard</h3>
        <button className="btn">
          Trade
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="7.41"
            viewBox="0 0 12 7.41"
          >
            <path fill="#1b1c1d" d="M1.41 0 6 4.58 10.59 0 12 1.41l-6 6-6-6Z" />
          </svg>
        </button>
      </header>
      <div className="card-body">
        {leaderboardMonthlyList && leaderboardMonthlyList.length < 1 && (
          <p>Loading...</p>
        )}
        {leaderboardMonthlyList && leaderboardMonthlyList.length > 0 && (
          <ul className="leader-list">
            {leaderboardMonthlyList &&
              Array.from(leaderboardMonthlyList)
                .slice(0, 5)
                .map((data, index) => {
                  const { profile_pics, username, points, rank } = data;

                  return (
                    <LeaderList
                      key={index}
                      rank={rank}
                      profile_pics={profile_pics}
                      username={username}
                      points={points}
                    />
                  );
                })}
          </ul>
        )}
      </div>
      <div className="custom-card-footer leader-footer">
        <Link to="/leaderboard">See all</Link>
      </div>
    </section>
  );
};

LeaderBoard.propTypes = {
  getLeaderboardMonthlyData: PropTypes.func.isRequired,
  leaderboardMonthlyList: PropTypes.array,
};

const mapStateToProps = state => ({
  leaderboardMonthlyList: state.leaderboard.leaderboardMonthlyList,
});

export default connect(mapStateToProps, { getLeaderboardMonthlyData })(
  LeaderBoard
);
