import React, {useEffect} from "react";
import useGlobalContext from "../../../custom-hook/Context";
// import { LeaderboardTopTradersItemData } from "../../data/traders";
import LeaderboardTradersItemMobile from "./LeaderBoardTradersItemMobile";
import LeaderboardSecondTrader from "./LeaderboardSecondTrader";
import LeaderboardThirdTrader from "./LeaderboardThirdTrader";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getLeaderboardMonthlyData } from "../../../js/actions/leaderboard";

const LeaderBoardTopTradersMobile = ({ leaderboardMonthlyList, getLeaderboardMonthlyData}) => {
  useEffect(() => {
    getLeaderboardMonthlyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { largeDevice } = useGlobalContext();
  return (
    <div style={{ display: `${largeDevice ? "block" : "none"}` }}>
      <div>
        <ul className="leaderboardmobile__ul">
        {leaderboardMonthlyList &&  Array.from(leaderboardMonthlyList).slice(1, 2).map((data, index) => {
             const { profile_pics, username, points, rank } = data;
            return (
              <LeaderboardSecondTrader
              key={index}
              profile_pics={profile_pics}
              username={username}
              points={points}
              rank={rank}
              />
            );
          })}
          {leaderboardMonthlyList &&  Array.from(leaderboardMonthlyList).slice(0, 1).map((data, index) => {
            const { profile_pics, username, points, rank } = data;
            return (
              <LeaderboardTradersItemMobile
              key={index}
              profile_pics={profile_pics}
              username={username}
              points={points}
              rank={rank}
              />
            );
          })}
           
            {leaderboardMonthlyList &&  Array.from(leaderboardMonthlyList).slice(2, 3).map((data, index) => {
           const { profile_pics, username, points, rank} = data;
            return (
              <LeaderboardThirdTrader
              key={index}
              profile_pics={profile_pics}
               username={username}
                points={points}
                rank={rank}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};


LeaderBoardTopTradersMobile.propTypes = {
  getLeaderboardMonthlyData: PropTypes.func.isRequired,
  leaderboardMonthlyList: PropTypes.array,

};

const mapStateToProps = state => ({
  leaderboardMonthlyList: state.leaderboard.leaderboardMonthlyList,

});

export default connect(mapStateToProps, { getLeaderboardMonthlyData })(
  LeaderBoardTopTradersMobile);
