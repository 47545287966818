import React, { useEffect } from "react";
import useGlobalContext from "../custom-hook/Context";
import TransactionMain from "../content/transaction/component/TransactionMain";

const Transactions = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return (
    <>
      <TransactionMain />
    </>
  );
};

export default Transactions;
