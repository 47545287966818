import React, {useEffect} from "react";
import useGlobalContext from "../custom-hook/Context";
import SetPinMain from "../content/set-pin/components/SetPinMain";

const SetPin = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return (
    <div>
      <SetPinMain />
    </div>
  );
};

export default SetPin;
