import React from "react";

const Verified = () => {
  return (
    <div className="verification">
      <p>Please Check Your Email Box To Complete Registration</p>
    </div>
  );
};

export default Verified;
