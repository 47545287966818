import React from "react";
// Components
// import MainHeader from "../../../header/components/MainHeader";
import LeaderBoard from "../../component/LeaderBoard";
// import { leaderBoardData1 } from "../../data/mainContentData";

// Styles
import "../../styles/home-main.css";
import "../styles/giftcard-transaction-history.css";

import MainHeader from "../../../layouts/header/components/MainHeader";
import HistoryHeader from "../../../content/component/HistoryHeader";

// Assets
// import user from "../../../asset/user-image x2.png";
import GiftcardTransactionHistory from "./GiftcardTransactionHistory";
// import TradeVolume from "../../component/TradeVolume";

function TransactionMain() {
  return (
    <main className="main">
      {/* <MainHeader 
        title="disciple"
        titleBtn="Quick trade to upgrade"
        user={user}
      /> */}
      <MainHeader />
      <HistoryHeader />
      <div className="main-content row">
        {/* Transaction History */}
        <section className="col-12 col-xl-8">
          <GiftcardTransactionHistory />
        </section>
        <section className="col-12 col-xl-4">
          <div className="row">
            <div className="col-12 col-md-6 col-xl-12 mt-0 mt-lg-3 mt-xl-0">
              <LeaderBoard
                // leaderData={leaderBoardData1}
                // viewLink="View leaderboard"
              />
            </div>
            <div className="col-12 col-md-6 col-xl-12">
              {/* <TradeVolume /> */}
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default TransactionMain;
