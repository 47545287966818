
import folder from "../../asset/ic_folder_24px.png";
import camera from "../../asset/ic_photo_camera_24px.png";
import acctLogo from "../../asset/ic_account_circle_24px.png";
import checkedIcon from "../../asset/successful-tick.svg";
import Avatar1 from "../../asset/redem-avatars/Beginner.png";
import Avatar2 from "../../asset/redem-avatars/Novice.png";
import icon from "../../asset/Group 162497.svg";


export const avatarData = [

  {
    id: 1,
    checked: false,
    image: `${Avatar1}`,
    label: "Beginner",
    status: "Unlocked",
    icon: `${icon}`,
    selected: {
      img: `${checkedIcon}`
    }
  },

  {
    id: 2,
    checked: false,
    image: `${Avatar2}`,
    label: "Novice",
    status: "Unlocked",
    icon: `${icon}`,
    selected: {
      img: `${checkedIcon}`
    }
  }]
export const imageActionData = [
  {
    id: 1,
    name: "Use camera",
    image: `${camera}`
  },
  {
    id: 2,
    name: "Select from folder",
    image: `${folder}`
  },
  {
    id: 3,
    name: "Redem avatars",
    image: `${acctLogo}`
  }
]