import React, {useEffect} from "react";
import useGlobalContext from "../custom-hook/Context";
import TransferMain from "../content/transfer/components/TransferMain";


const Transfer = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return (
    <>
      <TransferMain />
    </>
  );
};

export default Transfer;
