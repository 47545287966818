import React, { useEffect, useState } from "react";
import useGlobalContext from "../../../custom-hook/Context";
import Select from "../../../custom-hook/Select";
import ReceiptSelect from "../../../custom-hook/ReceiptSelect";
import {
  countryList,
  cardReceiptList,
  cardTypeList,
} from "../../data/mainContentData";
import info from "../../../asset/info-icon.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getGiftcardsList } from "../../../js/actions/giftcards";
import arrowDown from "../../../asset/ic_keyboard_arrow_down_24px.svg";
import { useLocation } from "react-router-dom";
// import empty from "../../../asset/empty giftcard holder.png";
function GiftCardFormDetails({ card, next, getGiftcardsList, giftcardsList }) {
  const location = useLocation();

  const { formData, setFormData, chooseCountry } = useGlobalContext();
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  const [domCountries, setDomCountries] = useState(countryList);
  const currencyRate = Number(JSON.parse(localStorage.getItem("currencyRate")));

  useEffect(() => {
    getGiftcardsList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(giftcardsList);
  const handleSelectedDenomination = e => {
    let card = giftcardsList.find(item => item.id === Number(e.target.value));
    let rate = card.rates;
    let cardCategory = card.category;
    let cardSubCategory = card.subcategory;
    let receipt = card.receipt;
    setFormData(e.target.value);
    setFormData({ ...formData, rate, cardCategory, cardSubCategory, receipt });
  };

  const handleValueChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const selectedCountry = domCountries.find(country => country.selected);
    formData.country = selectedCountry;
  }, [formData, domCountries]);

  const handleClick = id => {
    chooseCountry(id, { domCountries, setDomCountries });
    let currency = domCountries.find(item => item.id === id);

    let customCardList = giftcardsList.filter(
      item =>
        item.country.toLowerCase() === currency.country.toLowerCase() &&
        item.category.toLowerCase() === formData.card.name.toLowerCase()
    );
    setCardList(customCardList);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const inputValue = parseFloat(formData.amount);

    if (!formData.amount || isNaN(inputValue)) {
      return;
    }

    setFormData({ ...formData, card: card });
    next();
  };
  // console.log(card)
  useEffect(() => {
    // console.log(card);
  }, [card]);

  const cardDetails = card ? (
    <div className="card-details">
      <h4>{card.name || card?.identifier} giftcard</h4>
      <div className="card-details-img-container card__details__con">
        <img
          src={
            card.image ||
            require(`../../../asset/giftcards/${card.identifier.toLowerCase()}.png`)
          }
          alt={card.name}
          className="card-details-image"
        />
      </div>
    </div>
  ) : (
    <h4>Loading...</h4>
  );

  const [cardList, setCardList] = useState(null);

  return (
    <div className="giftcard-details">
      <section>{cardDetails}</section>
      <form onSubmit={handleSubmit} className="giftcard_form">
        {!location?.state?.hottestrateslist && (
          <div className="form-group">
            {/* <SelectCountry list={countryList} values={countriesValueList} /> */}
            <div className="countries">
              <h4>Select country</h4>
              <article className="country-list">
                {domCountries?.map(country => {
                  const { id, name, selected } = country;
                  return (
                    <button
                      key={id}
                      disabled={location?.state?.hottestrateslist}
                      type="button"
                      id={id}
                      style={{
                        backgroundColor: `${
                          location?.state?.hottestrateslist
                            ? "rgb(255, 255, 255)"
                            : selected
                            ? "#1b1c1d"
                            : "#ffffff"
                        }`,
                        color: `${selected ? "#ffffff" : "#1b1c1d"}`,
                      }}
                      onClick={() => handleClick(id)}
                    >
                      {name}
                    </button>
                  );
                })}
              </article>
            </div>
          </div>
        )}
        <div className="card-options">
          <div>
            <Select
              name="type"
              icon={info}
              label="Card type"
              value={formData.type}
              onSelect={handleValueChange}
              list={cardTypeList}
            />
          </div>
          <div>
            <ReceiptSelect
              name="receipt"
              label="Card has receipt?"
              value={formData.receipt}
              onSelect={handleValueChange}
              list={cardReceiptList}
            />
          </div>
        </div>
        <div className="form-group denomination">
          {/* <Select
            name="denomination"
           
            onSelect={handleValueChange}
            list={denominationList}
          /> */}

          <label htmlFor="denomination">Select denomination</label>
          {location?.state?.hottestrateslist ? (
            <>
              {/* <label htmlFor="denomination">Select denomination</label> */}
              <input
                readOnly
                value={card?.subcategory || ""}
                className="amount-input"
              />
            </>
          ) : (
            <>
              <div className="form-group denomination">
                <select
                  name="denomination"
                  label="Select denomination"
                  value={formData.category}
                  onChange={handleSelectedDenomination}
                >
                  <option value="" hidden>
                    select item
                  </option>
                  {cardList &&
                    cardList.map(denomation => {
                      const { category, id, subcategory } = denomation;
                      return (
                        <option key={id} value={id}>
                          {category} [{subcategory}]
                        </option>
                      );
                    })}
                </select>
                <img src={arrowDown} alt="" />
              </div>
            </>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="">Enter amount</label>
          <input
            type="number"
            name="amount"
            className="amount-input"
            value={formData.amount}
            placeholder="$0.00"
            onChange={handleValueChange}
            required
          />

          <p className="rate">
            <span className="rate-note">Current rate</span>

            {userCountry === "nigeria" && (
              <span className="rate-value">{`₦${formData.rate}/$`}</span>
            )}
            {userCountry === "kenya" && (
              <span className="rate-value">{`K${Math.round(
                formData.rate * currencyRate
              )}/$`}</span>
            )}
            {userCountry === "ghana" && (
              <span className="rate-value">{`₵${Math.round(
                formData.rate * currencyRate
              )}/$`}</span>
            )}
          </p>

          <label htmlFor="">Total Equivalent</label>
          <input
            type="number"
            name="amount"
            className="amount-input"
            placeholder="0.00"
            value={formData.amount * formData.rate}
            // {`₦${formData.amount * 150}`}
            disabled
            // onChange={handleValueChange}
            // required
          />
        </div>
        <section>
          <button type="submit" className="giftcard-btn">
            proceed
          </button>
        </section>
      </form>
    </div>
  );
}

GiftCardFormDetails.propTypes = {
  getGiftcardsList: PropTypes.func,
  giftcardsList: PropTypes.array,
};
const mapStateToProps = state => ({
  giftcardsList: state.giftcards.giftcardsList,
});

export default connect(mapStateToProps, { getGiftcardsList })(
  GiftCardFormDetails
);
