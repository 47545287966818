import React, { useState } from "react";
import { tollData } from "../../data/subscriptionData";
import { Link } from "react-router-dom";

import "./Accordion.css";

const TollAccordion = ({ logo, text, icon }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="house" onClick={() => setIsActive(!isActive)}>
        <div className="house__image">
          <img src={logo} alt="" className="house__image-image" />
        </div>

        <div className="house__title">
          <p className="house-title">{text}</p>
        </div>

        <div className="house__icon">
          <div className="house__image">
            <img src={icon} className="house__image-image" alt="" />
          </div>
        </div>
      </div>
      {isActive && (
        <div className="accordion-content">
          {tollData.map((data, index) => {
            const { icon, provider} = data;

            return (
              <Link
              to={{
                pathname: `/toll-details/${data.id}`,
                state: {
                  icon,
                },
              }}
              className="airtime__transaction__body__link"
              key={index}
            >
                <div className="airtime__transaction__body">
                  <div className="airtime__transaction__body__image">
                    <img src={icon} alt="" />
                  </div>
                  <div className="airtime__transaction__sub__body">
                    <h3 className="airtime__transaction__sub__body__provider">
                      {provider}
                    </h3>
                  
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TollAccordion;
