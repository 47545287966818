import React from "react";
// import apple from "../../asset/apple.svg";
// import playstore from "../../asset/playstore.svg";
// import canada from "../../asset/canada-flag-country-nation-union-empire-32938@2x.png";
// import unitedKing from "../../asset/united-kingdom-flag-country-nation-union-empire-33115@2x.png";
// import euro from "../../asset/Euro Flag@2x.png";

const HottestRateItem = ({
  rates,
  subcategory,
  category,
  country,
  identifier,
}) => {
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  return (
    <li className="hottest-rate-item">
      <div className="appstore-icon-wrapper">
        <img
          src={require(`../../asset/giftcardshistory/${identifier.toLowerCase()}.png`)}
          className=""
          alt=""
        />
        {/* <img src={apple} className="img-fluid" alt="" /> */}
      </div>
      <div className="rating-app-store">
        <p>{category}</p>
        {/* EUR ($100 - $500) */}
        <p>
          {`${country}`}
          {`(${subcategory})`}
        </p>
      </div>
      <div className="rating-country">
        <div className="country-icon-wrapper">
          <img
            src={require(`../../asset/giftcards/${country.toLowerCase()}.png`)}
            className=""
            alt="card-img"
          />
        </div>

        {userCountry === "nigeria" && <p>{`₦ ${rates}/ $`}</p>}
        {userCountry === "kenya" && <p>{`K ${rates}/ $`}</p>}
        {userCountry === "ghana" && <p>{`₵ ${rates}/ $`}</p>}
      </div>
    </li>
  );
};

export default HottestRateItem;
