
        
export const internationalBanksData = [
    {
        bankcode: "1",
      bankname: "VODAFONE ",
    
    },
    {
        bankcode: "2",
      bankname: "MTN",
    
    },
    {
        bankcode: "3",
      bankname: "AIRTEL",
  
    },
    {
        bankcode: "4",
      bankname: "SAFARICOM",
   
    },
  ];
  

  
