import React, {useEffect} from "react";
import MainHeader from "../layouts/header/components/MainHeader";

//Styles
import useGlobalContext from "../custom-hook/Context";
import HottestRatesLottie from "../content/component/HottestRatesLottie";
import AllHottestRates from "../content/component/AllHottestRates";

const Transactions = text => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  // const { smallDevice } = useGlobalContext();
  // console.log(smallDevice);
  // custom-row
  return (
    <main className="main">
      <MainHeader text="Hottest Rates" lottie={<HottestRatesLottie />} />

      <div className="main-content ">
        <AllHottestRates />
      </div>
    </main>
  );
};

export default Transactions;
