import React, { useEffect } from "react";
import MainHeader from "../../../layouts/header/components/MainHeader";
import success from "../../../asset/success.svg";
import "../styles/wallet-history-main.css";
import useGlobalContext from "../../../custom-hook/Context";
import { useLocation } from "react-router-dom";
import Moment from "react-moment";
import failed from "../../../asset/danger.svg";

const WalletHistoryMain = () => {
  const { checkAuth, smallDevice } = useGlobalContext();
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  // const {reference} = useParams();
  const Location = useLocation();
  const backTrackBtn = smallDevice ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.18"
      height="8.39"
      viewBox="0 0 5.18 8.39"
    >
      <path
        fill="#1b1c1d"
        d="m5.18 7.4-3.2-3.2L5.18.99 4.2 0 0 4.2l4.2 4.19Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.16"
      height="11.6"
      viewBox="0 0 7.16 11.6"
    >
      <path
        fill="#1b1c1d"
        d="M7.16 10.24 2.74 5.8l4.42-4.44L5.8 0 0 5.8l5.8 5.8Z"
      />
    </svg>
  );

  // console.log(reference)
  return (
    <main className="main">
      <MainHeader
        text="Wallet History"
        backTrackBtn={backTrackBtn}
        buttonStyle={{
          alignSelf: "flex-start",
          width: "52px",
          height: "52px",
          backgroundColor: "#FDFDFD",
          border: "1px solid #1b1b1b",
          borderRadius: "50%",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
      <section className="wallet__history__section__main">
        <header className="wallet__history__section__main__header">
          <div className="wallet__history__section__main__header__first__section">
            <div className="wallet__history__section__main__header__first__section__image">
       
               <img
                src={require(`../../../asset/${Location.state?.bankcode}.svg`)}
                alt=""
                className=""
              /> 
            </div>
            <div className="wallet__history__section__main__header__first__section__text">
              <p>Withdrawal</p>
              <p>{Location.state?.bankname}</p>
            </div>
          </div>

          <div className="wallet__history__section__main__header__second__section">
            <p>
              <Moment format="DD/MM/YYYY">{Location.state?.created_at}</Moment>
            </p>
            <p>
              <Moment format="hh:mm a">{Location.state?.created_at}</Moment>
            </p>
          </div>
        </header>

        <div className="wallet__history__section__main__second__section">
          <section className="wallet__history__info">
            <h5>Transaction Info</h5>
            <div>
              <p>
                <span>From</span>
                <span>My wallet</span>
              </p>
              <p>
                <span>To</span>
                <span>{Location.state?.bankname}</span>
              </p>
              <p>
                <span>Amount</span>

                {userCountry === "nigeria" && (
                  <span> ₦{Location.state?.amount}.00</span>
                )}
                {userCountry === "kenya" && (
                  <span> K{Location.state?.amount}.00</span>
                )}
                {userCountry === "ghana" && (
                  <span> ₵{Location.state?.amount}.00</span>
                )}
              </p>
            </div>
          </section>
          <section className="wallet-history-feedback">
            <h5>Feedback</h5>
            {/* <div className="wallet-history-feedback-container"> */}
            <div
              className="wallet-history-feedback-msg"
              style={{
                backgroundColor: "successful"
                  ? "#f9fff9"
                  : "rgb(255, 247, 248)",
              }}
            >
              <div>
                <img
                  src={
                    Location.state?.status === "successful" ? success : failed
                  }
                  alt=""
                />
              </div>
              <p>
                {Location.state?.status === "successful" ? "success" : "failed"}{" "}
              </p>
            </div>
            {/* </div> */}
          </section>
        </div>
      </section>
    </main>
  );
};

export default WalletHistoryMain;
