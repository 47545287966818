import React, { useState, useEffect } from "react";
// import { useState } from "react/cjs/react.development";
// import { transactionData } from "../../data/mainContentData";
import SingleTransactionDetails from "./SingleTransactionDetails";
import TransactionDetailsModal from "./TransactionDetailsModal";
import TransactionItem from "./TransactionItem";
import close from "../../../asset/ic_close_24px.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getGiftcardsHistory } from "../../../js/actions/giftcards";
import useGlobalContext from "../../../custom-hook/Context";
import SingleTransactionHistoryMobile from "./SingleTransactionHistoryMobile";
// import SingleTransactionHistoryMobile from "./SingleTransactionHistoryMobile";
// import Moment from "react-moment";

const GiftcardTransactionHistory = ({
  getGiftcardsHistory,
  giftcardsHistory,
}) => {
  useEffect(() => {
    getGiftcardsHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { setTransactionHistoryMobile } = useGlobalContext();

  // eslint-disable-next-line no-unused-vars
  const [historyItems, setHistoryItems] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Change the active state of selected item
  const selectHistoryItem = reference => {
    const newItems = giftcardsHistory.map(item => {
      if (reference === item.reference) {
        return { ...item, activeOnClick: true };
      } else {
        return { ...item, activeOnClick: false };
      }
    });

    const filterActiveItem = giftcardsHistory.find(
      item => item.reference === reference
    );


    // console.log(selectHistoryItem)
    setHistoryItems(newItems);
    setActiveItem(filterActiveItem);
    setTransactionHistoryMobile(filterActiveItem);
    // console.log(filterActiveItem)
  };

  // console.log(filterActiveItem)
  // slice message
  //const message = activeItem?.detail?.moreInfo?.message;
  //const learnMoreIndex = activeItem?.detail?.moreInfo?.message.indexOf("Learn more");
  //const messageBefore = message?.slice(0, learnMoreIndex);
  //const messageAfter = message?.slice(learnMoreIndex);

  return (
    <section className="card transaction-content">
      <div className="card-header history-header">
        <h3>
          <span className="quick_span">Giftcard transaction history</span>
          <span className="quick giftcard_transaction_quick">
            Giftcard transaction
          </span>
        </h3>
        <button type="button" className="btn">
          All
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="7.41"
            viewBox="0 0 12 7.41"
          >
            <path fill="#1b1c1d" d="M1.41 0 6 4.58 10.59 0 12 1.41l-6 6-6-6Z" />
          </svg>
        </button>
      </div>
      <div className="card-body">
        {giftcardsHistory && giftcardsHistory.length < 1 && <p>No giftcard history</p>}
        {giftcardsHistory && giftcardsHistory.length > 0 && (
          <div className="row">
            <div className="col-12 col-md-6 giftcard__mobile">
              <section className="history-list">
                {giftcardsHistory?.map((data, index) => {
                  const {
                    created_at,
                    cardType,
                    reference,
                    category,
                    naira,
                    dollar,
                    rate,
                    country,
                    status,
                    badImages,
                    comment,
                  } = data;
                  const newObj = giftcardsHistory.map(item => ({
                    ...item,
                    activeOnClick: false,
                  }));
                  return (
                    <TransactionItem
                      key={index}
                      id={reference}
                      created_at={created_at}
                      cardType={cardType}
                      category={category}
                      naira={naira}
                      dollar={dollar}
                      active={newObj}
                      rate={rate}
                      country={country}
                      status={status}
                      badImages={badImages}
                      comment={comment}
                      selectHistoryItem={selectHistoryItem}
                    />
                  );
                })}
              </section>
            </div>
            <div className="col-12 col-md-6 transaction-details">
              <h4 className="item-cta">Select transaction to see details</h4>
              <SingleTransactionDetails
                details={activeItem}
                setShowModal={setShowModal}
              />
              <SingleTransactionHistoryMobile
                showModal={showModal}
                setShowModal={setShowModal}
              />
              <TransactionDetailsModal
                details={activeItem}
                showModal={showModal}
                setShowModal={setShowModal}
                closeImg={close}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

GiftcardTransactionHistory.propTypes = {
  getGiftcardsHistory: PropTypes.func,
  giftcardsHistory: PropTypes.array,
};
const mapStateToProps = state => ({
  giftcardsHistory: state.giftcards.giftcardsHistory,
});

export default connect(mapStateToProps, { getGiftcardsHistory })(
  GiftcardTransactionHistory
);
