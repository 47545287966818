import React from "react";
import { CoverImagesItemData } from "../../data/avatars";
import "../styles/avatar-images.css";
import CoverImagesItem from "./CoverImagesItem";

const CoverImages = () => {
  return (
    <section className="avatar__list__main">
      <div className="avatar__list__main__section">
        <ul className="cover__list">
          {CoverImagesItemData.map((data, index) => {
            const { image } = data;
            return <CoverImagesItem key={index} image={image} />;
          })}
        </ul>
      </div>
    </section>
  );
};

export default CoverImages;
