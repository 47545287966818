import data from "../../asset/data.svg";
import bills from "../../asset/bills.svg";

export const quickSellerData = [
  {
    id:1,
    icon: `${data}`,
    text: "Airtime & Data",
    
  },
  {
    id:2,
    icon: `${bills}`,
    text: "Pay Bills",
   
  }
]