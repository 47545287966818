import React from "react";
import PropTypes from "prop-types";
import useGlobalContext from "../../custom-hook/Context";
import "../styles/form-progress.css";

const FormProgress = ({ step, first, second, third }) => {
  const { smallDevice } = useGlobalContext();

  const checked = () => {

    return ( smallDevice ? (
      <svg xmlns="http://www.w3.org/2000/svg" width="11.18" height="8.52" viewBox="0 0 11.18 8.52">
        <path fill="#fdfdfd" d="M3.55 6.72.9 4.07l-.9.9 3.55 3.56L11.18.9l-.9-.9Z"/>
      </svg>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.14" height="13.06" viewBox="0 0 17.14 13.06">
          <path fill="#fdfdfd" d="M5.45 10.31 1.38 6.24 0 7.62l5.45 5.44L17.13 1.37 15.76 0Z"/>
        </svg>
      )
    );
  };

  const firstStep = (iconFunc) => {
    return iconFunc();
  };

  const secondStep = (iconFunc) => {
    if(step >= 2) {
      return iconFunc();
    } else {
      return "2";
    }
  };

  const thirdStep = (iconFunc) => {
    if(step >= 3) {
      return iconFunc();
    } else {
      return "3";
    }
  }

  return (
    <div className="form-progress">
      <div>
        {first && <p>{first}</p>}
        {second && <p className="center-text">{second}</p>}
        {third && <p>{third}</p>}
      </div>
      <div className="progress-step">
        <div>
          <div 
            className="step" 
            style={{
              backgroundColor: `${step === 1 ? "#1b1b1b" : "#C2C2C2"}`
            }}
          >
            <span>{firstStep(checked)}</span>
          </div>
        </div> 
        <div className="center">   
          <div 
            className="step"
            style={{
              backgroundColor: `${step === 2 ? "#1b1b1b" : step < 2 ? "#FFFFFF" : "#C2C2C2"}`
            }}
          > 
            <span>{secondStep(checked)}</span>
          </div>
        </div>
        <div> 
          <div 
            className="step"
            style={{
              backgroundColor: `${step === 3 ? "#1b1b1b" : "#FFFFFF"}`
            }}
          > 
            <span>{thirdStep(checked)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

FormProgress.propTypes = {
  step: PropTypes.number.isRequired,
  first: PropTypes.string.isRequired,
  second: PropTypes.string.isRequired,
  third: PropTypes.string.isRequired
}

export default FormProgress;