import React from "react";
import CardItem from "./CardItem";

const CardBoxes = ({ cards }) => {

  return (
    <>
      {
        cards?.map(card => {
          const {id, name, icon, image, bgcolor} = card;

          return (
            <CardItem 
              key={id}
              id={id}
              name={name}
              icon={icon}
              img={image}
              bgcolor={bgcolor}
            />
          )
        })
      }
    </>
  )
}

export default CardBoxes;