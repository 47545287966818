import React, { useState, useEffect } from "react";
import "../styles/tags-input.css";
import useGlobalContext from "../../../custom-hook/Context";

const TagsInput = ({ setShowModal }) => {
  const { formData, setFormData } = useGlobalContext();
  const [tags, setTags] = useState([]);

  // function handleKeyDown(e) {
  //   if (e.key !== "Enter") return;
  //   const giftcardValue = e.target.value;
  //   if (!giftcardValue.trim()) return;
  //   setTags([...tags, giftcardValue]);
  //   e.target.value = "";
  //   setFormData({...formData, tags});

  //   console.log(tags)
  //   console.log(giftcardValue)
  //   console.log(formData.tags)
  // }

  // function validateAlphaNumeric(e) {
  //   var regEx = /^[0-9a-zA-Z]+$/;
  //   // console.log(e.value)
  //   if (e.key.match(regEx)) {
  //     return true;
  //   } else {
  //     return false;
  //   }

  // }
  const specialCharacters =   [
    ".",  "+", "-", " `", " `", "~", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+",  "=", "|","}", "{", ":", "?",",", "]", "[", ";",
  ]
    const validateEcodeInput = e => {
      if (
        specialCharacters.includes(e.key)
      ) {
        e.preventDefault();
      }
    };

  function handleKeyDown(e) {
    // console.log(e.key)
    validateEcodeInput(e);
    if (e.key !== "Enter") return;
    const giftcardValue = e.target.value;
    if (!giftcardValue.trim()) return;
    setTags([...tags, giftcardValue]);
    e.target.value = "";
  }

  useEffect(
    () => {
      setFormData({ ...formData, tags });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tags]
  );
  // console.log(formData.tags)

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index));
  }
  return (
    <>
      <div className="tags-input-container">
        {tags.map((tag, index) => (
          <div className="tag-item" key={index}>
            <span className="text">{tag}</span>
            <span className="close" onClick={() => removeTag(index)}>
              &times;
            </span>
          </div>
        ))}
        <input
          onKeyDown={handleKeyDown}
          type="text"
          className="tags-input"
          placeholder="Enter E-Code"
        />
      </div>
      <div className="ecode__details__section__button__section">
        <button
          type="button"
          className="ecode__details__section__button"
          onClick={() => setShowModal(false)}
          // onClick={() => handleInput()}
        >
          Proceed
        </button>
      </div>
    </>
  );
};

export default TagsInput;
