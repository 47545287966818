import React from "react";
import useGlobalContext from "../../../custom-hook/Context";
import camera from "../../../asset/ic_photo_camera_24px.png";
import acctLogo from "../../../asset/ic_account_circle_24px.png";

import "../style/user-avatar.css";

// Regex for checking file extension
const fileRegex = /(png|jpe?g)$/;

const UserAvatarOptions = ({actionHandler}) => {
  const { openCam, dispatchProfile } = useGlobalContext();

  const handleImgUpload = (e) => {
    const imageFile = e.target.files[0];

    const imageURL = URL.createObjectURL(imageFile);

    if(!fileRegex.test(imageFile.name) || (imageFile.size > 500000)) {
      return;
    } 
    dispatchProfile({ 
      type: "UPLOAD_IMAGE", 
      payload: {name: imageFile.name, image: imageURL} 
    })

    // console.log(fileRegex.test(imageFile.name))
  }

  return (
    <section className="avatar-options">
      <section onClick={openCam}>
        <img src={camera} alt="" />
        <label htmlFor="">Use camera</label>
      </section>
      <section>
        <input 
          type="file" 
          className="avatar-input" 
          name="" 
          id="" 
          onChange={handleImgUpload}
        />
        <label htmlFor="">Select from folder</label>
      </section>
      <section onClick={actionHandler}>
        <img src={acctLogo} alt="" />
        <label htmlFor="">Redem avatars</label>
      </section>
    </section>
  )
};

export default UserAvatarOptions;