import React, { useEffect } from "react";
import useGlobalContext from "../custom-hook/Context";
import HelpMain from "../content/help/components/HelpMain";
import { IntercomProvider } from "react-use-intercom";

const Help = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  const INTERCOM_APP_ID = "qk1bmgoi";
  return (
    <div>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        <HelpMain />
      </IntercomProvider>
    </div>
  );
};

export default Help;
