import axios from '../../axios';
import { GIFTCARDS_LIST, GIFTCARDS_HISTORY, PENDING_GIFTCARDS_TRADES} from '../constants/action-types';


export const getGiftcardsList = () => async dispatch => {
    try {
        const response  = await axios.get("/rd/v1/giftcard/cards");
        dispatch({
            type: GIFTCARDS_LIST,
            payload: response.data.data
        })
        // console.log(response.data.data);
    } catch (error) {
        // console.log(error)
    }
}

export const getGiftcardsHistory = () => async dispatch => {
    try {
        const response  = await axios.get("/rd/v1/transactions/giftcard/trade/status/all");
        dispatch({
            type: GIFTCARDS_HISTORY,
            payload: response.data.data
        })
      
    } catch (error) {
        // console.log(error)
    }
}

export const getPendingGiftcardsTrades = () => async dispatch => {
    try {
        const response  = await axios.get("/rd/v1/transactions/giftcard/cards/status/pending");
        dispatch({
            type: PENDING_GIFTCARDS_TRADES,
            payload: response.data.data
        })
      
    } catch (error) {
        // console.log(error)
    }
}