import React, {useState, useRef, useEffect} from "react";
import "../styles/sort.css";

const SortCards = ({ list, state, stateSetter, name, smallScreen, sortFunc, containerHeight }) => {

  const [showOptions, setShowOptions] = useState(false);

  const containerRef = useRef()
  const dropdownRef = useRef(null);

  useEffect(() => {
    const dropdownHeight = dropdownRef.current.getBoundingClientRect().height;

    if(showOptions) {
      containerRef.current.style.height = `${dropdownHeight}px`;  
    } else {
      containerRef.current.style.height = `${containerHeight}px`;
    }
  }, [showOptions, containerHeight])

  // Sort option handler
  const cardSortHandler = (e) => {
    stateSetter(e.target.value);
    sortFunc(e.target.value);
  }

  // sort dropdown handler on small screen
  const dropDownHandler = () => {
    setShowOptions(prev => !prev);
  }

  // console.log(showOptions)

  return (
    <div 
      className="sort-card" 
      style={{
        height: `${containerHeight}px`,
      }}
      ref={containerRef}
    >
      <span>Sort cards by:</span>
      <article className="sort-group">
        <div className="form-group" ref={dropdownRef}>
          {
            list.map(item => {
              const {value, id} = item;
              return (
                <label key={id} htmlFor={id}>
                  <input 
                    type="radio" 
                    name={name}
                    value={value} 
                    id={id} 
                    checked={state === value}
                    onChange={cardSortHandler}
                  />
                  {value}
                </label>
              )
            })
          }
        </div>
      </article>
      {
        smallScreen && (
          <span onClick={dropDownHandler}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8.53" height="5.27" viewBox="0 0 8.53 5.27">
              <path fill="#A8A8A8" d="m1 0 3.27 3.26L7.53 0l1 1-4.26 4.27L0 1Z"/>
            </svg>
          </span>
        )
      }
    </div>
  )
}

export default SortCards;