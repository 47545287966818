import React, {useEffect} from "react";
import MainHeader from "../../../layouts/header/components/MainHeader";
import LeaderBoardHeader from "./LeaderBoardHeader";
import "../styles/leaderboardmain.css";
// import { LeaderboardTopTradersItemData } from "../../data/traders";
import LeaderboardTopTradersItem from "./LeaderboardTopTradersItem";
import LeaderboardTopTraderDesktop from "./LeaderboardTopTraderDesktop";
import LeaderboardGiftcardTab from "../components/LeaderboardGiftcardTab";
import useGlobalContext from "../../../custom-hook/Context";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getLeaderboardMonthlyData } from "../../../js/actions/leaderboard";


const LeaderBoardMain = ({ leaderboardMonthlyList, getLeaderboardMonthlyData}) => {
  const { largeDevice } = useGlobalContext();
  useEffect(() => {
    getLeaderboardMonthlyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <main className="main">
      <MainHeader />
      <LeaderBoardHeader />
      <div className="main-content ">
        <div className="leaderboard__main">
          <div
            className="redem__avatar__avatar__section"
            style={{ display: `${largeDevice ? "none" : "block"}` }}
          >
            <p className="leaderboard__section__trader">Top traders</p>
            <div>
              <ul className="">
                {leaderboardMonthlyList &&  Array.from(leaderboardMonthlyList).slice(0, 1).map((data, index) => {
                  const { profile_pics, username, points} = data;

                  return (
                    <LeaderboardTopTraderDesktop
                      key={index}
                      profile_pics={profile_pics}
                      username={username}
                      points={points}
                      
                      // icon={icon}
                    />
                  );
                })}
                 {leaderboardMonthlyList &&  Array.from(leaderboardMonthlyList).slice(1, 3).map((data, index) => {
                  const { profile_pics, username, points, rank } = data;

                  return (
                    <LeaderboardTopTradersItem
                      key={index}
                      profile_pics={profile_pics}
                      username={username}
                      points={points}
                      rank={rank}
                      // icon={icon}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
          <div><LeaderboardGiftcardTab/></div>
        </div>
      </div>
    </main>
  );
};



LeaderBoardMain.propTypes = {
  getLeaderboardMonthlyData: PropTypes.func.isRequired,
  leaderboardMonthlyList: PropTypes.array,

};

const mapStateToProps = state => ({
  leaderboardMonthlyList: state.leaderboard.leaderboardMonthlyList,

});

export default connect(mapStateToProps, { getLeaderboardMonthlyData })(
  LeaderBoardMain);

