import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/gift-box.css";
import giftbox from "../../asset/Trade_Reward-removebg-preview (1)@2x.png";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../js/actions/profile";

const GiftBox = ({ getProfile, profile }) => {
  const currencyRate = Number(JSON.parse(localStorage.getItem("currencyRate")));
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  const [style, setStyle] = useState({});
  useEffect(() => {
    const timer = setTimeout(() => {
      const newStyle = {
        content: "",
        position: "absolute",
        top: "0",
        left: "0",
        height: "100%",
        backgroundColor: "#1B1C1D",
        width: `${profile.profile.reward_percentage}%`,
      };
      setStyle(newStyle);
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const totalReward = 1000;
  return (
    <section className="card gift-box">
      <section className="card-body gift-box-content">
        <div className="trade-value">
          <div className="">
            <div className="reward">
              <div className="reward__span">
                <span style={style}></span>
              </div>

              {userCountry === "nigeria" && (
                <p>
                  You have &nbsp;
                  <strong>{`$${profile.profile.reward_amount_left}`}</strong> to
                  get your &nbsp;{`₦${totalReward}`} reward
                </p>
              )}

              {userCountry === "kenya" && (
                <p>
                  You have &nbsp;
                  <strong>{`$${profile.profile.reward_amount_left}`}</strong> to
                  get your &nbsp;{`K${totalReward * currencyRate}`} reward
                </p>
              )}
              {userCountry === "ghana" && (
                <p>
                  You have &nbsp;
                  <strong>{`$${profile.profile.reward_amount_left}`}</strong> to
                  get your &nbsp;{`₵${totalReward * currencyRate}`} reward
                </p>
              )}
            </div>
            {/* <div className="next-rank">
              <p>Amount to trade to reach next rank</p>
              <span></span>
        
            </div> 
          <strong className="amt">{`${profile.profile.level_amount_left_raw}`}</strong> */}
          </div>
          <div className="giftbox-img-wrapper">
            <img src={giftbox} className="img-fluid" alt="" />
          </div>
        </div>
      </section>
      <div className="custom-card-footer gift-box-footer">
        <Link to="/giftcard">Quick Trade</Link>
      </div>
    </section>
  );
};

GiftBox.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(GiftBox);
