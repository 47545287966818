import "./App.css";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Wallet from "./pages/Wallet";
import SubMenu from "./submenu/components/subMenu";
import useGlobalContext from "./custom-hook/Context";
import Giftcards from "./pages/Giftcards";
import Calculator from "./pages/Calculator";
import HottestRates from "./pages/HottestRates";
import Transactions from "./pages/Transactions";
import ProtectedRoutes from "./layouts/Dashlayout";
import SignIn from "./auth/signin/signin";
import SignUp from "./auth/signup/signup";
import OtpAuthentication from "./auth/otpAuthentication/otpAuthentication";
import MainAuthLayout from "./layouts/authlayout/authlayout";
import GiftCardDetails from "./content/giftcard/components/GiftCardDetails";
import BankDetails from "./pages/BankDetails";
import EditProfile from "./pages/EditProfile";
import SecurityMain from "./content/changeSecurity/component/SecurityMain";
import ChangePin from "./content/changeSecurity/component/ChangePin";
import SavedBanksMain from "./content/bank-details/SavedBanksMain";
import AddBank from "./content/bank-details/AddBank";
import ChangePassword from "./content/changeSecurity/component/ChangePassword";
import { useEffect } from "react";
import store from "./js/store/index";
import { getAuthStatus } from "./js/actions/auth";
import Subscription from "./pages/Subscription";
import AirtimeDetails from "./content/bills/purchase-details/AirtimeDetails";
import DataDetails from "./content/bills/purchase-details/DataDetails";
import ElectricDetails from "./content/bills/purchase-details/ElectricDetails";
import TollDetails from "./content/bills/purchase-details/TollDetails";
import TvDetails from "./content/bills/purchase-details/TvDetails";
// import AirtimeSuccess from "./content/bills/successful/AirtimeSuccess";
// import DataSuccess from "./content/bills/successful/DataSuccess";
// import ElectricSuccess from "./content/bills/successful/ElectricSuccess";
// import TvSuccess from "./content/bills/successful/TvSuccess";
// import TollSuccess from "./content/bills/successful/TollSuccess";
import Transfer from "./pages/Transfer";
import BeneficiaryDetails from "./content/transfer/components/BeneficiaryDetails";
import RedeemAvatarsMain from "./content/redeem-avatars/components/RedeemAvatarsMain";
import Withdraw from "./pages/Withdraw";
import WithdrawSummary from "./pages/WithdrawSummary";
import WalletHistoryMain from "./content/wallet-history/components/WalletHistoryMain";
import LeaderBoard from "./pages/LeaderBoard";
import Notifications from "./pages/Notifications";
import Verified from "./pages/Verified";
import SetPin from "./pages/SetPin";
// import NotFound from "./pages/NotFound";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPasswordEmailValidation from "./pages/ForgotPasswordEmailValidation";
import ForgotPasswordChangePassword from "./pages/ForgotPasswordChangePassword";
import ChangePasswordRequestCode from "./content/changeSecurity/component/ChangePasswordRequestCode";
import ChangePasswordVerifyCode from "./content/changeSecurity/component/ChangePasswordVerifyCode";
import ChangePinRequestCode from "./content/changeSecurity/component/ChangePinRequestCode";
import ChangePinVerifyCode from "./content/changeSecurity/component/ChangePinVerifyCode";
import WithdrawMainDetails from "./content/withdraw/components/WithdrawMainDetails";
import Help from "./pages/Help";

import HelpEmail from "./pages/HelpEmail";
import HelpFaqs from "./pages/HelpFaqs";
import Settings from "./pages/Settings";
import Privacy from "./pages/Privacy";
import SingleTransactionHistoryMobile from "./content/transaction/component/SingleTransactionHistoryMobile";


function App() {
  const auth = JSON.parse(localStorage.getItem("token"));
  
  useEffect(() => {
    if (auth) {
      store.dispatch(getAuthStatus());
    }
    return;
  }, [auth]);

  const { mediumDevice } = useGlobalContext();


  // const currentLocation =
  //   window.location.href.includes("/signin") ||
  //   window.location.href.includes("/signup")||
  //   window.location.href === "/"
  // window.location.href.includes("/");
  // if(auth) {
  //     useEffect(() => {
  //         store.dispatch(getAuthStatus());
  //       }, []);
  // } else {
  //     return null;
  // }
  // console.log(auth)

  return (
    <div className="MainApp">
      <Router>
        {/* <Route exact path="/verification" > <Verified /></Route> */}
        <MainAuthLayout exact path="/verification" component={Verified} />
        <MainAuthLayout exact path="/signup" component={SignUp} />
        <MainAuthLayout exact path="/signin" component={SignIn} />
        <MainAuthLayout exact path="/otpAuthentication" component={OtpAuthentication} />
        <MainAuthLayout
          exact
          path="/forgot-password"
          component={ForgotPassword}
        />
        <MainAuthLayout
          exact
          path="/forgot-password-validate-email"
          component={ForgotPasswordEmailValidation}
        />
        <MainAuthLayout
          exact
          path="/forgot-password-change-password"
          component={ForgotPasswordChangePassword}
        />
        <MainAuthLayout exact path="/" component={SignUp} />

        <div
          className="App"
          style={{
            display: !auth ? "none" : "inline-flex" || "block",
          }}
        >
          <Switch>
            <>
              <ProtectedRoutes
                exact
                path="/dashboard"
                component={Dashboard}
                auth={auth ? auth : null}
              />
              <ProtectedRoutes
                exact
                path="/wallet"
                component={Wallet}
                auth={auth ? auth : null}
              />
              <ProtectedRoutes
                exact
                path="/giftcard"
                component={Giftcards}
                auth={auth ? auth : null}
              />
              <ProtectedRoutes
                exact
                path="/giftcard/:id-card-details"
                component={GiftCardDetails}
                auth={auth ? auth : null}
              />
              <ProtectedRoutes
                exact
                path="/all-rates"
                component={Calculator}
                auth={auth ? auth : null}
              />
              <ProtectedRoutes
                exact
                path="/hottest-rates"
                component={HottestRates}
                auth={auth ? auth : null}
              />
              <ProtectedRoutes
                exact
                path="/transactions"
                component={Transactions}
                auth={auth ? auth : null}
              />
              <ProtectedRoutes
                exact
                path="/profile/bank-details"
                component={BankDetails}
                auth={auth ? auth : null}
              />
              <ProtectedRoutes
                exact
                path="/profile/bank-details/add-bank"
                component={AddBank}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/user-details"
                component={EditProfile}
                auth={auth ? auth : null}
              />
              <ProtectedRoutes
                exact
                path="/profile/bank-details/saved-banks"
                component={SavedBanksMain}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/profile/change-credentials"
                component={SecurityMain}
                auth={auth ? auth : null}
              />
              <ProtectedRoutes
                exact
                path="/profile/change-pin"
                component={ChangePin}
                auth={auth ? auth : null}
              />
              <ProtectedRoutes
                exact
                path="/profile/change-password"
                component={ChangePassword}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/profile/request-code"
                component={ChangePasswordRequestCode}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/profile/verify-code"
                component={ChangePasswordVerifyCode}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/profile/pin-request-code"
                component={ChangePinRequestCode}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/profile/pin-verify-code"
                component={ChangePinVerifyCode}
                auth={auth ? auth : null}
              />
              <ProtectedRoutes
                exact
                path="/subscription"
                component={Subscription}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/airtime-details/:id"
                component={AirtimeDetails}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/data-details/:id"
                component={DataDetails}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/electric-details/:id"
                component={ElectricDetails}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/toll-details/:id"
                component={TollDetails}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/tv-details/:id"
                component={TvDetails}
                auth={auth ? auth : null}
              />

              {/* <ProtectedRoutes
                exact
                path="/airtime-details/:id/success"
                component={AirtimeSuccess}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/data-details/:id/success"
                component={DataSuccess}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/electric-details/:id/success"
                component={ElectricSuccess}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/tv-details/:id/success"
                component={TvSuccess}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/toll-details/:id/success"
                component={TollSuccess}
                auth={auth ? auth : null}
              /> */}

              <ProtectedRoutes
                exact
                path="/transfer"
                component={Transfer}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/beneficiary-details/:id/proceed"
                component={BeneficiaryDetails}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/redem-avatars"
                component={RedeemAvatarsMain}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/withdraw"
                component={Withdraw}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/withdrawal-summary"
                component={WithdrawSummary}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/:reference/wallet-history"
                component={WalletHistoryMain}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/leaderboard"
                component={LeaderBoard}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/notifications"
                component={Notifications}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/set-pin"
                component={SetPin}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/withdrawal-details/:id/proceed"
                component={WithdrawMainDetails}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/help"
                component={Help}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/help-email"
                component={HelpEmail}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/help-faqs"
                component={HelpFaqs}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/settings"
                component={Settings}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/privacy-policy"
                component={Privacy}
                auth={auth ? auth : null}
              />

              <ProtectedRoutes
                exact
                path="/transaction-history"
                component={SingleTransactionHistoryMobile}
                auth={auth ? auth : null}
              />

              {mediumDevice && <SubMenu />}
              {/* <Route component={NotFound} />  */}
            </>
            {/* <Route path='*' element={<Navigate to='/' />} /> */}
            {/* <Route  exact path="*" component={NotFound} /> */}
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
