import axios from "../../axios";
import Platform from "react-platform-js";
// import { LOGIN_SUCCESS, SIGNUP_SUCCESS } from "../constants/action-types";
import {
  AUTH_STATUS,
  // LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  LOADING_BUTTON,
} from "../constants/action-types";
import Swal from "sweetalert2";

export const signin =
  ({ email, password, ip, username }) =>
  async dispatch => {
    try {
      dispatch({
        type: LOADING_BUTTON,
        payload: true,
      });

      const response = await axios.post(
        "/rd/v1/auth/signin/init",
        {
          email,
          password,
          username,
        },
        {
          headers: {
            device_type: "web",
            device_model: Platform.OS,
            device_id: Platform.OSVersion,
            device_name: Platform.Browser,
            ip_address: ip,
          },
        }
      );
     
      if (response.data.status) {
        localStorage.setItem("email", JSON.stringify(email));
        localStorage.setItem("device_type", JSON.stringify("web"));
        localStorage.setItem("device_model", JSON.stringify(Platform.OS));
        localStorage.setItem("device_id", JSON.stringify(Platform.OSVersion));
        localStorage.setItem("device_name", JSON.stringify(Platform.Browser));
        localStorage.setItem("ip_address", JSON.stringify(ip));
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Authenticate this device",
        });

        dispatch({
          type: LOADING_BUTTON,
          payload: false,
        });
        setTimeout(() => {
          window.location.href = "/otpAuthentication";
        }, 1000);
        
      } else {
        setTimeout(() => {
          window.location.href = "/signin";
        }, 1500);

        dispatch({
          type: LOADING_BUTTON,
          payload: false,
        });
      }
    } catch (error) {
      setTimeout(() => {
        window.location.href = "/signin";
      }, 1500);
      Swal.fire({
        icon: "error",
        title: "Forbidden",
        text: `${error.response.data.message}`,
      });

      dispatch({
        type: LOADING_BUTTON,
        payload: false,
      });
    }
  };

export const signup =
  ({
    email,
    password,
    fname,
    lname,
    username,
    country,
    pnumber,
    ref,
    isCheckedPromos,
    isCheckedTerms,
  }) =>
  async dispatch => {
    try {
      const response = await axios.post("/rd/v1/auth/signup", {
        email,
        password,
        fname,
        lname,
        username,
        country,
        pnumber,
        ref,
        deviceType: "web",
        receivePromotionalEmail: isCheckedPromos,
      });

      if (response) {
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: response.data,
        });

        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("token", JSON.stringify(response.data.token));
        localStorage.setItem(
          "userCountry",
          JSON.stringify(response.data.profile.country)
        );
        axios.defaults.headers.common["authorization"] =
          "Bearer " + localStorage.getItem("token");
        setTimeout(() => {
          window.location.href = "/verification";
        }, 1000);
        const toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 1000,
        });
        window.toast = toast;
        toast.fire({ icon: "success", title: "Please check your Mailbox..." });
        return;
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Forbidden",
        text: `${error.response.data.message}`,
      });
    }
  };

export const getAuthStatus = () => async dispatch => {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = await axios.get("/rd/v1/user/profile");
    dispatch({
      type: AUTH_STATUS,
      payload: true,
    });
  } catch (error) {
    localStorage.clear();
    window.location.reload(false);
    dispatch({
      type: AUTH_STATUS,
      payload: false,
    });
  }
};
