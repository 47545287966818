import React, { useEffect } from "react";
import useGlobalContext from "../custom-hook/Context";
import EditProfileMain from "../content/edit-profile/component/EditProfileMain";

const EditProfile = () => {
  const { checkAuth} = useGlobalContext();


  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return (
    <EditProfileMain />
  );
}

export default EditProfile