import React, { useCallback, useEffect } from "react"
import useGlobalContext from "../../../custom-hook/Context";

import "../style/profile-header.css";

// import defaultAvatar from "../../../asset/user-image x2.png";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";

const ProfileHeader = ({avatar, getProfile, 
  profile}) => {
  useEffect(() => {
    getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { 
    smallDevice, 
    // handleModalDisplay, 
    chooseRenderAction, 
    dispatchProfile,
    updateAvatars,
    avatars 
  } = useGlobalContext();

  const defaultAvatar = profile.profile.profile_pics
  // Remove selected or uploaded avatar
  const removeAvatar = useCallback(() => {
    const defaultList = avatars.map(avatar => {
      return {...avatar, checked: false}
    });

    updateAvatars(defaultList);

    dispatchProfile({type: "REMOVE_PROFILE_AVATAR"});
  }, [dispatchProfile, avatars, updateAvatars]);

  const editIcon = (
    smallDevice ? (
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <g data-name="Group 163380" transform="translate(-300 -114)">
          <circle cx="14" cy="14" r="14" fill="rgba(27,28,29,0.7)" data-name="Ellipse 676" transform="translate(300 114)"/>
          <path fill="#fdfdfd" d="M308.1 131.4v2.5h2.5l7.2-7.2-2.5-2.5Zm11.6-6.6a.6.6 0 0 0 0-1l-1.6-1.5a.6.6 0 0 0-.9 0l-1.2 1.2 2.5 2.5 1.2-1.2Z"/>
        </g>
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
        <g data-name="Group 163381" transform="translate(-1438 -177)">
          <circle cx="21" cy="21" r="21" fill="rgba(27,28,29,0.7)" data-name="Ellipse 676" transform="translate(1438 177)"/>
          <path fill="#fdfdfd" d="M1450 203.3v3.7h3.7l11.1-11-3.7-3.8Zm17.7-10.3a1 1 0 0 0 0-1.4l-2.3-2.3a1 1 0 0 0-1.4 0l-1.9 1.8 3.8 3.8 1.8-1.9Z"/>
        </g>
      </svg>
    )
  );

  return (
    <header className="profile-header">
      <div className="profile-img-top">
        <div>
          <img src={avatar || defaultAvatar} alt="Avatar"/>
          {/* <img src={avatar || defaultAvatar} alt="Avatar" onClick={handleModalDisplay} /> */}
          <label className="sr-only">Change picture</label>
        </div>
        {editIcon}
      </div>
      <div className="btn-container">
        <button className="img-upload-btn btn" onClick={chooseRenderAction}>
          <span>Change avatar</span>
          <label className="sr-only">Change avatar</label>
        </button>
        <button className="delete-btn btn" onClick={removeAvatar}>Delete</button>
      </div>
      <p>
        Trade more to unlock avatars and covers. Make your profile look badass
      </p>
    </header>
  )
};



ProfileHeader.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(ProfileHeader);