import React, { useEffect, useState } from "react";
import useGlobalContext from "../../../custom-hook/Context";
import { useHistory } from "react-router-dom";
import MainHeader from "../../../layouts/header/components/MainHeader";
import Password from "../../component/Password";
import "./../style/change-password.css";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";
import axios from "../../../axios";
import Swal from "sweetalert2";

const ChangePassword = ({ getProfile, profile }) => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function showPassword() {
    document
      .querySelector(".toggle-forgot-password")
      .classList.toggle("fa-eye-slash");
    document
      .querySelector(".toggle-forgot-password")
      .classList.toggle("fa-eye");
    let x = document.getElementById("change-password-toggler");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  function showConfirmPassword() {
    document
      .querySelector(".toggle-confirm-password")
      .classList.toggle("fa-eye-slash");
    document
      .querySelector(".toggle-confirm-password")
      .classList.toggle("fa-eye");
    let x = document.getElementById("confirm-password-toggler");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  function showCurrentPassword() {
    document
      .querySelector(".toggle-current-password")
      .classList.toggle("fa-eye-slash");
    document
      .querySelector(".toggle-current-password")
      .classList.toggle("fa-eye");
    let x = document.getElementById("currentpassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  const [oldPassword, setoldPassword] = useState("");

  const getOldPassword = e => {
    let oldPassword = e.target.value;
    setoldPassword(oldPassword);
  };
  const [input, setInput] = useState({
    password: "",
    newPassword: "",
  });

  const [error, setError] = useState({
    password: "",
    newPassword: "",
  });

  const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = e => {
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (input.newPassword && value !== input.newPassword) {
            stateObj["newPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["newPassword"] = input.newPassword
              ? ""
              : error.newPassword;
          }
          break;

        case "newPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const history = useHistory();
  function cancel() {
    history.push("/profile/change-credentials");
  }
  let code = JSON.parse(localStorage.getItem("verifyInAppCode"));
  let token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      auth: `Bearer ${token}`,
    },
  };

  const handleInput = () => {
    let formBody = {
      token: config.headers.auth,
      verificationCode: code,
      oldPassword: oldPassword,
      newPassword: input.password,
    };
    // console.log(formBody);
    axios
      .post("/rd/v1/auth/password/verify/in", formBody)
      .then(response => {
        // console.log(response.data.message);
        Swal.fire({
          icon: "success",
          text: `${response.data.message}`,
        }).then(function () {
          localStorage.clear();
          window.location.href = "/dashboard";
        });
      })
      .catch(e => {
        // console.log(e);
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };

  return (
    <main className="main">
      <MainHeader text="Change Password" />
      <div className="feedback__home">
        {/* <div className="feedback__component"></div> */}
        <section className="change__pass">
          <div className="change__pass__form">
            <div className="change__pass__form__icon">
              <Password />
            </div>
            <p className="change__pass__form__text">
              You will change the password for:{" "}
              <span>{`${profile.profile.firstname}  ${profile.profile.lastname}`}</span>
            </p>
            <div className="form-group change__pass__form__input">
              <div className="label-wrapper">
                <label
                  htmlFor="currentpassword"
                  className="change__pass__form__label"
                >
                  Current password
                </label>
              </div>
              <input
                name="oldPassword"
                id="currentpassword"
                type="password"
                className="change__pass__form__input__input"
                onKeyUp={e => getOldPassword(e)}
                required
              />
              <span
                toggle="#current-password-toggler"
                className="fa fa-eye-slash  change__password__toggle toggle-current-password"
                onClick={showCurrentPassword}
              ></span>
            </div>
            <div className="form-group  change__pass__form__input">
              <div className="label-wrapper">
                <label
                  htmlFor="newpassword"
                  className="change__pass__form__label"
                >
                  New password
                </label>
              </div>
              <input
                name="password"
                id="change-password-toggler"
                type="password"
                className="change__pass__form__input__input"
                value={input.password}
                onChange={onInputChange}
                onBlur={validateInput}
                required
              />
              <span
                toggle="#change-password-toggler"
                className="fa fa-eye-slash change__password__toggle toggle-forgot-password"
                onClick={showPassword}
              ></span>
              <div>
                {error.password && (
                  <span className="err">{error.newpassword}</span>
                )}
              </div>
            </div>
            <div className="form-group change__pass__form__input">
              <div className="label-wrapper">
                <label
                  htmlFor="retypepassword"
                  className="change__pass__form__label"
                >
                  Confirm new password
                </label>
              </div>
              <input
                name="newPassword"
                id="confirm-password-toggler"
                type="password"
                className="change__pass__form__input__input"
                value={input.newPassword}
                onChange={onInputChange}
                onBlur={validateInput}
                required
              />
              <span
                toggle="#confirm-password-toggler"
                className="fa fa-eye-slash second__change__pasword__toggle change__password__toggle toggle-confirm-password"
                onClick={showConfirmPassword}
              ></span>
              <div>
                {error.newPassword && (
                  <span className="err">{error.newPassword}</span>
                )}
              </div>
            </div>
            {input.newPassword === input.password && (
              <div className="change__pass__buttons">
                <button
                  type="button"
                  className="change__pass__cancel__button"
                  onClick={cancel}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="change__pass__button"
                  onClick={() => handleInput()}
                >
                  Apply
                </button>
              </div>
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

ChangePassword.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(ChangePassword);
