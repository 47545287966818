import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from './custom-hook/Context';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./js/store/index";
import axios from './axios';

// let token = (localStorage.getItem('token'))
// let token = JSON.parse(localStorage.getItem('token'))
let token = localStorage.getItem('token')? JSON.parse(localStorage.getItem('token')):null
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
// console.log(token)
ReactDOM.render(
  <React.Fragment>
    <BrowserRouter>
      <AppProvider>
      <Provider store={store}>
        <App />
        </Provider>
      </AppProvider>
    </BrowserRouter>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
