import axios from '../../axios';
import { GET_HOTTEST_RATES } from '../constants/action-types';

export const getHottestRates = () => async dispatch => {
    try {
        const response  = await axios.get("/rd/v1/giftcard/cards/hot_rates");
        dispatch({
            type: GET_HOTTEST_RATES,
            payload: response.data.data
        })
        // console.log( response.data.data);
    } catch (error) {
        // console.log(error)
    }
}