import gtbankCard from "../../../asset/Group 163221.svg";
import kudaCard from "../../../asset/Group 163222.svg";
import kuda from "../../../asset/Kuda bank.svg";
import gtbank from "../../../asset/Gtbank.svg";




export const savedBankList = [
    {
      id: 1,
      bank: `${kuda}`,
      background: `${kudaCard}`,
      name: "Redem Akuulayo",
      account: "******4123",
      bankName:"Kuda Bank"
    },
    {
      id: 2,
      bank: `${gtbank}`,
      background: `${gtbankCard}`,
      name: "Princess Jewel",
      account: "******2580",
      bankName:"Gtbank Bank"
    }, 
  ];