import {
  AUTH_STATUS,
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
} from "../constants/action-types";

const initialState = {
  isAuthenticated: true,
  user: null,
  csrf: false,
};

export default function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
 
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
      };
    case AUTH_STATUS:
      return {
        ...state,
        isAuthenticated: payload,
      };
    default:
      return state;
  }
}
