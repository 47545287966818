import { WITHDRAWAL_HISTORY_LIST} from "../constants/action-types";

const initialState = {
    withdrawalList: {},
  
}

export default function withdrawalReducer(state = initialState, action) {
    const {type, payload} = action;

    switch(type) {
        case WITHDRAWAL_HISTORY_LIST:
            return {
                ...state,
                withdrawalList: payload
            }
        default:
            return state;
    }
}