/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { signin } from "../../js/actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import { Formik, Field, Form, ErrorMessage } from "formik";
// import * as Yup from "yup";
import Swal from "sweetalert2";
import "./signin.css";
import logo from "../../asset/Default Redem Logo (New).png";
import Spinner from "../../content/component/Spinner";

// import useGlobalContext from "../../custom-hook/Context";

const SignIn = ({ signin, isAuthenticated, loading }) => {
  const [ip, setIp] = useState();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [username] = useState("");

  const getIp = async () => {
    // Connect ipapi.co with fetch()
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();

    setIp(data.ip);
  };

  useEffect(() => {
    setEmail("");
    setPassword("");
    getIp();
  }, []);

  // const {loading} = useGlobalContext();
  // const [error, setError] = useState(null)

  function showPassword() {
    document.querySelector(".toggle-password").classList.toggle("fa-eye-slash");
    document.querySelector(".toggle-password").classList.toggle("fa-eye");
    let x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  const toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 1000,
  });

  window.toast = toast;

  const handleSubmit = event => {
    event.preventDefault();
    try {
      signin({ email, password, ip, username }).then(res => {
        if (res) {
          return;
        }
      });
    } catch (error) {}
  };

  return (
    <section className="">
      <div className="">
        <div className="">
          <div className="child">
            <a href="https://getredem.io/" rel="noreferrer" target="_blank">
              <div className="redirect__to__landing__page">
                <img src={logo} alt="" />
              </div>
            </a>
            <h2 className="right--section--h2">Sign In</h2>
            <p className="right--section--p">
              Welcome back! Please Sign in to continue
            </p>
            {/* <div className="user--icon--container">
                <img
                  src="https://res.cloudinary.com/dpqxraalv/image/upload/v1630937978/user_q8nnyv.svg"
                  alt="User avatar"
                  className="img-fluid"
                  loading="lazy"
                />
              </div> */}

            <form onSubmit={handleSubmit}>
              {/* {error && <div>{error}</div>} */}
              <div className="form-container">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="signin-form-control"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  onChange={e => setEmail(e.target.value)}
                  required
                />
                {/* <span className="input-span">Email</span> */}
              </div>

              <div className="form-container">
                <input
                  placeholder="Password"
                  id="password"
                  type="password"
                  className="signin-form-control"
                  name="password"
                  onChange={e => setPassword(e.target.value)}
                  required
                />
                {/* <span className="input-span">Password</span> */}
                <span
                  toggle="#password"
                  className="fa fa-eye-slash field-icon toggle-password"
                  onClick={showPassword}
                ></span>
              </div>

              <div className="checker--forgot--password">
                <div className="forgot-password">
                  {/* eslint-disable-next-line */}
                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
                {/* <div className="checker">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        Remember Me
                      </label>
                    </div>
                  </div> */}
              </div>

              <div className="d-flex justify-content-around align-items-center text-center gap-3">
                {!loading && (
                  <button type="submit" className="log--button">
                    Sign In
                  </button>
                )}
                {loading && (
                  <button type="submit" className="loading__button">
                    <Spinner />
                  </button>
                )}
              </div>

              <div className="redirect-to-signup">
                <p>
                  New on our platform?
                  <Link to="/signup" className="redirect-to-signup-link">
                    &nbsp;Create an account
                  </Link>
                </p>
              </div>

              <div className="signin-footer-links">
                <ul className="signin-footer-links-ul">
                  <a
                    href="https://getredem.io/termsandconditions"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <li>Terms & Condition</li>
                  </a>
                  <a
                    href="https://getredem.io/privacy"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <li>Privacy Policy</li>
                  </a>
                  <a
                    href="https://getredem.io/aml"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <li>AML</li>
                  </a>
                </ul>

                <p className="signin-footer-links-ul-p">
                  &copy; 2021 Redem Limited. All Rights Reserved
                </p>
              </div>
              {/* <Link to="/signup" className="sign--in--button link-button">
                   
                    Sign Up
                 
                  </Link> */}
            </form>
          </div>
        </div>
      </div>
    </section>
    // </Formik>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.utils.loading_button,
});

SignIn.propTypes = {
  signin: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
};
export default connect(mapStateToProps, { signin })(SignIn);
