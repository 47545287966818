import mtn from "../../asset/MTN.png";
import etisalat from "../../asset/etisalat.png";
import airtel from "../../asset/Airtel.png";
import glo from "../../asset/Glo.png";
import ikedc from "../../asset/ikedc.png";
import ekedc from "../../asset/ekedc.png";
import ibedc from "../../asset/ibedc.png";
import dstv from "../../asset/dstv.png";
import gotv from "../../asset/gotv.png";
import startimes from "../../asset/startimes.png";
import ikoyi from "../../asset/ikoyi.png";
import epe from "../../asset/epe.png";



export const airtimeData = [
  {
    id: "1",
    icon: `${mtn}`,
    provider: "MTN",
  
  },
  {
    id: "2",
    icon: `${etisalat}`,
    provider: "9mobile",
  
  },
  {
    id: "3",
    icon: `${airtel}`,
    provider: "Airtel",

  },
  {
    id: "4",
    icon: `${glo}`,
    provider: "GLO",
 
  },
];

export const dataData = [
  {
    id: "1",
    icon: `${mtn}`,
    provider: "MTN",
 
  },
  {
    id: "2",
    icon: `${etisalat}`,
    provider: "9Mobile",
  
  },
  {
    id: "3",
    icon: `${airtel}`,
    provider: "Airtel",

  },
  {
    id: "4",
    icon: `${glo}`,
    provider: "GLO",
   
  },
];

export const electricData = [
  {
    id: "1",
    icon: `${ikedc}`,
    provider: "IKEDC",

  },
  {
    id: "2",
    icon: `${ibedc}`,
    provider: "IBEDC",
    
  },
  {
    id: "3",
    icon: `${ekedc}`,
    provider: "EKEDC",

  }
];

export const tvData = [
  {
    id: "1",
    icon: `${dstv}`,
    provider: "DSTV",

  },
  {
    id: "2",
    icon: `${gotv}`,
    provider: "GOTV",
    
  },
  {
    id: "3",
    icon: `${startimes}`,
    provider: "STARTIMES",

  }
];

export const tollData = [
  {
    id: "1",
    icon: `${ikoyi}`,
    provider: "IKOYI",

  },
  {
    id: "2",
    icon: `${epe}`,
    provider: "EPE",
    
  }
];


export const electricList = [
  {
    id: "1",
    name: "prepaid",

  },
  {
    id: "2",
    name: "postpaid",
    
  }
];