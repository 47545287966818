import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/hottest-rate.css";
// import flame from "../../asset/fire.svg";
import HottestRateItem from "./HottestRateItem";
// import GiftcardHistoryLottie from "./GiftcardHistoryLottie";
import HottestRatesLottie from "./HottestRatesLottie";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getHottestRates } from "../../js/actions/hottestrates";

const HottestRate = ({ getHottestRates, hottestrateslist }) => {
  useEffect(() => {
    getHottestRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="card hottest-rate">
      <header className="hottest-rate-header">
        <h3>Hottest rates</h3>
        <span>
          <HottestRatesLottie />
        </span>
      </header>
      <section className="card-body">
        {hottestrateslist && hottestrateslist.length < 1 && <p>Loading...</p>}
        {hottestrateslist && hottestrateslist.length > 0 && (
          <ul className="hottest-list">
            {hottestrateslist &&
              hottestrateslist.slice(0, 4).map((data, id) => {
                const { rates, subcategory, category, country, identifier } = data;

                return (
                  <HottestRateItem
                    key={id}
                    rates={rates}
                    subcategory={subcategory}
                    category={category}
                    country={country}
                    identifier={identifier}
                    hottestrateslist={hottestrateslist}
                    // icons={[icon1, icon2]}
                    // name={name}
                    // dollarAmt={dollarAmt}
                    // nairaAmt={nairaAmt}
                  />
                );
              })}
          </ul>
        )}
      </section>
      <div className="custom-card-footer hottest-rate-footer">
        <Link to="/hottest-rates">See juiciest rates</Link>
      </div>
    </section>
  );
};

HottestRate.propTypes = {
  getHottestRates: PropTypes.func,
  hottestrateslist: PropTypes.array,
};
const mapStateToProps = state => ({
  hottestrateslist: state.hottestrates.hottestrateslist,
});

export default connect(mapStateToProps, { getHottestRates })(HottestRate);
