import React from "react";

const CountryValues = ({ id, name, selected, selectCountry }) => {

  const handleClick = () => {
    selectCountry(id)
  }

  return (
 <>
  
    <button
      type="button" id={id}
      style={{
        backgroundColor: `${selected ? "#1b1c1d" : "#ffffff"}`,
        color: `${selected ? "#ffffff" : "#1b1c1d"}`,
      }}
      onClick={handleClick}
    >{name}</button></>
  )
};

export default CountryValues;