import { BANK_LIST, SAVED_BANK, ADD_BANK, VALIDATE_BANK, SAVED_BENEFICIARIES, INTERNATIONAL_SAVED_BANK} from "../constants/action-types";

const initialState = {
    bankList: [],
    allSavedBankList: [],
    addBank: {},
    validateBank: {},
    savedBeneficiaries: [],
    allInternationalSavedBankList: []
}

export default function profReducer(state = initialState, action) {
    const {type, payload} = action;

    switch(type) {
        case BANK_LIST:
            return {
                ...state,
                bankList: payload
            }
        case SAVED_BANK:
            return {
                ...state,
                allSavedBankList: payload
            }
        case ADD_BANK:
            return {
                ...state,
                addBank: payload
            }
        case VALIDATE_BANK:
            return {
                ...state,
                validateBank: payload
            }
        case SAVED_BENEFICIARIES:
            return {
                ...state,
                savedBeneficiaries: payload
            }
            case INTERNATIONAL_SAVED_BANK:
                return {
                    ...state,
                    allInternationalSavedBankList: payload
                }
        default:
            return state;
    }
}