import React, {useEffect} from "react";

// import defaultAvatar from "../../../asset/default-avatar1.png";
import useGlobalContext from "../../../custom-hook/Context";
import RenderAction from "./RenderAction";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";
// import WebCamCapture from "./WebCamCapture";

const ProfileModal = ({ avatar, selectAvatar, getProfile, 
  profile }) => {
  useEffect(() => {
    getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const defaultAvatar = profile.profile.profile_pics
  const {smallDevice, showModal, closeModal, action, chooseRenderAction, updateAction} = useGlobalContext();

  return (
    <article className={`modal_container ${showModal ? "show_modal" : ""}`}>
      <section className="_modal">
        <header className="modal_header">
          <h4>Change {action === "Redem avatars" ? "avatar" : "picture"}</h4>
          <button type="button" onClick={() => {
            closeModal()
            updateAction("");
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16.2" height="16.2" viewBox="0 0 16.2 16.2">
              <path fill="#fdfdfd" d="M16.2 1.6 14.6 0 8.1 6.5 1.6 0 0 1.6l6.5 6.5L0 14.6l1.6 1.6 6.5-6.4 6.5 6.4 1.6-1.6-6.4-6.5Z"/>
            </svg>
          </button>
        </header>
        <section className="modal_content">
          <img src={avatar || defaultAvatar} alt="Avatar" />
        </section>
        <section className="modal_footer">
          <RenderAction
            action={action}
            smallDevice={smallDevice}
            chooseAction={chooseRenderAction}
          />
        </section>
      </section>
      {/* <WebCamCapture /> */}
    </article>
  )
};


ProfileModal.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(ProfileModal);