import React, {  useEffect } from "react";
import { Link} from "react-router-dom";
import MainHeader from "../../../layouts/header/components/MainHeader";
import BankIcon from "../../component/Bank-icon";
import plus from "../../../asset/Group 163480.png";
import "../styles/withdraw-main.css";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";
import { getSavedBankList } from "../../../js/actions/bankdetails";
import { getInternationalSavedBankList } from "../../../js/actions/bankdetails";

const WithdrawMain = ({ getProfile, profile, bank, name, getSavedBankList, allSavedBankList,getInternationalSavedBankList, allInternationalSavedBankList   }) => {
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  const kenyaWallet = JSON.parse(localStorage.getItem("kenyaWallet"));
  const ghanaWallet = JSON.parse(localStorage.getItem("ghanaWallet"));
  useEffect(() => {
    getProfile();
    getSavedBankList();
    getInternationalSavedBankList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="main">
      <MainHeader text="Withdraw" />
      <section className="withdraw__section__main">
        <div className="wallet__balance__component">
          <p className="wallet__balance">Wallet Balance</p>
          {userCountry === "nigeria" && (
              <p className="wallet__balance__amount">
                ₦ {profile.profile.wallet}.00
              </p>
            )}
            {userCountry === "kenya" && (
              <p className="wallet__balance__amount">
                K {kenyaWallet}.00
              </p>
            )}
            {userCountry === "ghana" && (
              <p className="wallet__balance__amount">
                ₵ {ghanaWallet}.00
              </p>
            )}
        </div>

       <>{userCountry === "nigeria" ? <> <div className="withdraw__bank__details__text__section">
          <h4 className="withdraw__bank__details__text">
            Select from added banks
          </h4>

          {allSavedBankList.length > 0 && (
            <Link to="/profile/bank-details/add-bank">
              <div className="withdraw__bank__details__add__bank__section">
                <h4 className="withdraw__bank__details__add__bank__text">
                  Add a bank account
                </h4>
                <div className="withdraw__bank__details__add__bank__image">
                  <img src={plus} alt="" />
                </div>
              </div>
            </Link>
          )}
        </div>
        <section className="withdraw__bank__details">
          {allSavedBankList.length < 1 && (
            <div>
              <div className="bank-icon">
                <BankIcon />
              </div>
              <h4 className="bank-details__header">No Bank Account Added</h4>
              <Link to="/profile/bank-details/add-bank">
                <button type="button" className="bank-details__button">
                  Add bank account
                </button>
              </Link>
            </div>
          )}
          {allSavedBankList.length > 0 && (
            <div className="withdraw__bank">
              {allSavedBankList.map((data, index) => {
                const { bankcode, bankholder, bankname, nuban } = data;
                return (
                  <Link
                  to={{
                    pathname: `/withdrawal-details/${data.id}/proceed`,
                    state: {
                      bankcode, bankholder, bankname, nuban 
                    },
                  }}
                  className="airtime__transaction__body__link"
                  key={index}
                >
                  <section className="saved__withdraw__banks__info__section">
                    <div
                      className="saved__withdraw__banks__info"
                    >
                      <div className="saved__withdraw__banks__carddetails__bank__info">
                        <div className="saved__withdraw__banks__carddetails__bank__info__image">
                          <img
                            src={require(`../../../asset/${bankcode}.svg`)}
                            alt="bank-icon"
                            className=""
                          />
                        </div>
              
                        <div className="saved__withdraw__banks__carddetails__info">
                          {/* <p className="saved__withdraw__banks__carddetails__info__bankname">
                            {bankname}
                          </p> */}
                          <div className="saved__withdraw__banks__carddetails__info__sub">
                            <p className="saved__withdraw__banks__carddetails__info__name">
                              {bankholder}
                            </p>
                            <p className="saved__withdraw__banks__carddetails__info__account">
                              {nuban}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  </Link>
                );
              })}
            </div>
          )}
        </section></>
        :
        // THIS PART IS FOR INTERNATIONAL BANKS
        <> <div className="withdraw__bank__details__text__section">
          <h4 className="withdraw__bank__details__text">
            Select from added banks
          </h4>

          {allInternationalSavedBankList.length > 0 && (
            <Link to="/profile/bank-details/add-bank">
              <div className="withdraw__bank__details__add__bank__section">
                <h4 className="withdraw__bank__details__add__bank__text">
                  Add a bank account
                </h4>
                <div className="withdraw__bank__details__add__bank__image">
                  <img src={plus} alt="" />
                </div>
              </div>
            </Link>
          )}
        </div>
        <section className="withdraw__bank__details">
          {allInternationalSavedBankList.length < 1 && (
            <div>
              <div className="bank-icon">
                <BankIcon />
              </div>
              <h4 className="bank-details__header">No Bank Account Added</h4>
              <Link to="/profile/bank-details/add-bank">
                <button type="button" className="bank-details__button">
                  Add bank account
                </button>
              </Link>
            </div>
          )}
          {allInternationalSavedBankList.length > 0 && (
            <div className="withdraw__bank">
              {allInternationalSavedBankList.map((data, index) => {
                const { currency_code, customer_name, network, account_number  } = data;
                return (
                  <Link
                  to={{
                    pathname: `/withdrawal-details/${data.id}/proceed`,
                    state: {
                      currency_code, customer_name, network, account_number 
                    },
                  }}
                  className="airtime__transaction__body__link"
                  key={index}
                >
                  <section className="saved__withdraw__banks__info__section">
                    <div
                      className="saved__withdraw__banks__info"
                    >
                      <div className="saved__withdraw__banks__carddetails__bank__info">
                        <div className="saved__withdraw__banks__carddetails__bank__info__image">
                          <img
                            src={require(`../../../asset/${currency_code}.svg`)}
                            alt="bank-icon"
                            className=""
                          />
                        </div>
              
                        <div className="saved__withdraw__banks__carddetails__info">
                          {/* <p className="saved__withdraw__banks__carddetails__info__bankname">
                            {bankname}
                          </p> */}
                          <div className="saved__withdraw__banks__carddetails__info__sub">
                            <p className="saved__withdraw__banks__carddetails__info__name">
                              {customer_name}
                            </p>
                            <p className="saved__withdraw__banks__carddetails__info__account">
                              {account_number}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  </Link>
                );
              })}
            </div>
          )}
        </section></>}</>

      </section>
    </main>
  );
};

WithdrawMain.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
  getSavedBankList: PropTypes.func,
  allSavedBankList: PropTypes.array,
  getInternationalSavedBankList: PropTypes.func,
  allInternationalSavedBankList: PropTypes.array,
};

const mapStateToProps = state => ({
  profile: state.profile,
  allSavedBankList: state.bankdetails.allSavedBankList,
  allInternationalSavedBankList: state.bankdetails.allInternationalSavedBankList,
});

export default connect(mapStateToProps, { getProfile, getSavedBankList,getInternationalSavedBankList })(
  WithdrawMain
);
