import React from "react";
import { Link } from "react-router-dom";

const CardItem = ({ id, name, icon, img, bgcolor }) => {

  return (
    <div 
      key={id} 
      id={id} 
      className="gift-item"
      style={{backgroundColor: bgcolor}}
    >
      <Link 
          to={{
            pathname: `/giftcard/${id}-card-details`,
            // state: {
            //  listType: "cardList",
            // },
          }}>
        <header className="gift-item-header">
          <h4>{name}</h4>
         <div className="gift-item-header-image">
         <img src={icon} alt="" />
         </div>
        </header>
        <div className="gift-item-content">
          <img src={img} alt={name} />
        </div>
      </Link>
    </div>
  );
}

export default CardItem;