import React, { useState } from "react";
import "../styles/forgot-password-change-password.css";
import axios from "../../../axios";
import Swal from "sweetalert2";


const ForgotPasswordChangePasswordMain = () => {
  const [input, setInput] = useState({
    password: "",
    newPassword: "",
  });

  const [error, setError] = useState({
    password: "",
    newPassword: "",
  });

  const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = e => {
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (input.newPassword && value !== input.newPassword) {
            stateObj["newPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["newPassword"] = input.newPassword
              ? ""
              : error.newPassword;
          }
          break;

        case "newPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  function showPassword() {
    document
      .querySelector(".toggle-forgot-password")
      .classList.toggle("fa-eye-slash");
    document
      .querySelector(".toggle-forgot-password")
      .classList.toggle("fa-eye");
    let x = document.getElementById("password-forgot-field");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  function showConfirmPassword() {
    document
      .querySelector(".toggle-confirm-password")
      .classList.toggle("fa-eye-slash");
    document
      .querySelector(".toggle-confirm-password")
      .classList.toggle("fa-eye");
    let x = document.getElementById("password-confirm-field");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  let myEmail = JSON.parse(localStorage.getItem("myEmail"));
  let code = JSON.parse(localStorage.getItem("verifyCode"));
  const handleInput = () => {
    let formBody = {
      email: myEmail,
      verificationCode: code,
      newPassword: input.password,
    };
    // console.log(formBody);

    axios
      .post("/rd/v1/auth/password/verify/out", formBody)
      .then(response => {
        // console.log(response.data.message);
        Swal.fire({
          icon: "success",
          text: `${response.data.message}`,
        })
          .then(function() {
            localStorage.clear();
            window.location.href = '/signin';
        })
      })
      .catch(e => {
        // console.log(e);
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };

  return (
    <section className="">
      <div className="child">
        <div className="forgot__password__home">
          <h3 className="forgot__password__heading">Change Your Password</h3>
          <p className="forgot__password__validate__email__sub__heading">
            Enter your new password to reset successfully. Make sure it's
            something you can remember next time
          </p>

          <div className="forgot__password__input__container">
            <div className="tv__details__section__form-group forgot__password__input">
              <div className="label-wrapper">
                <label className="forgot__pass__form__label">
                  New Password
                </label>
              </div>
              <input
                type="password"
                id="password-forgot-field"
                name="password"
                className="tv__details__section__input"
                value={input.password}
                onChange={onInputChange}
                onBlur={validateInput}
                required
              />
              <span
                toggle="#password-forgot-field"
                className="fa fa-eye-slash forgot__password__toggle toggle-forgot-password"
                onClick={showPassword}
              ></span>
              <div>
                {error.password && (
                  <span className="err">{error.password}</span>
                )}
              </div>
            </div>

            <div className="tv__details__section__form-group forgot__password__input">
              <div className="label-wrapper">
                <label className="forgot__pass__form__label">
                  Confirm Password
                </label>
              </div>
              <input
                type="password"
                id="password-confirm-field"
                name="newPassword"
                className="tv__details__section__input"
                value={input.newPassword}
                onChange={onInputChange}
                onBlur={validateInput}
                required
              />
              <span
                toggle="#password-confirm-field"
                className="fa fa-eye-slash second__forgot__pasword__toggle forgot__password__toggle toggle-confirm-password"
                onClick={showConfirmPassword}
              ></span>
              <div>
                {error.newPassword && (
                  <span className="err">{error.newPassword}</span>
                )}
              </div>
            </div>
            {input.newPassword === input.password && (
              <div className="change__pass__buttons">
                <button
                  type="submit"
                  className="change__pass__button"
                  onClick={() => handleInput()}
                >
                  Change password
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordChangePasswordMain;
