import React from "react";
import { NavLink } from "react-router-dom";
// import ReactCSSTransitionGroup from "react-addons-css-transition-group";
// import useGlobalContext from "../../../custom-hook/Context";
//import { navLinksData } from "./sidebarData";
import homeIcon from "../../../asset/house.svg";
import wallet from "../../../asset/wallet.svg";
import giftcards from "../../../asset/Giftcards.svg";
// import stars from "../../../asset/star.svg";
import moneybag from "../../../asset/money-bag.svg";
import fire from "../../../asset/fire (1) (1).svg";
import calculator from "../../../asset/calculator-svgrepo-com.svg";
// import branch from "../../../asset/branch.svg";
import "../styles/sidebar-link.css";

const SidebarLink = () => {
  // const { showSidebarDropdown, toggleSidebarDropdown } = useGlobalContext();

  // const sidebarDropdown = showSidebarDropdown && (
  //   <div className="dropdown-content">
  //     <img src={branch} alt="" />
  //     <ul className="sublink_list">
  //       <li className="sublink_item">
  //         <NavLink to="/all-rates" className="sublink_link">
  //           All Rates Calculator
  //         </NavLink>
  //       </li>
  //       <li className="sublink_item">
  //         <NavLink to="/hottest-rates" className="sublink_link">
  //           Hottest Rates
  //         </NavLink>
  //       </li>
  //     </ul>
  //   </div>
  // );

  // const dropdownIcon = !showSidebarDropdown ? (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     width="12"
  //     height="7.41"
  //     viewBox="0 0 12 7.41"
  //   >
  //     <path fill="#d3d3d3" d="M1.41 0 6 4.58 10.59 0 12 1.41l-6 6-6-6Z" />
  //   </svg>
  // ) : (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     width="14"
  //     height="8.64"
  //     viewBox="0 0 14 8.64"
  //   >
  //     <path fill="#d3d3d3" d="M12.36 8.65 7 3.3 1.65 8.64 0 7l7-7 7 7Z" />
  //   </svg>
  // );

  
  // const history = useHistory();

  // function logOut() {
  //   localStorage.clear();
  //   history.push("/signin");
  //   window.location.reload(false);
  // }
  return (
    <ul className="sidebar-list">
      <li className="sidebar-item">
        <NavLink to="/dashboard" exact className="sidebar-link">
          <img src={homeIcon} alt="Home icon" />
          <span>Home</span>
        </NavLink>
      </li>
      <li className="sidebar-item">
        <NavLink to="/wallet" className="sidebar-link">
          <img src={wallet} alt="Wallet icon" />
          <span>Wallet</span>
        </NavLink>
      </li>
      <li className="sidebar-item">
        <NavLink to="/giftcard" className="sidebar-link">
          <img src={giftcards} alt="Giftcard icon" />
          <span>Giftcards</span>
        </NavLink>
      </li>
      <li className="sidebar-item">
        <NavLink to="/hottest-rates" className="sidebar-link">
          <img src={fire} alt="Giftcard icon" />
          <span>Hottest Rates</span>
        </NavLink>
      </li>
      <li className="sidebar-item">
        <NavLink to="/all-rates" className="sidebar-link">
          <img src={calculator} alt="Giftcard icon" />
          <span>All Rates Calculator</span>
        </NavLink>
      </li>
      {/* <li className="sidebar-item dropdown">
        <div className="dropdown-toggler" onClick={toggleSidebarDropdown}>
          <img src={stars} alt="Rates icon" />
          <span>Rates</span>
          {dropdownIcon}
        </div>
        <ReactCSSTransitionGroup
          transitionName="dropdown-menu"
          transitionAppear={true}
          transitionEnterTimeout={500}
          transitionAppearTimeout={500}
          transitionLeaveTimeout={300}
        >
          {sidebarDropdown}
        </ReactCSSTransitionGroup>
      </li> */}
      <li className="sidebar-item">
        <NavLink to="/transactions" className="sidebar-link">
          <img src={moneybag} alt="Transaction icon" />
          <span>Transactions</span>
        </NavLink>
      </li>
      {/* <li className="sidebar-item" onClick={logOut}>
        <div className="sidebar-link">
          <img src={moneybag} alt="Transaction icon" />
          <span>Logout</span>
        </div>
      </li> */}
    </ul>
  );
};

export default SidebarLink;
