import React from "react";
import PropTypes from "prop-types";
import arrowDown from "../asset/ic_keyboard_arrow_down_24px.svg";

const TabsSelect = ({ name, icon,  value, onSelect, list }) => {
  
  return (
    <>
      <div className="label-wrapper">
        {/* <label htmlFor={name}>{label}</label> */}
        <span className="select-icon">
          { icon && <img src={icon} alt="" /> }
        </span>
      </div>
      <div className="form-group">
        <select
          name={name} 
          id={name} 
          value={value}
          onChange={onSelect}
          required
        >
          {list?.map(item => {
            const { name:bankname, bankcode } = item;
            return (
              <option key={bankcode} value={bankname}>{bankname}</option>
            )
          })}
        </select>
        <img src={arrowDown} alt="" />
      </div>
    </>
  )
};

TabsSelect.propTypes = {
  name: PropTypes.string.isRequired,
  // label: PropTypes.string.isRequired,
  // value: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired
}

export default TabsSelect;
