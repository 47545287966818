import { GIFTCARDS_LIST, GIFTCARDS_HISTORY, PENDING_GIFTCARDS_TRADES } from "../constants/action-types";

const initialState = {
    giftcardsList: [],
    giftcardsHistory: [],
    pendingGiftcardsTrades: [],
}

export default function giftcardsReducer(state = initialState, action) {
    const {type, payload} = action;

    switch(type) {
        case GIFTCARDS_LIST:
            return {
                ...state,
                giftcardsList: payload
            }
            case GIFTCARDS_HISTORY:
                return {
                    ...state,
                    giftcardsHistory: payload
                }

            case PENDING_GIFTCARDS_TRADES:
                return {
                    ...state,
                    pendingGiftcardsTrades: payload
                }
        default:
            return state;
    }
}