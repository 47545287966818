import React, {useEffect} from "react";
import useGlobalContext from "../custom-hook/Context";
import WithdrawSummaryMain from "../content/withdraw/components/WithdrawSummaryMain";

const WithdrawSummary = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return (
    <div>
      <WithdrawSummaryMain />
    </div>
  );
};

export default WithdrawSummary;
