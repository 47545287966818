import React from "react";
import { Link } from "react-router-dom";
import "../styles/allhottestrates.css";
import useGlobalContext from "../../custom-hook/Context";
// import empty from "../../asset/empty giftcard holder.png";

// card: null,
// // country: null,
// type: cardTypeList[0].value,
// receipt: cardReceiptList[0].value,
// hasReceipt: cardReceiptList[0].value,
// // giftcardValue: [],
// // denomination: denominationList[0].value,
// tags: [],
// amount: "",
// rate: "",
// // category: "",
// // rate: "$150/$",
// file: null,
const HottestRatesPageItem = ({
  rates,
  subcategory,
  category,
  country,
  identifier,
  id,
  hottestrateslist,
}) => {
  const { formData, setFormData } = useGlobalContext();
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));

  // const handleSelectedHottestRatesCard = e => {
  //   setFormData({
  //     ...formData,
  //     rates,
  //     subcategory,
  //     category,
  //     country,
  //     identifier,
  //     id,
  //   });
  // };

  const handleSelectedDenomination = e => {
    let card = hottestrateslist.find(item => item.id === Number(id));
    let rate = card.rates;
    let cardCategory = card.category;
    let cardSubCategory = card.subcategory;
    let receipt = card.receipt;
    // let cardName = card.name;

    // setFormData(e.target.value);
    setFormData({
      ...formData,
      rate,
      cardCategory,
      cardSubCategory,
      receipt,
      country,
    });
  };

  return (
    <div className="card card-con">
      <div className="card-home">
        <img
          src={require(`../../asset/giftcards/${country.toLowerCase()}.png`)}
          className=""
          alt="card-img"
        />
        {/* <img src={unitedKing} alt="country-img" /> */}
      </div>
      <div className="card-cont">
        <div className="card-house">
          <img
            src={require(`../../asset/giftcards/${identifier.toLowerCase()}.png`)}
            className=""
            alt="card-img"
          />
        </div>
        <div className="">
          <p className="card-name">
            {`${category}`}
            {`(${subcategory})`}
          </p>

          <div className="card-name-footer">
            {userCountry === "nigeria" && (
              <p className="card-name-bold">{`₦ ${rates}/ $`}</p>
            )}
            {userCountry === "kenya" && (
              <p className="card-name-bold">{`K ${rates}/ $`}</p>
            )}
            {userCountry === "ghana" && (
              <p className="card-name-bold">{`₵ ${rates}/ $`}</p>
            )}

            <Link
              to={{
                pathname: `/giftcard/${id}-card-details`,
                state: {
                  hottestrateslist: true,
                },
              }}
            >
              <button
                className="btn btn-btn"
                onClick={handleSelectedDenomination}
              >
                Trade
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HottestRatesPageItem;
