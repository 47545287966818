import React from 'react'
import { useLottie } from "lottie-react";
import animationData from './Disciple-Loop-Fire.json';

export default function GiftcardHistoryLottie() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
      const { View } = useLottie(defaultOptions);
      return (
        <div style={{ width: "60px", height: "60px" }} >
          {View}
        </div>
      );
  }
