// import React, { useState, useRef } from "react";
import React, { useState, useEffect } from "react";
import CountryValues from "./CountryValues";
import {
  cardData,
  countryList,
  // denominationList,
  // countriesValueList,
} from "../../data/allRatesData";
import RatesCardSlider from "./RatesCardSlider";
import switchIcon from "../../../asset/switch.svg";
import arrowDown from "../../../asset/ic_keyboard_arrow_down_24px.svg";
import arrowLeft from "../../../asset/ic_keyboard_arrow_left_24px.svg";
import arrowRight from "../../../asset/ic_keyboard_arrow_right_24px (1).svg";
import useGlobalContext from "../../../custom-hook/Context";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getGiftcardsList } from "../../../js/actions/giftcards";

const RatesCalculatorContent = ({ getGiftcardsList, giftcardsList }) => {
  const [denominationList, setDenominationList] = useState([]);
  const [domCountries, setDomCountries] = useState(countryList);
  const [denomination, setDenomination] = useState("");
  const [cardRate, setCardRate] = useState("");
  const { smallDevice, getCalculateRate, calculateRate } = useGlobalContext();
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));

  useEffect(() => {
    getGiftcardsList();
    setDenominationList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = e => {
    if (["e", ".", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const chooseCountry = id => {
    const newCountriesList = domCountries.map(country => {
      if (country.id === id) {
        return { ...country, selected: true };
      }
      return { ...country, selected: false };
    });

    const selectCountry = domCountries.find(country => country.id === id);

    localStorage.setItem("selectedCountry", JSON.stringify(selectCountry));
    let country = JSON.parse(
      localStorage.getItem("selectedCountry")
    ).country.toLowerCase();
    let selectedCard = localStorage.getItem("selectedCard").toLowerCase();
    setDomCountries(newCountriesList);

    // console.log(giftcardsList)
    let list = giftcardsList.filter(
      card =>
        card.category.toLowerCase() === selectedCard &&
        card.country.toLowerCase() === country
    );
    setDenominationList(list);
  };

  const handleSelectedDenomination = e => {
    let card = giftcardsList.find(card => card.id === Number(e.target.value));
    setDenomination(e.target.value);
    // console.log(card.rates)
    setCardRate(card.rates);
  };

  const total = calculateRate * cardRate;

  return (
    <article className="all-rates-calculator card">
      <section className="rates-content">
        <div className="rates-left">
          <div>
            <RatesCardSlider
              cards={cardData}
              next={arrowRight}
              prev={arrowLeft}
            />
          </div>

          <form action="">
            <div className="form-group countries">
              <h4>Select currency</h4>
              <article className="country-list">
                {domCountries?.map(country => {
                  const { id, name, selected } = country;

                  return (
                    <CountryValues
                      key={id}
                      id={id}
                      name={name}
                      selected={selected}
                      selectCountry={chooseCountry}
                    />
                  );
                })}
                {/* <button type="button" onClick={handleDropdown}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.53"
                    height="5.27"
                    viewBox="0 0 8.53 5.27"
                  >
                    <path
                      fill="#1b1c1d"
                      d="m1 0 3.27 3.26L7.53 0l1 1-4.26 4.27L0 1Z"
                    />
                  </svg>
                </button>

                <div
                  className={`dropdown-container ${
                    showDropdown ? "show-dropdown" : ""
                  }`}
                  ref={dropdownRef}
                >
                  <ul className="dropdown">
                    {countries.map(country => {
                      const { id, name } = country;
                      return (
                        <li
                          className="dropdown-item"
                          key={id}
                          onClick={selectCountry}
                          data-id={id}
                        >
                          {name}
                        </li>
                      );
                    })}
                  </ul>
                </div> */}
              </article>
            </div>
            <label htmlFor="denomination">Select denomination</label>
            <div className="form-group denomination">
              <select
                name="denomination"
                id="denomination"
                value={denomination}
                onChange={handleSelectedDenomination}
              >
                <option value="" hidden>
                  select item
                </option>
                {denominationList &&
                  denominationList.map(denomation => {
                    const { category, id, subcategory } = denomation;
                    return (
                      <option key={id} value={id}>
                        {category} [{subcategory}]
                      </option>
                    );
                  })}
              </select>
              <img src={arrowDown} alt="" />
            </div>
          </form>

          <p>
            <span>Current rate</span>
            {userCountry === "nigeria" && <span>₦{cardRate}/$</span>}
            {userCountry === "kenya" && <span>K{Math.round(cardRate)}/$</span>}
            {userCountry === "ghana" && <span> ₵ {Math.round(cardRate)}/$</span>}
          </p>
        </div>
        <div className="rates-right">
          <form action="">
            <div className="form-group">
              <label
                htmlFor=""
                style={{ display: `${smallDevice ? "none" : "inline-block"}` }}
              >
                Enter amount
              </label>
              <input
                type="number"
                id="currency"
                name="currency"
                className="currency-input"
                onKeyUp={e => getCalculateRate(e)}
                onKeyDown={e => validate(e)}
                placeholder="0.00"
                required
              />
              <label
                htmlFor="currency"
                className="input_placeholder"
                style={{ display: `${smallDevice ? "inline-block" : "none"}` }}
              >
                Enter amount to convert
              </label>
            </div>
          </form>
          <div className="img-wrapper">
            <img src={switchIcon} alt="" />
          </div>
          <div className="currency-equivalent">
            <h4>total equivalent</h4>
            {/* <p>{calculateRate}</p> */}

            {userCountry === "nigeria" && (
              <p className="currency-value">
                NGN&nbsp;
                <span> {`${total.toLocaleString()}`}</span>
                <span>.00</span>
              </p>
            )}
            {userCountry === "kenya" && (
              <p className="currency-value">
                K&nbsp;
                <span> {`${total.toLocaleString()}`}</span>
                <span>.00</span>
              </p>
            )}
            {userCountry === "ghana" && (
              <p className="currency-value">
                ₵&nbsp;
                <span> {`${total.toLocaleString()}`}</span>
                <span>.00</span>
              </p>
            )}
          </div>
        </div>
      </section>
    </article>
  );
};

RatesCalculatorContent.propTypes = {
  getGiftcardsList: PropTypes.func,
  giftcardsList: PropTypes.array,
};
const mapStateToProps = state => ({
  giftcardsList: state.giftcards.giftcardsList,
});

export default connect(mapStateToProps, { getGiftcardsList })(
  RatesCalculatorContent
);
