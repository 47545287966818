import React, { useState } from "react";
import MainHeader from "../../../layouts/header/components/MainHeader";
import setPinImage from "../../../asset/Group 162964.png";
import "../styles/set-pin-main.css";
import axios from "../../../axios";
import Swal from "sweetalert2";

const SetPinMain = () => {

  let token = JSON.parse(localStorage.getItem("token"));
  const [pin, setPin] = useState(null);

  const getPin = e => {
    let auth = e.target.value;
    setPin(auth);
  };

  const handleInput = () => {
    let formBody = {
      auth: Number(pin),
    };
    // console.log(formBody);

    axios
      .post("/rd/v1/auth/set-pin", formBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        // console.log(response.data.message);
          Swal.fire({
            icon:'success',
            // title: 'Pin set successfully',
            text: `${response.data.message}`
          }).then(function() {
            window.location.href = '/dashboard';
        })
      })
      .catch(e => {
        // console.log(e);
          Swal.fire({
            icon:'error',
            title: 'Forbidden',
            text: `${e.response.data.message}`
          })
      });
  };

  return (
    <main className="main">
      <MainHeader text="Set Pin" />
      <div className="change__pin">
        <div className="set__pin__main">
          <div className="set__pin__main__image">
            <img src={setPinImage} alt="set pin icon" />
          </div>
          <p className="set__pin__main__image__text">
            You'll be required to provide this secret pin whenever you want to
            login or perform transactions
          </p>
          <div>
            <label className="change__pass__form__label">Enter pin</label>
            <div className="tags-input-container">
              <input
                // onKeyDown={handleKeyDown}
                type="number"
                className="setpin-input"
                name="pin"
                pattern="[0-9]*"
                onKeyUp={e => getPin(e)}
                maxLength={4}
                required
              />
            </div>
            <div className="change__pass__buttons">
              <button
                type="submit"
                className="change__pass__button"
                onClick={() => handleInput()}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SetPinMain;
