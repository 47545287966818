import axios from "../../axios";
import { DATA_LIST } from "../constants/action-types";
import Swal from "sweetalert2";

export const getDataList = provider => async dispatch => {
  try {
    const response = await axios.get(`/rd/v1/bills/dataPlans/${provider}`);
    // console.log(response.data.data[0].plans[0].amount);

    dispatch({
      type: DATA_LIST,
      payload: response.data.data[0].plans,
    });
    const toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 1000,
    });
    window.toast = toast;
    //   toast.fire({icon: 'success', title: 'sucesss', text:`${response.data.message}` })
  } catch (error) {
    // console.log(error);
    Swal.fire({
      icon: "error",
      title: "Forbidden",
      text: `${error.response.data.message}`,
    });
  }
};
