import React from "react";

const Capsule = ({icon, text, textStyle, capsStyle}) => {

  return (
    <div className="capsule" style={capsStyle}>
      <div>
        <img src={icon} alt="" />
      </div>
      <p style={textStyle}>{text}</p>
    </div>
  )
}

export default Capsule;