import React from "react";
import useGlobalContext from "../../../custom-hook/Context";
import success from "../../../asset/success.svg";
import failed from "../../../asset/danger.svg";
import { useHistory } from "react-router-dom";
// import apple from "../../../asset/apple.svg";

const TransactionItem = ({
  id,
  cardType,
  category,
  dollar,
  status,
  active,
  selectHistoryItem,
  details,
  setShowModal,
  created_at,
  reference,
  naira,
  rate,
  country,
  badImages,
  comment,
}) => {
  const { smallDevice } = useGlobalContext();
  // const { TransactionHistoryMobile } =
  // useGlobalContext();
  const history = useHistory();


  const historyItemStyle = {
    borderLeft: `${
      smallDevice
        ? "none"
        : status === "successful"
        ? "4px solid rgb(37, 187, 50)"
        : "4px solid rgb(187, 37, 37)"
    }`,
    marginLeft: `${smallDevice ? "0" : "0.2rem"}`,
    backgroundColor: `${active ? "#F8F8F8" : "#ffffff"}`,
    // display: `${
    //   !smallDevice
    //     ? "grid"
    //     : smallDevice && status === "successful"
    //     ? "grid"
    //     : "none"
    // }`,
  };
  // function handleTransactionHistory() {

  // }
  const handleClick = () => {
    selectHistoryItem(id);
    if (smallDevice) {
      history.push("/transaction-history"); 
    }
  };

  // const transactionAction = smallDevice && status === "successful" && (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     data-name="Group 162497"
  //     width="14.89"
  //     height="14.3"
  //     viewBox="0 0 14.89 14.3"
  //   >
  //     <path
  //       fill="#459f45"
  //       d="M8.15.5a2.37 2.37 0 0 1 2.6.85v.01a2.37 2.37 0 0 0 1.13.82l.02.01a2.37 2.37 0 0 1 1.6 2.2v.05a2.37 2.37 0 0 0 .42 1.32l.03.04a2.37 2.37 0 0 1 0 2.7l-.03.04a2.37 2.37 0 0 0-.42 1.32v.05a2.37 2.37 0 0 1-1.6 2.2h-.01a2.37 2.37 0 0 0-1.13.83v.01a2.37 2.37 0 0 1-2.6.85 2.37 2.37 0 0 0-1.4 0 2.37 2.37 0 0 1-2.6-.85h-.02a2.37 2.37 0 0 0-1.12-.83L3 12.1a2.37 2.37 0 0 1-1.6-2.2v-.05a2.37 2.37 0 0 0-.43-1.31L.94 8.5a2.37 2.37 0 0 1 0-2.7l.03-.04a2.37 2.37 0 0 0 .43-1.32v-.05A2.37 2.37 0 0 1 3 2.19H3a2.37 2.37 0 0 0 1.13-.83v-.02A2.37 2.37 0 0 1 6.75.5a2.37 2.37 0 0 0 1.4 0Z"
  //       data-name="Polygon 1"
  //     />
  //     <path
  //       fill="#fdfdfd"
  //       d="M6.08 8.71 4.3 6.94l-.6.6 2.37 2.37 5.08-5.08-.6-.6Z"
  //     />
  //   </svg>
  // );

  // console.log(category)
  return (
    <>
        <article
          className="history-item"
          id={id}
          style={historyItemStyle}
          onClick={handleClick}
        >
          <div className="history-icon-wrapper">
            <img
              src={require(`../../../asset/giftcardshistory/${category.toLowerCase()}.png`)}
              className=""
              alt="card-img"
            />
          </div>
          <div className="history-details">
            <p>{category}</p>
            <p>
              ({category} {cardType})
            </p>
          </div>
          <div className="history-amount">
            {/* {transactionAction} */}
            <img
              src={status === "successful" ? success : failed}
              className="img-fluid check "
              alt=""
            />
            <p
              style={{
                color:
                  status === "successful"
                    ? "rgb(37, 187, 50)"
                    : "rgb(187, 37, 37)",
              }}
            >{`$${dollar}`}</p>
          </div>
        </article>
    </>
  );
};

export default TransactionItem;
