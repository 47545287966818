import React, { useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import useGlobalContext from "../../custom-hook/Context";
import MainHeader from "../../layouts/header/components/MainHeader";
// import banner from "./../../asset/Group 163290.png";
import "../styles/bankdetailsmain.css";
import BankSelect from "../../custom-hook/BankSelect";
import InternationalBankSelect from "../../custom-hook/InternationalBankSelect";
import padlock from "../../asset/Group 163392.svg";
import correct from "../../asset/Group 163393.svg";
import { getBankList } from "../../js/actions/bankdetails";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "../../axios";
import Swal from "sweetalert2";

const AddBank = ({ bankList, getBankList }) => {
  const { checkAuth, smallDevice } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  useEffect(() => {
    getBankList();
    // console.log(bankList);
    // console.log("here");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [accountNumber, setAccountNumber] = useState(null);
  const getAccountNumber = e => {
    let account = e.target.value;
    setAccountNumber(account);
  };

  const validate = e => {
    if (["e", ".", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const [bankDetails, setBankDetails] = useState("");
  const [kenyaBankDetails, setKenyaBankDetails] = useState("");
  const [bankNetworkDetails, setBankNetworkDetails] = useState("");
  const [validateLoading, setValidateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  let token = JSON.parse(localStorage.getItem("token"));
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));


  const handleValueChange = e => {
    const { value } = e.target;
    const code = bankList.find(item => item.name === value).bankcode;
    setBankDetails(code);
  };

  const config = {
    headers: {
      auth: `Bearer ${token}`,
    },
  };

  const handleInput = () => {
    setLoading(true)
    let formBody = {
      nuban: accountNumber,
      auth: config.headers.auth,
      bankcode: bankDetails,
    };
    axios
      .post("/rd/v1/payment/addBank", formBody)
      .then(response => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: `${response.data.message}`,
        }).then(function () {
          window.location.href = "/dashboard";
        });
      })
      .catch(e => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };

  const bankNetwork = e => {
    const { value } = e.target;
    setBankNetworkDetails(value);
  };
  const getKenyaAccountNumber = e => {
    let account = e.target.value;
    setAccountNumber(account);
  };

  const validateKenyaAccountNumber = e => {
    setValidateLoading(true);
    let formBody = {
      token: config.headers.auth,
      account_number: accountNumber,
      type: "mobile",
    };

    if (accountNumber.length === 10) {
      setTimeout(
        axios
          .post("/rd/v1/intl/account/validate", formBody)
          .then(response => {
            setKenyaBankDetails(response.data.data.customer_name);
            setValidateLoading(false);
          })
          .catch(e => {
            setValidateLoading(false);
            setKenyaBankDetails(e);
          }),
        2000
      );
    }
  };

  const handleFormInput = () => {
    setLoading(true);
    let formBody = {
      account_number: accountNumber,
      type: "mobile",
      network: bankNetworkDetails,
      auth: config.headers.auth,
    };
    axios
      .post("/rd/v1/intl/account/add", formBody)
      .then(response => {
        setLoading(false);
        console.log(response.data.message);
        Swal.fire({
          icon: "success",
          text: `${response.data.message}`,
        }).then(function () {
          window.location.href = "/dashboard";
        });
      })
      .catch(e => {
        setLoading(false);
        console.log(e.response.data.message);
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };

  useEffect(() => {
    if (step <= 0) {
      history.push("/profile/bank-details");
    }
  });

  const [step, setStep] = useState(1);
  const history = useHistory();

  // const nextStep = () => {
  //     setStep(prev => prev + 1);
  //   }

  const prevStep = () => {
    setStep(prev => prev - 1);
  };

  const backTrackBtn = smallDevice ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.18"
      height="8.39"
      viewBox="0 0 5.18 8.39"
    >
      <path
        fill="#1b1c1d"
        d="m5.18 7.4-3.2-3.2L5.18.99 4.2 0 0 4.2l4.2 4.19Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.16"
      height="11.6"
      viewBox="0 0 7.16 11.6"
    >
      <path
        fill="#1b1c1d"
        d="M7.16 10.24 2.74 5.8l4.42-4.44L5.8 0 0 5.8l5.8 5.8Z"
      />
    </svg>
  );

  return (
    <main className="main">
      <MainHeader />
      <div className="progress-header">
        <button className="progress-btn" onClick={prevStep}>
          {backTrackBtn}
        </button>
      </div>
      <section className="bank-details-add-bank">
        <div className="card bank-banner-card">
          <header className="bank-banner">
            <div className="bank__banner__div"></div>
          </header>
          {userCountry === "nigeria" ? (
            <div className="card-body card-body__bank-details">
              <div className="form-groupp">
                <BankSelect
                  name="selectbank"
                  label="Select Bank"
                  onSelect={handleValueChange}
                  list={bankList}
                />
              </div>

              <div className="form-groupp">
                <div className="label-wrapper">
                  <label htmlFor="accountlabel" className="account-label">
                    Account Number
                  </label>
                </div>
                <input
                  type="number"
                  name="accountnumber"
                  className="account"
                  onKeyUp={e => getAccountNumber(e)}
                  onKeyDown={e => validate(e)}
                  required
                />
              </div>

              <div className="add-bank__button">
                <button
                  type="button"
                  className="bank-details__button"
                  onClick={() => handleInput()}
                >
                 {loading ? "Adding bank account" : " Add bank account"}
                </button>
              </div>
            </div>
          ) : (
            <div className="card-body card-body__bank-details">
              <div className="form-groupp">
                <InternationalBankSelect onSelect={bankNetwork}/>
              </div>

              <div className="form-groupp">
                <div className="label-wrapper">
                  <label htmlFor="accountlabel" className="account-label">
                    Mobile Account Number
                  </label>
                </div>
                <input
                  type="number"
                  name="accountnumber"
                  className="account"
                  onInput={e => (e.target.value = e.target.value.slice(0, 10))}
                  onChange={e => getKenyaAccountNumber(e)}
                  onKeyUp={e => validateKenyaAccountNumber(e)}
                  onKeyDown={e => validate(e)}
                  required
                />
              </div>

              {validateLoading && <p>Loading account holder details...</p>}
              {kenyaBankDetails && (
                <div className="form-groupp">
                  <div className="label-wrapper">
                    <label htmlFor="accountlabel" className="account-label">
                      Account Holder
                    </label>
                  </div>
                  <input
                    className="account"
                    value={kenyaBankDetails}
                    readOnly
                  />
                </div>
              )}

             {kenyaBankDetails &&  <div className="add-bank__button">
                <button
                  type="button"
                  className="bank-details__button"
                  onClick={() => handleFormInput()}
                >
                    {loading ? "Adding bank account" : " Add bank account"}
                </button>
              </div>}
            </div>
          )}
        </div>

        <div className="addbank__last-section">
          <div className="addbank__last-section__footer">
            <img src={padlock} className="" alt="" />
            <p>Your account information is protected, all data are private.</p>
          </div>
          <div className="addbank__last-section__footer">
            <img src={correct} className="" alt="" />
            <p>
              Ensure your details are correct to prevent withdrawal into a wrong
              account.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

AddBank.propTypes = {
  getBankList: PropTypes.func,
  bankList: PropTypes.array,
};
const mapStateToProps = state => ({
  bankList: state.bankdetails.bankList,
});

export default connect(mapStateToProps, { getBankList })(AddBank);
