import React from "react";
import ForgotPasswordValidateEmailMain from "../content/forgot-password/components/ForgotPasswordValidateEmailMain";

const ForgotPasswordEmailValidation = () => {
  return (
    <div>
      <ForgotPasswordValidateEmailMain />
    </div>
  );
};

export default ForgotPasswordEmailValidation;
