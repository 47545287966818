import { GET_LEADERBOARD_MONTLY_TRADERS, GET_MY_LEADERBOARD_DATA } from "../constants/action-types";

const initialState = {
    leaderboardMonthlyList: [],
    myLeaderboardData: []
}

export default function leaderboardReducer(state = initialState, action) {
    const {type, payload} = action;

    switch(type) {
        case GET_LEADERBOARD_MONTLY_TRADERS:
            return {
                ...state,
                leaderboardMonthlyList: payload
            }
            case GET_MY_LEADERBOARD_DATA:
                return {
                    ...state,
                    myLeaderboardData: payload
                }
        default:
            return state;
    }
}

