import React, {useEffect} from "react";
import HottestRatesPageItem from "../../content/component/HottestRatesPageItem";
// import { HottestRatesPageItemData } from "../../content/data/mainContentData";
import "../styles/allhottestrates.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getHottestRates } from "../../js/actions/hottestrates";


const AllHottestRates = ({ getHottestRates, hottestrateslist }) => {
   useEffect(() => {
    getHottestRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section className="">
  {hottestrateslist && hottestrateslist.length < 1 && <p>Loading...</p>}
      {hottestrateslist && hottestrateslist.length > 0 && (
        <ul className="hottestlistcontent">
        {hottestrateslist && hottestrateslist.map((data, index) => {
            const { rates, id, subcategory, category, country,identifier} = data;

            return (
              <HottestRatesPageItem
                key={index}
                id={id}
                rates={rates}
                subcategory={subcategory}
                category={category}
                country={country}
                identifier={identifier}
                hottestrateslist={hottestrateslist}
                // icons={[icon1, icon2]}
                // name={name}
                // dollarAmt={dollarAmt}
                // nairaAmt={nairaAmt}
              />
            );
          })}
        </ul>
      )}
      {/* </div> */}
    </section>
  );
};


AllHottestRates.propTypes = {
  getHottestRates: PropTypes.func,
  hottestrateslist: PropTypes.array,
};
const mapStateToProps = state => ({
  hottestrateslist: state.hottestrates.hottestrateslist,
});

export default connect(mapStateToProps, { getHottestRates })(
  AllHottestRates
);