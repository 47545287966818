import React, { useEffect, useState } from "react";
import useGlobalContext from "../../../custom-hook/Context";
import MainHeader from "../../../layouts/header/components/MainHeader";
import CardBoxes from "./CardBoxes";
import { cardList, sortCardsData } from "../../data/giftcardData";
import SortCards from "../../component/SortCards";
import user from "../../../asset/user-image x2.png";
// import pointer from "../../../asset/pointer_2.png";
import "../styles/giftcard.css";
import GiftCardPageLottie from "../../component/GiftCardPageLottie";

const GiftCardMain = () => {
  const { mediumDevice, smallDevice } = useGlobalContext();


  const [cards, setCards] = useState(null);
  // const [clearOverlay, setClearOverlay] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Most traded");

  // Render by default
  useEffect(() => {
    if (selectedOption === "Most traded") {
      setCards(cardList);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (localStorage.getItem("clear-overlay")) {
      document.getElementById("cta").classList.add("clear-overlay");
    }
  }, []);

  const onClick = () => {
    document.getElementById("cta").classList.add("clear-overlay");
    localStorage.setItem("clear-overlay", true);
  };

  // Sort card on option select
  const sorted = value => {
    if (value === "Hot rates") {
      const mostRated = cards.sort((a, b) => a.rated - b.rated);

      setCards(mostRated);
    } else if (value === "Name (A-Z)") {
      const sortedAtoZ = cards.sort(function (a, b) {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 1;
      });
      setCards(sortedAtoZ);
    } else if (value === "Hot rates") {
      const sortedMostSales = cards.sort((a, b) => a.sales - b.sales);
      setCards(sortedMostSales);
    }
  };

  return (
    <main className="giftcard-main">
      {mediumDevice && <MainHeader user={user} />}
      <div className="giftcard-content">
        <CardBoxes cards={cards} />
      </div>
      <SortCards
        list={sortCardsData}
        state={selectedOption}
        stateSetter={setSelectedOption}
        name="sort"
        smallScreen={smallDevice}
        sortFunc={sorted}
        containerHeight={35}
      />

      <div className="cta-overlay" id="cta">
        <div className="cta-content">
          <div className="cta-info">
            <div>
              <GiftCardPageLottie />
            </div>
            <p>Click on a giftcard to start trading</p>
          </div>
          <div className="btn-container">
            <button className="cta-btn btn" onClick={() => onClick()}>
              Got it!
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default GiftCardMain;
