import React from "react";
import "./authlayout.css";
import { Route } from "react-router";

const Authlayout = ({ children }) => {
  return (
    <>
      <div className="authwrapper">
        <div className="col-sm-12 col-md-6 col-lg-6 d-sm-none d-md-block d-lg-block d-flex justify-content-center align-items-center text-center main--section">
          <div className="main__section__image">
            <img
              src="https://res.cloudinary.com/dpqxraalv/image/upload/v1632238705/Group_162463_2x_rmqvv3.png"
              alt="coffee img"
              // className="img-fluid"
              loading="lazy"
            />
          </div>
        </div>

        <div>
          <main className="children-section">{children}</main>
        </div>
      </div>
    </>
  );
};

const MainAuthLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Authlayout>
          <Component {...props} />
        </Authlayout>
      )}
    />
  );
};

export default MainAuthLayout;
