import React from "react";
import icon from "../../../asset/trophy-svgrepo-com.svg";
import CrownLottie from "../../component/CrownLottie";

const LeaderboardTradersItemMobile = ({  profile_pics, username, points  }) => {
  return (
    <div className="leaderboard__list__section leaderboardmobile__list">
      <div className="leaderboardmobile__list__section__image ">
        <img src={profile_pics} alt="" className="leaderboard__list__section__img" />
        <div className="leaderboard__crown__span"><CrownLottie/></div>
      </div>
      <div className="leaderboard__list__section__text">
        <p className="leaderboardmobile__list__first__text">{username}</p>
        <div className="leaderboard__list__section__section">
          <p className="leaderboardmobile__list__second__text">{points}</p>
          <div className="leaderboardmobile__list__section__section__image">
            <img src={icon} alt=""  />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardTradersItemMobile;