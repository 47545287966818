import React from 'react'
import { useLottie } from "lottie-react";
import animationData from './ClickHereLottie.json';

export default function GiftCardPageLottie() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
      const { View } = useLottie(defaultOptions);
      return (
        <div style={{ width: "60px", height: "60px" }} >
          {View}
        </div>
      );
  }
