import React, {useState} from "react";
import "../styles/forgot-password-validate-email.css";
import { useHistory } from "react-router-dom";
import axios from "../../../axios";
import Swal from "sweetalert2";
import emailBox from "../../../asset/Email.png";

const ForgotPasswordValidateEmailMain = () => {
  let myEmail = JSON.parse(localStorage.getItem('myEmail'))
  const [verifyCode, setVerifyCode] = useState(null);

  const history = useHistory();

  const getVerificationCode = e => {
    let verificationCode = e.target.value;
    setVerifyCode(verificationCode);
  };
  localStorage.setItem("verifyCode", JSON.stringify(verifyCode));
  


  const handleResend = () => {
    let formBody = {
      email: myEmail,
    };
    // console.log(formBody);

    axios
      .post("/rd/v1/auth/password/reset/out/init", formBody)
      .then(response => {
        // console.log(response.data.message);
        Swal.fire({
          icon: "success",
          // title: 'Pin set successfully',
          text: `${response.data.message}`,
        }).then(function() {
          window.location.href = '/forgot-password-validate-email';
      })
      })
      .catch(e => {
        // console.log(e);
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };

  const handleInput = () => {
    history.push("/forgot-password-change-password");
  };
  return (
    <section className="">
      <div className="child">
        <div className="emailbox">
          <img src={emailBox} alt="email box" />
        </div>
        <div className="forgot__password__home">
          <h3 className="forgot__password__heading">Change Your Password</h3>
          <p className="forgot__password__validate__email__sub__heading">
            We sent a code to <span>{`${myEmail}`}.</span>
            Enter the code to change your password.
          </p>

          <div className="forgot__password__input__container">
            <div className="tv__details__section__form-group forgot__password__input">
              <input
                type="number"
                name="number"
                className="tv__details__section__input"
                pattern="[0-9]*"
                onKeyUp={e => getVerificationCode(e)}
                required
              />
            </div>
            <div className="change__pass__buttons">
              <button
                type="submit"
                className="change__pass__button"
                onClick={() => handleInput()}
              >
                Proceed
              </button>
            </div>

            <p className="forgot__password__sub__heading resend__code">
              Didn't get code? Check your spam folder or <span  onClick={() => handleResend()}>Resend</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordValidateEmailMain;
