import React from "react";
import Capsule from "./Capsule";
import { quickSellerData } from "../data/capsuleData";
import "../styles/quick-seller.css";
import { Link } from "react-router-dom";

const QuickSeller = () => {
  return (
    <section className="quick-seller">
      {quickSellerData.map((data, index) => {
        const { icon, text } = data;
        return (
          <Link to="/subscription" className="capsule__link" key={index}>
            <Capsule
              key={index}
              icon={icon}
              text={text}
              textStyle={{
                flexBasis: "40%",
                fontSize: "0.875rem",
                lineHeight: "1.375rem",
                // textAlign: "end"
              }}
              capsStyle={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textAlign: "left",
                padding: "0.8rem",
                background: "rgb(239, 239, 239)",
                borderRadius: "15px",
                height: "110px",
              }}
            />
          </Link>
        );
      })}
    </section>
  );
};

export default QuickSeller;
