import Avatar1 from "../../asset/redem-avatars/Beginner.png";
import Avatar2 from "../../asset/redem-avatars/Novice.png";
import Avatar4 from "../../asset/redem-avatars/Disciple.png";
import Avatar7 from "../../asset/redem-avatars/Master 2.png";
import Avatar9 from "../../asset/redem-avatars/Invincible 2.png";
import Avatar10 from "../../asset/redem-avatars/Trade Baron.png";
import Avatar13 from "../../asset/redem-avatars/Redeemer.png";
import Avatar14 from "../../asset/redem-avatars/Lil Wvyne.png";
import Avatar15 from "../../asset/redem-avatars/Emin3m.png";
import Avatar16 from "../../asset/redem-avatars/Alpha.png";
import Avatar17 from "../../asset/redem-avatars/J. Kole.png";
import Avatar18 from "../../asset/redem-avatars/Kendreek.png";
import Avatar19 from "../../asset/redem-avatars/Lil Wvyne 2.png";
import Avatar20 from "../../asset/redem-avatars/Dunda.png";
import Avatar21 from "../../asset/redem-avatars/Jay V.png";
import Avatar22 from "../../asset/redem-avatars/Dr4ke.png";
import Avatar23 from "../../asset/redem-avatars/Jay V 2.png";
import Avatar24 from "../../asset/redem-avatars/Kendreek 2.png";
import Avatar33 from "../../asset/redem-avatars/J.Kole 2.png";
import Avatar34 from "../../asset/redem-avatars/Dr4ke 2.png";
import Avatar35 from "../../asset/redem-avatars/Dunda 2.png";
import Avatar36 from "../../asset/redem-avatars/Emin3m 2.png";





import icon from "../../asset/Group 162497.svg";
import Avatar25 from "../../asset/Alpha Cover.png";
import Avatar26 from "../../asset/Disciple Cover On Display.svg";
import Avatar27 from "../../asset/King Of Trades (Drake).png";
import Avatar28 from "../../asset/Master Cover On Display.svg";
import Avatar29 from "../../asset/Rectangle 147825.svg";
import Avatar30 from "../../asset/Rectangle 147826.svg";
import Avatar31 from "../../asset/Rectangle 147827.svg";
import Avatar32 from "../../asset/Rectangle 147828.svg";







export const AvatarImagesItemData = [
  {
    image: `${Avatar1}`,
    levelName: "Beginner",
    status: "Default",
    icon: `${icon}`,
    level:1,
    id: 1,
    avatar:"beginner"
  },

  {
    image: `${Avatar2}`,
    levelName: "Novice",
    status: "Trade between ₦0 - 200k",
    icon: `${icon}`,
    level:2,
    id: 2,
    avatar:"novice"

  },
  {
    image: `${Avatar4}`,
    levelName: "Disciple",
    status: "Trade between ₦200k - ₦500k",
    icon: `${icon}`,
    level:3,
    id: 3,
    avatar:"disciple"
  },
  {
    image: `${Avatar7}`,
    levelName: "Master",
    status: "Trade between ₦500k - ₦1m",
    icon: `${icon}`,
    level:4,
    id: 4,
    avatar:"master"
  },

  {
    image: `${Avatar9}`,
    levelName: "Invincible",
    status: "Trade between ₦1m - ₦2m",
    icon: `${icon}`,
    level: 5,
    id: 5,
    avatar:"invincible"
  },
  {
    image: `${Avatar10}`,
    levelName: "Trade Baron",
    status: "Trade between ₦2m - ₦4m",
    icon: `${icon}`,
    level: 6,
    id: 6,
    avatar:"tradebaron"
  },
 

  {
    image: `${Avatar13}`,
    levelName: "Redeemer",
    status: "Trade between ₦4m - ₦8m",
    icon: `${icon}`,
    level:7,
    id: 7,
    avatar:"redeemer"
  },

  {
    image: `${Avatar16}`,
    levelName: "Alpha",
    status: "Trade between ₦8m - ₦10m",
    icon: `${icon}`,
    level:8,
    id: 8,
    avatar:"alpha"
  },

  {
    image: `${Avatar17}`,
    levelName: "J.Kole",
    status: "Trade between ₦8m - ₦10m",
    icon: `${icon}`,
    level:9,
    id: 9,
    avatar:"jkole1"
  },
  {
    image: `${ Avatar33}`,
    levelName: "J.Kole",
    status: "Trade between ₦8m - ₦10m",
    icon: `${icon}`,
    level:9,
    id: 10,
    avatar:"jkole2"
  },
 
  {
    image: `${Avatar18}`,
    levelName: "Kendreek",
    status: "Trade between ₦15m - ₦20m",
    icon: `${icon}`,
    level:10,
    id: 11,
    avatar:"kendreek1"
  },
  {
    image: `${ Avatar24}`,
    levelName: "Kendreek",
    status: "Trade between ₦15m - ₦20m",
    icon: `${icon}`,
    level:10,
    id: 12,
    avatar:"kendreek2"
  },
    {
    image: `${Avatar14}`,
    levelName: "Lil Wvyne",
    status: "Trade between ₦20m - ₦30m",
    icon: `${icon}`,
    level:11,
    id: 13,
    avatar:"lilwayne1"
  },
  {
    image: `${Avatar19}`,
    levelName: "Lil Wvyne",
    status: "Trade between ₦20m - ₦30m",
    icon: `${icon}`,
    level:11,
    id: 14,
    avatar:"lilwayne2"
  },
  {
    image: `${Avatar22}`,
    levelName: "Dr4ke",
    status: "Trade between ₦30m - ₦35m",
    icon: `${icon}`,
    level:12,
    id: 15,
    avatar:"dr4ke1"
  },
  {
    image: `${Avatar34}`,
    levelName: "Dr4ke",
    status: "Trade between ₦30m - ₦35m",
    icon: `${icon}`,
    level:12,
    id: 16,
    avatar:"dr4ke2"
  },
  {
    image: `${Avatar20}`,
    levelName: "Dunda",
    status: "Trade between ₦35m - ₦40m",
    icon: `${icon}`,
    level:13,
    id: 17,
    avatar:"dunda1"
  },
  {
    image: `${Avatar35}`,
    levelName: "Dunda",
    status: "Trade between ₦35m - ₦40m",
    icon: `${icon}`,
    level:13,
    id: 18,
    avatar:"dunda2"
  },
  
  {
    image: `${Avatar21}`,
    levelName: "Jay V",
    status: "Trade between ₦40m - ₦45m",
    icon: `${icon}`,
    level:14,
    id: 19,
    avatar:"jayv1"
  },
  {
    image: `${Avatar23}`,
    levelName: "Jay V",
    status: "Trade between ₦40m - ₦45m",
    icon: `${icon}`,
    level:14,
    id: 20,
    avatar:"jayv2"
  },
  {
    image: `${Avatar15}`,
    levelName: "Emin3m",
    status: "Trade between ₦45m - ₦50m",
    icon: `${icon}`,
    level:15,
    id: 21,
    avatar:"emin3m1"
  },
  {
    image: `${Avatar36}`,
    levelName: "Emin3m",
    status: "Trade between ₦45m - ₦50m",
    icon: `${icon}`,
    level:15,
    id: 22,
    avatar:"emin3m2"
  },
 
];

export const CoverImagesItemData = [
  {
    image: `${Avatar32}`,
    level: "The Baron 2",
    status: "Unlocked",
    icon: `${icon}`,
  },
  {
    image: `${Avatar26}`,
    level: "Master 2",
    status: "Unlocked",
    icon: `${icon}`,
  },

  {
    image: `${Avatar27}`,
    level: "Beginner",
    status: "Unlocked",
    icon: `${icon}`,
  },

  {
    image: `${Avatar28}`,
    level: "Disciple 2",
    status: "Unlocked",
    icon: `${icon}`,
  },
  {
    image: `${Avatar29}`,
    level: "The Baron 2",
    status: "Unlocked",
    icon: `${icon}`,
  },
  {
    image: `${Avatar30}`,
    level: "Master 2",
    status: "Unlocked",
    icon: `${icon}`,
  },
  {
    image: `${Avatar31}`,
    level: "The Baron",
    status: "Unlocked",
    icon: `${icon}`,
  },

  {
    image: `${Avatar32}`,
    level: "Beginner",
    status: "Unlocked",
    icon: `${icon}`,
  },
  {
    image: `${Avatar25}`,
    level: "The Baron 2",
    status: "Unlocked",
    icon: `${icon}`,
  },
  {
    image: `${Avatar26}`,
    level: "Master 2",
    status: "Unlocked",
    icon: `${icon}`,
  },

  {
    image: `${Avatar27}`,
    level: "Beginner",
    status: "Unlocked",
    icon: `${icon}`,
  },

  {
    image: `${Avatar28}`,
    level: "Disciple 2",
    status: "Unlocked",
    icon: `${icon}`,
  },
  {
    image: `${Avatar29}`,
    level: "The Baron 2",
    status: "Unlocked",
    icon: `${icon}`,
  },
  {
    image: `${Avatar30}`,
    level: "Master 2",
    status: "Unlocked",
    icon: `${icon}`,
  },
  {
    image: `${Avatar31}`,
    level: "The Baron",
    status: "Unlocked",
    icon: `${icon}`,
  },

  {
    image: `${Avatar32}`,
    level: "Beginner",
    status: "Unlocked",
    icon: `${icon}`,
  },
  {
    image: `${Avatar25}`,
    level: "The Baron 2",
    status: "Unlocked",
    icon: `${icon}`,
  },
  {
    image: `${Avatar26}`,
    level: "Master 2",
    status: "Unlocked",
    icon: `${icon}`,
  },

  {
    image: `${Avatar27}`,
    level: "Beginner",
    status: "Unlocked",
    icon: `${icon}`,
  },

  {
    image: `${Avatar28}`,
    level: "Disciple 2",
    status: "Unlocked",
    icon: `${icon}`,
  },
  {
    image: `${Avatar25}`,
    level: "The Baron 2",
    status: "Unlocked",
    icon: `${icon}`,
  },
  {
    image: `${Avatar30}`,
    level: "Master 2",
    status: "Unlocked",
    icon: `${icon}`,
  },
  {
    image: `${Avatar31}`,
    level: "The Baron",
    status: "Unlocked",
    icon: `${icon}`,
  },

  {
    image: `${Avatar32}`,
    level: "Beginner",
    status: "Unlocked",
    icon: `${icon}`,
  },

];
