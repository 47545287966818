import React, {useEffect} from "react";
import useGlobalContext from "../custom-hook/Context";

import SettingsMain from "../content/settings/components/SettingsMain";

const Settings = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return (
    <div>
      <SettingsMain />
    </div>
  );
};

export default Settings;
