import React from "react";
import PropTypes from "prop-types";
import arrowDown from "../asset/ic_keyboard_arrow_down_24px.svg";
import { internationalBanksData } from "../content/data/internationalBanksData";

const InternationalBankSelect = ({ name, icon, value, onSelect}) => {
  
  return (
    <>
      <div className="label-wrapper">
        <label htmlFor="select network">Select Network</label>
        <span className="select-icon">
          { icon && <img src={icon} alt="" /> }
        </span>
      </div>
      <div className="form-group">
        <select
          name={name} 
          id={name} 
          value={value}
          onChange={onSelect}
          required
        >
             <option value="" hidden selected>
              Select Network
            </option>
          {internationalBanksData?.map(item => {
            const { bankname, bankcode } = item;
            return (
              <option key={bankcode} value={bankname}>{bankname}</option>
            )
          })}
        </select>
        <img src={arrowDown} alt="" />
      </div>
    </>
  )
};

InternationalBankSelect.propTypes = {
//   name: PropTypes.string.isRequired,
//   label: PropTypes.string.isRequired,
  // value: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
//   list: PropTypes.array.isRequired
}

export default InternationalBankSelect;
