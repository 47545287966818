import React from "react";
import PropTypes from "prop-types";
import arrowDown from "../asset/ic_keyboard_arrow_down_24px.svg";

const DataSelect = ({ name, icon, label, value, onSelect, list }) => {
  return (
    <>
      <div className="label-wrapper">
        <label htmlFor={name}>{label}</label>
        <span className="select-icon">{icon && <img src={icon} alt="" />}</span>
      </div>
      <div className="form-group">
        <select
          name={name}
          id={name}
          value={value}
          onChange={onSelect}
          required
        >
            <option value="" hidden selected>
              Select Plan
            </option>
          {list?.map((item, index) => {
            const { plan_code, name } = item;
            return (
              <option key={index} value={plan_code}>
                {name}
              </option>
            );
          })}
        </select>
        <img src={arrowDown} alt="" />
      </div>
    </>
  );
};

DataSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
};

export default DataSelect;
