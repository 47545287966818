import axios from "../../axios";
import { BANK_LIST } from "../constants/action-types";
import { SAVED_BANK } from "../constants/action-types";
import { ADD_BANK } from "../constants/action-types";
import { VALIDATE_BANK } from "../constants/action-types";
import { SAVED_BENEFICIARIES } from "../constants/action-types";
import { INTERNATIONAL_SAVED_BANK } from "../constants/action-types";

export const getBankList = () => async dispatch => {
  try {
    const response = await axios.get("/rd/v1/payment/bankList");
    dispatch({
      type: BANK_LIST,
      payload: response.data.data,
    });
  } catch (error) {}
};

export const getSavedBankList = () => async dispatch => {
  try {
    const response = await axios.get("/rd/v1/payment/getBank");
    dispatch({
      type: SAVED_BANK,
      payload: response.data.data,
    });
  } catch (error) {}
};

export const addBank = () => async dispatch => {
  try {
    const response = await axios.get("/rd/v1/payment/getBank");
    dispatch({
      type: ADD_BANK,
      payload: response.data.data,
    });
  } catch (error) {}
};

export const validateBank = () => async dispatch => {
  try {
    const response = await axios.get("/rd/v1/payment/validateBank");
    dispatch({
      type: VALIDATE_BANK,
      payload: response.data.data,
    });
  } catch (error) {}
};

export const getSavedBeneficiaries = () => async dispatch => {
  try {
    const response = await axios.get("/rd/v1/payment/getBeneficiaries");
    dispatch({
      type: SAVED_BENEFICIARIES,
      payload: response.data.data,
    });
  } catch (error) {}
};

export const getInternationalSavedBankList = () => async dispatch => {
  try {
    const response = await axios.get("/rd/v1/intl/account/all");
    dispatch({
      type: INTERNATIONAL_SAVED_BANK,
      payload: response.data.data,
    });
  } catch (error) {}
};
