import React, { useEffect } from "react";
import useGlobalContext from "../custom-hook/Context";
import AirtimeBills from '../content/component/AirtimeBills';

const  Subscription = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return <div>
    <AirtimeBills/>
  </div>;
};

export default Subscription;
