import React from "react";
import useGlobalContext from "../../../custom-hook/Context";
import AvatarChoice from "./AvatarChoice";
import UserAvatarOptions from "./UserAvatarOptions";

const RenderAction = ({
  action,
  smallDevice, 
  chooseAction
}) => {
  const {avatars, updateAvatars} = useGlobalContext();

  if((action === "Change picture") || !action ) {
    return <UserAvatarOptions actionHandler={chooseAction} />
  };

  if((action === "Redem avatars") || (action === "Change avatar")) {
    return (
      <AvatarChoice 
        avatars={avatars} 
        number={smallDevice ? 4 : 6}
        updateAvatars={updateAvatars}
      />
    );
  }

};

export default RenderAction;