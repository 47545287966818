import axios from '../../axios';
import { NOTIFICATION_LIST} from '../constants/action-types';


export const getNotificationsList = () => async dispatch => {
    try {
        const response  = await axios.get("/rd/v1/user/firebase/notifications/1");
        dispatch({
            type: NOTIFICATION_LIST,
            payload: response.data.data
        })
        // console.log(response.data);
    } catch (error) {
        // console.log(error)
    }
}
