import React from "react";
import { useHistory } from "react-router-dom";
import "../styles/help-main.css";
import MainHeader from "../../../layouts/header/components/MainHeader";
import helpImage from "../../../asset/Mask Group 42.png";
import helpImage1 from "../../../asset/Mask Group 42-1.png";
import helpImage2 from "../../../asset/Mask Group 42-2.png";
import { helpData } from "../../data/help";
import { useIntercom } from "react-use-intercom";

const HelpMain = () => {
  const username = JSON.parse(localStorage.getItem("username"));

  const history = useHistory();
  function handleClickEmail() {
    history.push("/help-email");
  }

  function handleClickFaqs() {
    history.push("/help-faqs");
  }

  const { show } = useIntercom();

  return (
    <main className="main">
      <MainHeader text={`Hi ${username}!, how can we be of help?`} />
      <div className="help__section">
        <div className="help__section__first__card" onClick={handleClickFaqs}>
          <div className="help__section__title">
            <h1>FAQs</h1>
            <p>Discover the best answers on popular topics</p>
          </div>
          <div className="help__section__image">
            <img src={helpImage} alt="" />
          </div>
        </div>
        <div className="help__section__second__card" onClick={handleClickEmail}>
          <div className="help__section__title">
            <h1>Email</h1>
            <p>Receive personalised solutions in your email box.</p>
          </div>

          <div className="help__section__image">
            <img src={helpImage1} alt="" />
          </div>
        </div>
        <div className="help__section__third__card" onClick={show}>
          <div className="help__section__title">
            <h1>Chat</h1>
            <p>
              Click on this box to start an instant conversation with a support
              representative
            </p>
          </div>

          <div className="help__section__image">
            <img src={helpImage2} alt="" />
          </div>
        </div>
      </div>
      <div className="help__section__sub__section">
        {helpData?.map((data, index) => {
          const { title, image, handle } = data;
          return (
            <div className="help__support" key={index}>
              {/* <div className="help__support__section"> */}
              <div className="help__support__image">
                <img src={image} alt="" />
              </div>
              <div className="help__support__title">
                <p className="help__support__title__title">{title}</p>
                <p className="help__support__title__handle">{handle}</p>
              </div>
            </div>
            // </div>
          );
        })}
      </div>
    </main>
  );
};

export default HelpMain;
