import React from "react";
import icon from "../../../asset/trophy-svgrepo-com.svg";

const LeaderboardTopTradersItem = ({  profile_pics, username, points, rank  }) => {
  return (
    <div className="leaderboard__list__section">
      <div className="leaderboard__list__section__image">
        <img src={profile_pics} alt="" className="leaderboard__list__section__img" />
        <p className="leaderboard__span">{rank}</p>
      </div>
      <div className="leaderboard__list__section__text">
        <p className="leaderboard__list__section__first__text">{username}</p>
        <div className="leaderboard__list__section__section">
          <p className="leaderboard__list__section__second__text">{`${points} pts`}</p>
          <div className="avatar__list__section__section__image">
            <img src={icon} alt="" className="avatar__list__section__section__img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardTopTradersItem;