import React, { useState, useContext, useReducer } from "react";
import useMediaQuery from "./useMediaQuery";
import { cardTypeList, cardReceiptList } from "../content/data/mainContentData";
// import { denominationList } from "../content/data/allRatesData";
import profileFormReducer from "../js/reducers/profileFormReducers";
// import { initialFormState } from "../js/state/formState";
// import profileFormReducer from "../store/reducers/profileFormReducers";
import { initialFormState } from "../js/state/formState";
import { avatarData } from "../content/data/profileData";
import { savedBankList } from "../content/withdraw/data/savedBankList";

const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [showSubmenuDropdown, setShowSubmenuDropdown] = useState(false);
  const [showSidebarDropdown, setShowSidebarDropdown] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [TransactionHistoryMobile, setTransactionHistoryMobile] =
    useState(null);
    const [TransactionHistoryMobileModal, setTransactionHistoryMobileModal] =
    useState(false);

  // const [notificationAmt, setNotificationAmt] = useState(99);
  // eslint-disable-next-line no-unused-vars
  const [notificationAmt, setNotificationAmt] = useState(0);
  const smallDevice = useMediaQuery("(max-width: 600px)");
  const mediumDevice = useMediaQuery("(max-width: 992px)");
  const largeDevice = useMediaQuery("(max-width: 1400px)");
  const [avatars, setAvatars] = useState(avatarData);
  const [action, setAction] = useState("Change picture");
  // const [showCamModal, setShowCamModal] = useState(false);
  const [cardDetails, setCardDetails] = useState({
    bank: "",
    bankcode: "",
    name: "",
    account: "",
    background: "",
    bankName: "",
  });
  const [withdrawalDetails, setWithdrawalDetails] = useState({
    bankholder: "",
    bankcode: "",
    amount: "",
    nuban: "",
    bankname: "",
  });
  const [giftcardName, setGiftcardName] = useState(null);
  const [calculateRate, setCalculateRate] = useState("");
  const [selectedAvatarLabel, setSelectedAvatarLabel] = useState("");
  const [myRedemAvatarDetails, setMyRedemAvatarDetails] = useState({
    image: "",
    avatar: "",
  });
  // const [loading, setLoading] = useState(false);
  // const [isPending, setIsPending] = useState("false");
  // const [status, setStatus] = useState("");
  // const [forgotPasswordDetails, setForgotPasswordDetails] = useState({ myEmail: "", verificationCode: ""});

  const getCalculateRate = e => {
    let gottenRate = e.target.value;
    // console.log(gottenRate)
    setCalculateRate(gottenRate);
  };
  // console.log(calculateRate)

  // const [response, setResponse] = useState("");
  // const [cardAmount, setCardAmount] = useState({amount:""});
  const [formData, setFormData] = useState({
    card: null,
    // country: null,
    type: cardTypeList[0].value,
    receipt: cardReceiptList[0].value,
    hasReceipt: cardReceiptList[0].value,
    // giftcardValue: [],
    // denomination: denominationList[0].value,
    tags: [],
    amount: "",
    rate: "",
    // country:"",
    // category: "",
    // rate: "$150/$",
    file: null,
  });

  const [selectedHottestRatesCard, setSelectedHottestRatesCard] = useState({
    // card: null,
    cardName: "",
    currency: "",
    denomination: "",
  });

  const auth = JSON.parse(localStorage.getItem("token"));
  const checkAuth = () => {
    if (!auth) {
      window.location.href = "/signin";
    }
  };

  // Profile reducer state management
  // const [dispatchProfile] = useReducer(profileFormReducer);
  const [profileState, dispatchProfile] = useReducer(
    profileFormReducer,
    initialFormState
  );

  const toggleSubmenu = () => {
    setShowSubmenu(prev => !prev);
    if (showDropdown) {
      hideDropdown();
    }
  };

  // Sidebar dropdown toggler
  const toggleSidebarDropdown = () => {
    setShowSidebarDropdown(prev => !prev);
    if (showDropdown) {
      hideDropdown();
    }
  };

  // Submenu dropdown toggler
  const toggleSubmenuDropdown = () => {
    setShowSubmenuDropdown(prev => !prev);
    if (showDropdown) {
      hideDropdown();
    }
  };

  const closeSubmenu = () => {
    setShowSubmenu(false);
    if (showDropdown) {
      hideDropdown();
    }
  };

  // Select new country
  const selectCountry = (e, dom, list, setDropdown) => {
    const { countries, setCountries } = list;
    const { domCountries, setDomCountries } = dom;

    const newCountriesList = countries.map(country => {
      if (country.id === +e.target.dataset.id) {
        return { ...country, selected: true };
      }
      return { ...country, selected: false };
    });

    // Get the selected country from dropdown
    const selectedCountry = newCountriesList.find(country => country.selected);

    // Remove duplicate countries with same id
    const uniqueCountries = [...domCountries, selectedCountry]
      .reduce((acc, current) => {
        const dup = acc.find(item => item.id === current.id);
        if (!dup) {
          acc.push(current);
        }
        return acc;
      }, [])
      .map((country, index, countries) => {
        if (index !== countries.length - 1) {
          return { ...country, selected: false };
        }
        return { ...country, selected: true };
      });

    setDomCountries(uniqueCountries);
    setCountries(newCountriesList);
    setDropdown(false);
  };

  const chooseCountry = (id, dom) => {
    const { domCountries, setDomCountries } = dom;

    const newCountriesList = domCountries.map(country => {
      if (country.id === id) {
        return { ...country, selected: true };
      }
      return { ...country, selected: false };
    });

    setDomCountries(newCountriesList);
  };

  // const chooseCountry = (id, dom) => {
  //   const { domCountries, setDomCountries } = dom;

  //   const newCountriesList = domCountries.map(country => {
  //     if (country.id === id) {
  //       return { ...country, selected: true };
  //     }
  //     return { ...country, selected: false };
  //   });

  //   const selectCountry = domCountries.find(country => country.id === id )

  //   localStorage.setItem('selectedCountry', JSON.stringify(selectCountry));

  //   setDomCountries(newCountriesList);

  // };

  // Button dropdown
  const handleDropdown = (e, func) => {
    const target = e.currentTarget;
    const center = target.offsetLeft;
    const bottom = target.offsetTop + 45;
    func({ center, bottom });
  };

  // Profile Modal display
  const handleModalDisplay = () => {
    setShowModal(true);
    if (showDropdown) {
      hideDropdown();
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const updateAvatars = newAvatars => {
    setAvatars(newAvatars);
  };

  // Profile dropdown handlers
  const toggleDropdown = () => {
    setShowDropdown(prev => !prev);
  };

  const hideDropdown = () => {
    setShowDropdown(false);
  };

  // Handle avatar action
  const chooseRenderAction = e => {
    const target = e.currentTarget;
    const labelText = target.lastElementChild.textContent;
    setAction(labelText);
    if (showModal) {
      return;
    } else {
      setShowModal(true);
    }
  };

  const updateAction = label => {
    setAction(label);
    // console.log("princess");
  };

  // Camera update handler
  // const openCam = () => {
  //   setShowCamModal(true);
  // }

  // const closeCam = () => {
  //   setShowCamModal(false)
  // }

  //for saved banks details
  const savedDetails = id => {
    const singleSavedDetails = savedBankList.find(item => item.id === id);
    const { bank, name, account, background, bankName, backcode } =
      singleSavedDetails;
    setCardDetails({
      ...cardDetails,
      bank,
      name,
      account,
      background,
      bankName,
      backcode,
    });
  };

  return (
    <AppContext.Provider
      value={{
        showSubmenu,
        toggleSubmenu,
        smallDevice,
        mediumDevice,
        largeDevice,
        notificationAmt,
        showSidebarDropdown,
        showSubmenuDropdown,
        toggleSidebarDropdown,
        toggleSubmenuDropdown,
        closeSubmenu,
        selectCountry,
        chooseCountry,
        handleDropdown,
        formData,
        setFormData,
        dispatchProfile,
        profileState,
        showModal,
        closeModal,
        handleModalDisplay,
        avatars,
        updateAvatars,
        toggleDropdown,
        hideDropdown,
        showDropdown,
        action,
        chooseRenderAction,
        updateAction,
        // showCamModal,
        // closeCam,
        // openCam,
        savedDetails,
        cardDetails,
        setCardDetails,
        // instantiateGiftcard,
        giftcardName,
        setGiftcardName,
        getCalculateRate,
        calculateRate,
        //  forgotPasswordDetails,
        //   setForgotPasswordDetails
        //  isPending,
        //   setIsPending
        //  status, setStatus
        // cardAmount,
        // setCardAmount,
        withdrawalDetails,
        setWithdrawalDetails,
        selectedHottestRatesCard,
        setSelectedHottestRatesCard,
        selectedAvatarLabel,
        setSelectedAvatarLabel,
        myRedemAvatarDetails,
        setMyRedemAvatarDetails,
        checkAuth,
        TransactionHistoryMobile,
        setTransactionHistoryMobile,
        TransactionHistoryMobileModal, 
        setTransactionHistoryMobileModal
        // loading, setLoading
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => useContext(AppContext);

export default useGlobalContext;
