import React, { useState, useEffect } from "react";
import useGlobalContext from "../../../custom-hook/Context";
import { useHistory } from "react-router-dom";
import MainHeader from "../../../layouts/header/components/MainHeader";
import PinShield from "../../component/PinShield";
import "./../style/change-pin.css";
// import FormInput from "./FormInput";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";
import axios from "../../../axios";
import Swal from "sweetalert2";

const ChangePin = ({ getProfile, profile }) => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  function showPin() {
    document
      .querySelector(".toggle-forgot-pin")
      .classList.toggle("fa-eye-slash");
    document.querySelector(".toggle-forgot-pin").classList.toggle("fa-eye");
    let x = document.getElementById("change-pin-toggler");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  function showConfirmPin() {
    document
      .querySelector(".toggle-confirm-pin")
      .classList.toggle("fa-eye-slash");
    document.querySelector(".toggle-confirm-pin").classList.toggle("fa-eye");
    let x = document.getElementById("confirm-pin-toggler");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  function showCurrentPin() {
    document
      .querySelector(".toggle-current-pin")
      .classList.toggle("fa-eye-slash");
    document.querySelector(".toggle-current-pin").classList.toggle("fa-eye");
    let x = document.getElementById("currentpin");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  // const [oldPin, setoldPin] = useState("");

  // const getOldPin = e => {
  //   let oldPin = e.target.value;
  //   setoldPin(oldPin);
  // };
  const [input, setInput] = useState({
    pin: "",
    newPin: "",
  });

  const [error, setError] = useState({
    pin: "",
    newPin: "",
  });

  const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = e => {
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "pin":
          if (!value) {
            stateObj[name] = "Please enter Pin.";
          } else if (input.newPin && value !== input.newPin) {
            stateObj["newPin"] = "Pin and Confirm Pin does not match.";
          } else {
            stateObj["newPin"] = input.newPin ? "" : error.newPin;
          }
          break;

        case "newPin":
          if (!value) {
            stateObj[name] = "Please enter Confirm Pin.";
          } else if (input.pin && value !== input.pin) {
            stateObj[name] = "Pin and Confirm Pin does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  useEffect(() => {
    getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();
  function cancel() {
    history.push("/profile/change-credentials");
  }
  let code = JSON.parse(localStorage.getItem("verifyPinCode"));
  let token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      auth: `Bearer ${token}`,
    },
  };

  const handleInput = () => {
    let formBody = {
      token: config.headers.auth,
      verificationCode: code,
      auth: input.pin,
    };
    // console.log(formBody);
    axios
      .post("/rd/v1/auth/change-pin/verify", formBody)
      .then(response => {
        // console.log(response.data.message);
        Swal.fire({
          icon: "success",
          text: `${response.data.message}`,
        }).then(function () {
          localStorage.clear();
          window.location.href = "/dashboard";
        });
      })
      .catch(e => {
        // console.log(e);
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };

  return (
    <main className="main">
      <MainHeader text="Change Pin" />
      <div className="feedback__home">
        {/* <div className="feedback__component"></div> */}
        <section className="change__pin">
          <div className="change__pin__form">
            <div className="change__pin__form__icon">
              <PinShield />
            </div>
            <p className="change__pin__form__text">
              You will change the pin for:&nbsp;{" "}
              <span>{`${profile.profile.firstname}  ${profile.profile.lastname}`}</span>
            </p>
            <div className="form-group change__pass__form__input">
              <div className="label-wrapper">
                <label
                  htmlFor="currentpassword"
                  className="change__pass__form__label"
                >
                  Current new pin
                </label>
              </div>
              <input
                name="oldPassword"
                id="currentpin"
                type="password"
                className="change__pass__form__input__input"
                // onKeyUp={e => getOldPin(e)}
                required
              />
              <span
                toggle="#current-pin-toggler"
                className="fa fa-eye-slash  change__password__toggle toggle-current-pin"
                onClick={showCurrentPin}
              ></span>
            </div>
            <div className="form-group  change__pass__form__input">
              <div className="label-wrapper">
                <label
                  htmlFor="newpassword"
                  className="change__pass__form__label"
                >
                  New pin
                </label>
              </div>
              <input
                name="pin"
                id="change-pin-toggler"
                type="password"
                className="change__pass__form__input__input"
                value={input.pin}
                onChange={onInputChange}
                onBlur={validateInput}
                required
              />
              <span
                toggle="#change-pin-toggler"
                className="fa fa-eye-slash change__password__toggle toggle-forgot-pin"
                onClick={showPin}
              ></span>
              <div>
                {error.pin && <span className="err">{error.newPin}</span>}
              </div>
            </div>
            <div className="form-group change__pass__form__input">
              <div className="label-wrapper">
                <label
                  htmlFor="retypepassword"
                  className="change__pass__form__label"
                >
                  Confirm new pin
                </label>
              </div>
              <input
                name="newPin"
                id="confirm-pin-toggler"
                type="password"
                className="change__pass__form__input__input"
                value={input.newPin}
                onChange={onInputChange}
                onBlur={validateInput}
                required
              />
              <span
                toggle="#confirm-pin-toggler"
                className="fa fa-eye-slash second__change__pasword__toggle change__password__toggle toggle-confirm-pin"
                onClick={showConfirmPin}
              ></span>
              <div>
                {error.newPin && <span className="err">{error.newPin}</span>}
              </div>
            </div>

            {input.newPin === input.pin && (
              <div className="change__pass__buttons">
                <button
                  type="button"
                  className="change__pass__cancel__button"
                  onClick={cancel}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="change__pass__button"
                  onClick={() => handleInput()}
                >
                  Apply
                </button>
              </div>
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

ChangePin.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(ChangePin);
