import React, { useEffect } from "react";
import TextInput from "../../common/TextInput";
import useGlobalContext from "../../../custom-hook/Context";
import { edit } from "../../../js/actions/action";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";

import "../style/profile-form.css";

const ProfileForm = ({ getProfile, profile }) => {
  useEffect(() => {
    getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { dispatchProfile } = useGlobalContext();
const email = JSON.parse(localStorage.getItem("email"));
  const handleChange = e => {
    dispatchProfile({
      type: edit,
      field: e.target.name,
      payload: e.target.value,
    });
  };
  // console.log("princess is here",profile.profile.wallet);
  // console.log("here here")
  return (
    <form className="profile-form">
      <TextInput
        label="First name"
        name="first_name"
        type="text"
        value={profile.profile.firstname ?? ""}
        onChange={handleChange}
        error=""
      />
      <TextInput
        label="Last name"
        name="last_name"
        type="text"
        value={profile.profile.lastname ?? ""}
        onChange={handleChange}
        error=""
      />
      <TextInput
        label="Email address"
        name="email"
        type="email"
        value={email}
        onChange={handleChange}
        error=""
      />
      <article>
        <TextInput
          label="Phone number"
          name="phone"
          type="tel"
          value={profile.profile.phone_number ?? ""}
          onChange={handleChange}
          error=""
        />
        <TextInput
          label="Country"
          name="country"
          type="text"
          value={profile.profile.country ?? ""}
          onChange={handleChange}
          error=""
        />
      </article>

      {/* <div className="btn-container">
        <button type="submit">Save profile</button>
      </div> */}
    </form>
  );
};

ProfileForm.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(ProfileForm);
