import { GET_HOTTEST_RATES } from "../constants/action-types";

const initialState = {
    hottestrateslist: []
}

export default function profileReducer(state = initialState, action) {
    const {type, payload} = action;

    switch(type) {
        case GET_HOTTEST_RATES:
            return {
                ...state,
                hottestrateslist: payload
            }
        default:
            return state;
    }
}