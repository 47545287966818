import React from "react";
import "../styles/giftcardhistory.css";
import GiftcardHistoryLottie from "./GiftcardHistoryLottie";
import { Link } from "react-router-dom";

const HistoryHeader = () => {
  return (
    <div className="giftcard-history-header">
      <div>
        <h1 className="giftcard-history-header-rank">Disciple</h1>
      </div>
      <div className="giftcard-history-lottie">
        <GiftcardHistoryLottie />
      </div>

      <div className="card-btn giftcard-history-button">
        <button className="btn">
          <Link to="/giftcard">
            <span className="quick_span">Quick trade to upgrade</span>
            <span className="quick">Trade to upgrade</span>
          </Link>
        </button>
      </div>
    </div>
  );
};

export default HistoryHeader;
