import React from "react";
// import { Link } from "react-router-dom";

const TransactionDetailsModal = ({ 
  details, 
  showModal,
  setShowModal, 
  messageBefore, 
  messageAfter, 
  closeImg 
}) => {

  return (
    <div 
      className={`details-modal_container ${showModal ? "show-modal" : ""}`}
    >
      <div className="details-modal">
        <div className="details-modal_header">
          <button type="button" onClick={ () => setShowModal(false) }>
            {
              closeImg ? (<img src={closeImg} alt="Close icon" />) : "X"
            }
          </button>
        </div>
        <article className="details-modal_content">
          <div className="icon-wrapper">
            
          { details?.badImages.map((item,reference) => <img src={item} alt='' key={reference}/>)}
          </div>
          {/* <div className="more-info">
            <p>{details?.detail?.moreInfo?.code}</p>
            <p>
              <span
                style={{
                  color: `${details?.success ? "#25bb32" : "#EC1A3F"}`
                }}
              >{messageBefore}</span>
              <Link 
                to="/"
                style={{
                  color: "#1b1c1d",
                  textDecoration: "none"
                }}
              >{messageAfter}</Link>
            </p>
            <p>{details?.detail?.moreInfo?.text}</p>
          </div> */}
        </article>
      </div>
    </div>
  )
}

export default TransactionDetailsModal;