import React from "react";
import { useLottie } from "lottie-react";
import animationData from "./Bank Icon.json";

export default function BankIcon() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { View } = useLottie(defaultOptions);
  return (
    <div style={{ width: "200px", height: "200px" }} className="addbanklottie">
      {View}
    </div>
  );
}
