import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/savedbanksmain.css";
// import { savedBankList } from "../data/addBankDetails";
// import SavedBankMain from "./SavedBankMain";
import add from "../../asset/Group 163394.svg";
import { getSavedBankList } from "../../js/actions/bankdetails";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SavedBankMain from "./SavedBankMain";
import { getInternationalSavedBankList } from "../../js/actions/bankdetails";

const SavedBanks = ({ getSavedBankList, allSavedBankList, getInternationalSavedBankList, allInternationalSavedBankList }) => {
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  useEffect(() => {
    getSavedBankList();
    getInternationalSavedBankList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
     {userCountry === 'nigeria' ?  <div className="savedbank">
        {allSavedBankList &&
          allSavedBankList.map((data, index) => {
            // eslint-disable-next-line no-unused-vars
            const { bankcode, bankholder, bankname, nuban } = data;
            // console.log(bankholder);
            return (
              <SavedBankMain
                key={index}
                bankcode={bankcode}
                bankholder={bankholder}
                bankname={bankname}
                nuban={nuban}
              />
            );
          })}

        <div className="savedbank__add">
          <Link to="/profile/bank-details/add-bank">
            <img src={add} alt="" />
          </Link>
          <p>Add another bank</p>
        </div>
      </div> :  <div className="savedbank">
        {allInternationalSavedBankList &&
          allInternationalSavedBankList.map((data, index) => {
            // eslint-disable-next-line no-unused-vars
            const { currency_code, customer_name, network, account_number } = data;
            // console.log(bankholder);
            return (
              <SavedBankMain
                key={index}
                bankcode={currency_code}
                bankholder={customer_name}
                bankname={network}
                nuban={account_number}
              />
            );
          })}

        <div className="savedbank__add">
          <Link to="/profile/bank-details/add-bank">
            <img src={add} alt="" />
          </Link>
          <p>Add another bank</p>
        </div>
      </div>}
    </div>
  );
};

SavedBanks.propTypes = {
  getSavedBankList: PropTypes.func,
  allSavedBankList: PropTypes.array,
  getInternationalSavedBankList: PropTypes.func,
  allInternationalSavedBankList: PropTypes.array,
};
const mapStateToProps = state => ({
  allSavedBankList: state.bankdetails.allSavedBankList,
  allInternationalSavedBankList: state.bankdetails.allInternationalSavedBankList,
});

export default connect(mapStateToProps, { getSavedBankList, getInternationalSavedBankList })(SavedBanks);
