import React, { useState, useEffect } from "react";
import useGlobalContext from "../../../custom-hook/Context";
import { useHistory } from "react-router-dom";

//Styles
import "../styles/main-header.css";

// Images
import notification1 from "../../../asset/Notification Bell.svg";
import notification2 from "../../../asset/notification-bell2.svg";
import ProfileDropdown from "./ProfileDropdown";
import user from "../../../asset/user-image x2.png";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";

const MainHeader = ({
  title,
  name,
  titleImg,
  titleBtn,
  text,
  lottie,
  backTrackBtn,
  buttonStyle,
  getProfile,
  profile,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const {
    // showSubmenu,
    // toggleSubmenu,
    notificationAmt,
    smallDevice,
    mediumDevice,
  } = useGlobalContext();
  const [step, setStep] = useState(1);
  const history = useHistory();

  useEffect(() => {
    if (step <= 0) {
      history.goBack();
    }
  });
  useEffect(() => {
    getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const headerBtn = (
  //   <button className="title-btn">
  //     {titleBtn}
  //   </button>
  // );

  const notification = () => {
    history.push("/notifications");
  };

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const hidDropdown = () => {
    setShowDropdown(false);
  };

  const prevStep = () => {
    setStep((prev) => prev - 1);
  };
  const currentLocation =
    window.location.href.includes("/airtime-details") ||
    window.location.href.includes("/data-details") ||
    window.location.href.includes("/toll-details") ||
    window.location.href.includes("/electric-details") ||
    window.location.href.includes("/tv-details") ||
    window.location.href.includes("/help-email") ||
    window.location.href.includes("/help-faqs");

  // const hamburger = (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     width="22"
  //     height="15"
  //     viewBox="0 0 22 15"
  //   >
  //     <path
  //       className="a"
  //       stroke={showSubmenu ? "#1b1c1d" : "#1b1c1d"}
  //       transform={`${showSubmenu ? "rotate(45 -.8 2.9)" : "translate(1 1)"}`}
  //       d={`M0 ${showSubmenu ? "0L6.6" : "0L7"} 0`}
  //     />
  //     <path
  //       className="a"
  //       stroke={showSubmenu ? "#1b1c1d" : "#1b1c1d"}
  //       transform={`${showSubmenu ? "rotate(-45 19.4 6)" : "translate(1 8)"}`}
  //       d={`M0 ${showSubmenu ? "0L19.9" : "0L20"} 0`}
  //     />
  //     <path
  //       className="a"
  //       stroke={showSubmenu ? "#1b1c1d" : "#1b1c1d"}
  //       transform={`${showSubmenu ? "rotate(45 -4.1 11)" : "translate(1 14)"}`}
  //       d="M0 0L13 0"
  //     />
  //   </svg>
  // );

  return (
    <header className="home-header">
      <div className="second-header-text">
        <div
          className="headache"
          style={{
            display: currentLocation ? "block" : "none",
          }}
        >
          <button onClick={prevStep} style={buttonStyle}>
            {backTrackBtn}
          </button>
        </div>

        <div className="">
          <h1>{text}</h1>
        </div>

        <div className="lottie-div">{lottie}</div>
      </div>
      <div
        style={{
          marginBottom: title && mediumDevice ? "2rem" : "",
          position: "relative",
        }}
      >
        <div className="header-img-wrapper">
          <div className="notification">
            <img
              src={smallDevice ? notification2 : notification1}
              alt="notification icon"
              onClick={notification}
            />
            <p>{notificationAmt}</p>
          </div>
          <div className="profile">
            <div onClick={toggleDropdown}>
              <img
                src={profile.profile.profile_pics}
                className="avatar"
                alt="User avatar"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="7.41"
                viewBox="0 0 12 7.41"
              >
                <path
                  fill="rgb(27, 28, 29)"
                  d="M1.41 0 6 4.58 10.59 0 12 1.41l-6 6-6-6Z"
                />
              </svg>
            </div>
          </div>
        </div>
        {/* <button onClick={() => toggleSubmenu()} className="main-header-btn">
          {hamburger}
        </button> */}
        <ProfileDropdown
          isVisible={showDropdown}
          hidDropdown={hidDropdown}
          profileImg={user}
          name={name}
        />
      </div>
    </header>
  );
};

MainHeader.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(MainHeader);
