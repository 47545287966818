import React from "react";
import PropTypes from "prop-types";
import arrowDown from "../asset/ic_keyboard_arrow_down_24px.svg";

const Select = ({ name, icon, label, value, onSelect, list }) => {

  return (
    <>
      <div className="label-wrapper">
        <label htmlFor={name}>{label}</label>
        <span className="select-icon">
          { icon && <img src={icon} alt="" /> }
        </span>
      </div>
      <div className="form-group">
        <select
          name={name} 
          id={name} 
          value={value}
          onChange={onSelect}
          required
        >
          {list.map(item => {
            const { text, id, value } = item;
            return (
              <option key={id} value={value}>{text}</option>
            )
          })}
        </select>
        <img src={arrowDown} alt="" />
      </div>
    </>
  )
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired
}

export default Select;
