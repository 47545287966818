
// import amazon from "../../asset/amazon@2x.png";
// import footlocker from "../../asset/footlocker@2x.png";
// import ebay from "../../asset/ebay@2x.png";
// import amex from "../../asset/amex@2x.png";
// import bestbuy from "../../asset/best buy@2x.png";
// import jcpenny from "../../asset/JcPenny.png";
// import macy from "../../asset/macy.png";
// import nike from "../../asset/nike.png";
// import nordstorm from "../../asset/nordstorm.png";


export const countryList = [
  {
    id: 1,
    name: "USD",
    country: "USA",
    currency: "USD",
    selected: false,
  },
  {
    id: 2,
    name: "GBP",
    country: "UK",
    currency: "GBP",
    selected: false,
  },
  {
    id: 3,
    name: "EUR",
    country: "Euro",
    currency: "EUR",
    selected: false,
  },
  {
    id: 4,
    name: "ESP",
    country: "Spain",
    currency: "ESP",
    selected: false,
  },
  {
    id: 5,
    name: "AUD",
    country: "Australia",
    currency: "AUD",
    selected: false
  },
  {
    id: 6,
    name: "CAD",
    country: "Canada",
    currency: "CAD",
    selected: false
  },
];

// export const countriesValueList = [
//   {
//     id: 7,
//     name: "USD",
//     selected: false,
//   },
//   {
//     id: 8,
//     name: "GBP",
//     selected: false,
//   },
//   {
//     id: 9,
//     name: "EUR",
//     selected: false,
//   },
//   {
//     id: 10,
//     name: "ESP",
//     selected: false,
//   },
//   {
//     id: 11,
//     name: "AUD",
//     selected: false
//   },
//   {
//     id: 12,
//     name: "CAD",
//     selected: false
//   }
// ]

// export const denominationList = [
//   {
//     id: 1,
//     value: "USA Amazon credit receipt (101-200)",
//     selected: true
//   },
//   {
//     id: 2,
//     value: "EUR Itunes credit receipt (101-200)",
//     selected: false
//   }
// ];

export const cardData = [
  {
    id: 1,
    name: "nordstrom ",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1638366764/nordstrom_otbwtw.png",
  },
  {
    id: 2,
    name: "amazon",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1638366765/amazon_2x_jz6zmu.png",
  },
  {
    id: 3,
    name: "nike",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1638366763/nike_gdoumv.png",
  },
  {
    id: 4,
    name: "ebay",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1638366770/ebay_2x_dghpzs.png",
  },
  // {
  //   id: 5,
  //   name: "bestbuy",
  //   image:"https://res.cloudinary.com/dpqxraalv/image/upload/v1638366765/best_buy_2x_djqqaj.png",
  // },
  {
    id: 6,
    name: "macys",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1638366764/macy_nb4dfc.png",
  },
  {
    id: 7,
    name: "footlocker",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1638366766/footlocker_2x_k1zeve.png",
  },
  {
    id: 8,
    name: "Amex Gold",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1638366768/amex_2x_chbzlu.png",
  },
  {
    id: 9,
    name: "JcPenny ",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1638366763/JcPenny_bnqomk.png",
  },
  {
    id: 10,
    name: "google play",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1641825650/google_play_card_ij8qks.png",
  },
  {
    id: 11,
    name: "itunes",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1641820449/itunes_card_zejr7q.png",
  },
  {
    id: 12,
    name: "sephora",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1647860827/sephora_card_ynrzf9.png",
  },
  {
    id: 13,
    name: "steam",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1647860827/steam_giftcard_o84uul.png",
  },
  {
    id: 14,
    name: "target",
    image:"https://res.cloudinary.com/dpqxraalv/image/upload/v1647860826/target_card_oyptie.png",
  },
  {
    id: 15,
    name: "Apple iTunes",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1643287099/usa_apple_store_rcmigm.png",
  },
  {
    id: 16,
    name: "Vanilla Visa",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1647860827/vanilla_card_uvpgsj.png",
  },
  {
    id: 17,
    name: "visa",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1647860826/visa_card_bdq3ka.png",
  },
  {
    id: 18,
    name: "walmart visa",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1647860827/walmart_card_f3heht.png",
  },
  {
    id: 19,
    name: "razergold",
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1653310712/razer_gold_card_d9vgta.png",
  },
]