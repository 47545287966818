import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/transactionhistory.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getWithdrawalHistory } from "../../js/actions/withdrawal";
import Moment from "react-moment";
import success from "../../asset/success.svg";
import failed from "../../asset/danger.svg";
// import bank from "../../asset/058.svg";

const TransactionHistory = ({ getWithdrawalHistory, withdrawalList }) => {
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  useEffect(() => {
    getWithdrawalHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(withdrawalList.data);
  return (
    <>
      <section className="transaction-body transaction__desktop">
        <header className="transaction-header">
          <h3>
            Wallet transaction<span className="history"> history</span>
          </h3>
          <button className="btn">
            All
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="7.41"
              viewBox="0 0 12 7.41"
            >
              <path
                fill="#1b1c1d"
                d="M1.41 0 6 4.58 10.59 0 12 1.41l-6 6-6-6Z"
              />
            </svg>
          </button>
        </header>

        {withdrawalList.data && withdrawalList.data.length === 0 && (
          <>
            <p style={{ padding: "15px 25px" }}>No Transaction History</p>
          </>
        )}

        <>
          <section className="">
            {withdrawalList.data &&
              withdrawalList.data.map((data, index) => {
                const {
                  // bankLogo,
                  bankcode,
                  bankname,
                  created_at,
                  currency_code,
                  // statusIcon,
                  amount,
                  reference,
                  status,
                } = data;
                // console.log(bankcode)
                return (
                  <Link
                    to={{
                      pathname: `/${reference}/wallet-history`,
                      state: {
                        bankcode,
                        currency_code,
                        // statusIcon: data.statusIcon,
                        amount,
                        bankname,
                        created_at,
                        status,
                      },
                    }}
                    className="airtime__transaction__body__link"
                    key={reference}
                  >
                    <div className="custom-card-body">
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <div className="d-flex justify-content-center align-items-center">
             
                            <div className="banklogo">
                              {/* <img src="../../asset/{{bankcode}}.svg" className="" alt="" /> */}
                              <img
                                src={require(`../../asset/${bankcode}.svg`)}
                                className=""
                                alt=""
                              />
                            </div>
                          <div className="d-flex flex-column justify-content-start align-items-start transaction_left_side">
                            <p className="transaction-title">Withdrawal</p>
                            <p className="transaction-bank">{bankname}</p>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex flex-column justify-content-start align-items-start transaction_right_side ">
                            <p className="transaction-date">
                              <Moment format="DD/MM/YYYY">{created_at}</Moment>
                            </p>
                            <p className="transaction-time">
                              <Moment format="hh:mm a">{created_at}</Moment>
                            </p>
                          </div>
                          <div className="d-flex justify-content-center align-items-center mobile_check">
                            <img
                              src={status === "successful" ? success : failed}
                              className="img-fluid check "
                              alt=""
                            />

                            {userCountry === "nigeria" && (
                              <p className="transaction-amount">{`₦ ${amount.toLocaleString()}.00`}</p>
                            )}
                            {userCountry === "kenya" && (
                              <p className="transaction-amount">{`K ${amount.toLocaleString()}.00`}</p>
                            )}
                            {userCountry === "ghana" && (
                              <p className="transaction-amount">{`₵ ${amount.toLocaleString()}.00`}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </section>

          {/* <div className="custom-card-footer transaction-footer">
            <Link to="/">See all</Link>
          </div> */}
        </>
      </section>

      <section className=" transaction-body__mobile transaction__mobile">
        <header className="transaction-header">
          <h3>
            Wallet transaction<span className="history"> history</span>
          </h3>
          <button className="btn">
            All
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="7.41"
              viewBox="0 0 12 7.41"
            >
              <path
                fill="#1b1c1d"
                d="M1.41 0 6 4.58 10.59 0 12 1.41l-6 6-6-6Z"
              />
            </svg>
          </button>
        </header>

        {withdrawalList.data && withdrawalList.data.length === 0 && (
          <>
            <p style={{ padding: "15px 0px" }}>No Transaction History</p>
          </>
        )}

        <>
          <section className="">
            {withdrawalList.data &&
              withdrawalList.data.map((data, index) => {
                const {
                  // bankLogo,
                  bankcode,
                  bankname,
                  created_at,
                  currency_code,
                  // statusIcon,
                  amount,
                  reference,
                  status,
                } = data;

                return (
                  <Link
                    to={{
                      pathname: `/${reference}/wallet-history`,
                      state: {
                        bankcode,
                        // statusIcon: data.statusIcon,
                        amount,
                        bankname,
                        created_at,
                        status,
                      },
                    }}
                    className="airtime__transaction__body__link"
                    key={reference}
                  >
                    <div className=" transaction__mobile__main">
                      {userCountry === "nigeria" ? (
                        <div className="transaction__mobile__main__image">
                          <img
                            src={require(`../../asset/${bankcode}.svg`)}
                            className=""
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="transaction__mobile__main__image">
                          <img
                            src={require(`../../asset/${currency_code}.svg`)}
                            className=""
                            alt=""
                          />
                        </div>
                      )}

                      {/* <div className="princess"> */}
                      <div className="transaction__mobile__main__transaction__title">
                        <p className="transaction-title">Withdrawal</p>
                        <p className="transaction-bank">{bankname}</p>
                      </div>

                      <div className="transaction__mobile__main__transaction__icon">
                        <img
                          src={status === "successful" ? success : failed}
                          className="img-fluid check "
                          alt=""
                        />
                        <p className="transaction-amount"> ₦{amount}.00</p>
                      </div>
                      {/* </div> */}
                    </div>
                  </Link>
                );
              })}
          </section>
          {/* <div className="custom-card-footer transaction-footer">
            <Link to="/">See all</Link>
          </div> */}
        </>
      </section>
    </>
  );
};

TransactionHistory.propTypes = {
  getWithdrawalHistory: PropTypes.func,
  withdrawalList: PropTypes.object,
};
const mapStateToProps = state => ({
  withdrawalList: state.withdrawal.withdrawalList,
});

export default connect(mapStateToProps, { getWithdrawalHistory })(
  TransactionHistory
);
