import React, { useState } from "react";
import MainHeader from "../../../layouts/header/components/MainHeader";
import Toggle from "../../../ui/toggle/Toggle";
import deleteIcon from "../../../asset/Group 164647.png";
import "../styles/settings-main.css";
import axios from "../../../axios";
import Swal from "sweetalert2";

const SettingsMain = () => {
  const [value, setValue] = useState(false);
  const [password, setPassword] = useState("");
  const [textArea, setTextArea] = useState("");

  const handleInput = () => {
    let formBody = {
      reason: textArea,
      password: password,
      delete: true,
    };
    axios.post("/rd/v1/auth/account/delete", formBody).then(response => {
      Swal.fire({
        icon:'success',
        title: 'Account deleted successfully',
        text: `${response.data.message}`
      }).then(function() {
        window.location.href = '/signup';
    })
    }).catch(e => {
      Swal.fire({
        icon:'error',
        title: 'Forbidden',
        text: `${e.response.data.message}`
      })
    })
  };

  return (
    <main className="main">
      <MainHeader text="Settings" />
      <section className="settings__section">
        <div className="settings__section__content">
          <h3 className="settings__section__heading">Account Settings</h3>

          <div className="settings__section__form-group">
            <div className="label-wrapper">
              <label
                htmlFor="settingslabel"
                className="settings__section__settings-label"
              >
                Password
              </label>
            </div>

            <input
              type="password"
              name="password"
              className="settings__section__input"
              onKeyUp={e => setPassword(e.target.value)}
              required
            />
          </div>

          <div className="settings__section__form-group">
            <div className="label-wrapper">
              <label
                htmlFor="settingslabel"
                className="settings__section__settings-label"
              >
                Reason For Deleting Account
              </label>
            </div>

            <textarea
              className="delete__account__textarea"
              required
              onChange={e => setTextArea(e.target.value)}
            />
          </div>

          <div className="toggle__section delete__account__toggle">
            <Toggle
              isOn={value}
              onColor="#6C63FF"
              handleToggle={() => setValue(!value)}
            />
            <p className="toggle__section__text">
              Keep me up to date with Redem marketing emails
            </p>
          </div>

          <div className="settings__delete__section">
            <h4 className="settings__delete__section__heading">
              Delete Account
            </h4>
            <div className="delete__section">
              <div className="delete__section__image">
                <img src={deleteIcon} alt="" />
              </div>
              <div className="delete__section__text">
                {/* <p className="delete__section__first__text">Delete Account</p> */}
                <p className="delete__section__second__text">
                  This action is permanent, you will not be able to recover your
                  account when you delete it.
                </p>
              </div>
            </div>
          </div>

          <div className="settings__delete__button__section ">
            <button
              type="button"
              className="settings__delete__section__button"
              onClick={() => handleInput()}
              disabled={!textArea}
            >
              Delete Account
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SettingsMain;
