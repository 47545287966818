import React, { useState} from "react";
// import CoverImages from "../../redeem-avatars/components/CoverImages";
import "../../redeem-avatars/components/Tabs/RedemAvatarsTab.css";
import LeaderboardGiftcardImages from "./LeaderboardGiftcardImages";
import LeaderBoardTopTradersMobile from "./LeaderBoardTopTradersMobile";
import MyLeaderBoard from "./MyLeaderBoard";
// import { PropTypes } from "prop-types";
// import { connect } from "react-redux";
// import { getMyLeaderboardData } from "../../../js/actions/leaderboard";

function LeaderboardGiftcardTab() {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = index => {
    setToggleState(index);
  };
 
  return (
    <div className="avatar__main">
      <div className=" ">
        <div className="">
          <div className="avatar-bloc-tabs">
            <button
              className={
                toggleState === 1
                  ? "avatar-tabs active-avatar-tabs"
                  : "avatar-tabs"
              }
              onClick={() => toggleTab(1)}
            >
              Giftcard
            </button>
            <button
              className={
                toggleState === 2
                  ? "avatar-tabs active-avatar-tabs"
                  : "avatar-tabs"
              }
              onClick={() => toggleTab(2)}
            >
              Referral
            </button>
          </div>
        </div>
      </div>

      <div className="content-avatar-tabs">
        <div
          className={
            toggleState === 1
              ? "avatar-content  active-avatar-content"
              : "avatar-content"
          }
        >
          <section className="container content__container giftcard__traders">
            <LeaderBoardTopTradersMobile />
            {/* <section className="">
              {myLeaderboardData &&
                Array.from(myLeaderboardData).map((data, index) => {
                  const { profile_pics, username, points, rank } = data;
                  return (
                    <MyLeaderBoard
                      key={index}
                      profile_pics={profile_pics}
                      username={username}
                      points={points}
                      rank={rank}
                    />
                  );
                })}
            </section> */}
           <MyLeaderBoard/>
            <LeaderboardGiftcardImages />
          </section>
        </div>

        <div
          className={
            toggleState === 2
              ? "avatar-content  active-avatar-content"
              : "avatar-content"
          }
        >
          <section className="container content__container">
            {/* <CoverImages /> */}
            <h3>Coming Soon!!!</h3>
          </section>
        </div>
      </div>
    </div>
  );
}

export default LeaderboardGiftcardTab;
// LeaderboardGiftcardTab.propTypes = {
//   getMyLeaderboardData: PropTypes.func.isRequired,
//   myLeaderboardData: PropTypes.object,
// };

// const mapStateToProps = state => ({
//   myLeaderboardData: state.leaderboard.myLeaderboardData,
// });

// export default connect(mapStateToProps, { getMyLeaderboardData })(
//   LeaderboardGiftcardTab
// );
