import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import apple from "../../../asset/apple.svg";
import Moment from "react-moment";
import success from "../../../asset/success.svg";
import failed from "../../../asset/danger.svg";
import tooltip from "../../../asset/ic_info_outline_24px.svg";
import useGlobalContext from "../../../custom-hook/Context";
import { useHistory } from "react-router-dom";
// import MainHeader from "../../../layouts/header/components/MainHeader";

const SingleTransactionHistoryMobile = ({
  //   details,
  setShowModal,
  //   created_at,
  //   cardType,
  //   reference,
  //   category,
  //   naira,
  //   dollar,
  //   rate,
  //   country,
  //   status,
  //   badImages,
  //   comment,
}) => {
  const { TransactionHistoryMobile, smallDevice, checkAuth } =
    useGlobalContext();
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  const currencyRate = Number(JSON.parse(localStorage.getItem("currencyRate")));
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  // const Location = useLocation();
  const popupHandler = () => {
    setShowModal(true);
  };

  const backTrackBtn = smallDevice ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.18"
      height="8.39"
      viewBox="0 0 5.18 8.39"
    >
      <path
        fill="#1b1c1d"
        d="m5.18 7.4-3.2-3.2L5.18.99 4.2 0 0 4.2l4.2 4.19Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.16"
      height="11.6"
      viewBox="0 0 7.16 11.6"
    >
      <path
        fill="#1b1c1d"
        d="M7.16 10.24 2.74 5.8l4.42-4.44L5.8 0 0 5.8l5.8 5.8Z"
      />
    </svg>
  );
  const [step, setStep] = useState(1);
  const history = useHistory();
  useEffect(() => {
    if (step <= 0) {
      history.goBack();
    }
  });
  const prevStep = () => {
    setStep(prev => prev - 1);
  };
  return (
    <div style={{ padding: "30px 20px" }}>
      {smallDevice ? (
        <div>
          <button
            onClick={prevStep}
            style={{
              alignSelf: "flex-start",
              width: "52px",
              height: "52px",
              backgroundColor: "#FDFDFD",
              border: "1px solid #1b1b1b",
              borderRadius: "50%",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {backTrackBtn}
          </button>
        </div>
      ) : (
        ""
      )}

      {smallDevice && TransactionHistoryMobile && (
        <section style={{ marginTop: "35px", padding: "30px 20px" }}>
          <section className="transaction-period">
            {/* <div className="icon-wrapper">
              <img src={details?.icon} alt={details?.title} />
            </div> */}
            <div className="icon-wrapper">
              <img
                src={require(`../../../asset/giftcardshistory/${TransactionHistoryMobile?.category.toLowerCase()}.png`)}
                className=""
                alt={TransactionHistoryMobile?.category}
              />
            </div>
            <div className="details-title">
              <p>{TransactionHistoryMobile?.category}</p>
              {/* <p>{details?.category}</p> */}
              <p>
                ({TransactionHistoryMobile?.category}{" "}
                {TransactionHistoryMobile?.cardType})
              </p>
            </div>
            <div className="details-time">
              <p>
                <Moment format="MMM Do">
                  {TransactionHistoryMobile?.created_at}
                </Moment>{" "}
              </p>
              <p>
                <Moment format="hh:mm a">
                  {TransactionHistoryMobile?.created_at}
                </Moment>{" "}
              </p>
            </div>
          </section>
          <section className="trade-info">
            <h5>Trade info</h5>
            <div>
              <p>
                <span>rate</span>

                {userCountry === "nigeria" && (
                  <span>{`₦${TransactionHistoryMobile?.rate}/$`}</span>
                )}
                {userCountry === "kenya" && (
                  <span>{`K${TransactionHistoryMobile?.rate}/$`}</span>
                )}
                {userCountry === "ghana" && (
                  <span>{`₵${TransactionHistoryMobile?.rate}/$`}</span>
                )}
              </p>
              <p>
                <span>trade amount</span>
                <span>{`$${TransactionHistoryMobile?.dollar}`}</span>
              </p>
              <p>
                {userCountry === "nigeria" && <span>naira equivalent</span>}
                {userCountry === "kenya" && <span>K equivalent</span>}
                {userCountry === "ghana" && <span>₵ equivalent</span>}

                {userCountry === "nigeria" && (
                  <span>{`₦${TransactionHistoryMobile?.naira}`}</span>
                )}
                {userCountry === "kenya" && (
                  <span>{`K${
                    TransactionHistoryMobile?.naira * currencyRate
                  }`}</span>
                )}
                {userCountry === "ghana" && (
                  <span>{`₦${
                    TransactionHistoryMobile?.naira * currencyRate
                  }`}</span>
                )}
              </p>
            </div>
          </section>
          <section className="feedback">
            <div className="transaction__tooltip__container">
              <h5>Feedback</h5>
              <Link
                to={{
                  pathname:
                    "https://intercom.help/redem/en/articles/6000169-wondering-why-your-gift-card-might-get-declined",
                }}
                target="_blank"
              >
                <div className="transaction__tooltip">
                  <p>Learn why your trade could fail</p>
                  <button
                    type="button"
                    className=""
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Learn why your trade could fail"
                  >
                    <div className="transaction__tooltip__image">
                      <img src={tooltip} alt="" />
                    </div>
                  </button>
                </div>
              </Link>
            </div>
            <div className="feedback-container">
              <div className="feedback-info">
                <div className="feedback-info_content">
                  <div className="icon-wrapper" onClick={popupHandler}>
                    {TransactionHistoryMobile?.badImages.map(
                      (item, reference) => (
                        <img src={item} alt="" key={reference} />
                      )
                    )}
                    {/* <img src={details?.badImages} alt="" /> */}
                  </div>
                </div>
              </div>

              <div
                className="feedback-msg"
                style={{
                  backgroundColor: `${
                    TransactionHistoryMobile?.status === "successful"
                      ? "rgb(206, 245, 209)"
                      : "rgb(255, 247, 248)"
                  }`,
                }}
              >
                <img
                  src={
                    TransactionHistoryMobile?.status === "successful"
                      ? success
                      : failed
                  }
                  className="img-fluid check "
                  alt=""
                />
                <p
                  style={{
                    color: `${
                      TransactionHistoryMobile?.status === "successful"
                        ? "#25bb32"
                        : "#EC1A3F"
                    }`,
                  }}
                >
                  {TransactionHistoryMobile?.status === "successful"
                    ? "successful"
                    : "failed"}
                </p>
              </div>
            </div>
            <div>
              <p>{TransactionHistoryMobile?.comment}</p>
            </div>
          </section>
        </section>
      )}
    </div>
  );
};

export default SingleTransactionHistoryMobile;
