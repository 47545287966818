import React, { useState, useEffect } from "react";
import useGlobalContext from "../../../custom-hook/Context";
import dummyImg from "../../../asset/dummy-img.svg";
import add from "../../../asset/add-ecode.png";
import "../styles/card-upload.css";
import EcodeModal from "./EcodeModal";
import close from "../../../asset/ic_close_24px.svg";
import axios from "../../../axios";
import Swal from "sweetalert2";
// import empty from "../../../asset/empty giftcard holder.png";
// import TagsInput from "./TagsInput";

// const fileRegex = /[(png)(jpg)(jpeg)(svg)]$/;

const CardUpload = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [giftcardLink, setGiftcardLink] = useState([]);

  const { smallDevice, formData, setFormData } = useGlobalContext();
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  const currencyRate = Number(JSON.parse(localStorage.getItem("currencyRate")));

  const { card, country, type } = data;

  const [tags, setTags] = useState([]);

  const specialCharacters = [
    ".",
    "+",
    "-",
    " `",
    " `",
    "~",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "*",
    "(",
    ")",
    "_",
    "+",
    "=",
    "|",
    "}",
    "{",
    ":",
    "?",
    ",",
    "]",
    "[",
    ";",
  ];
  const validateEcodeInput = e => {
    if (specialCharacters.includes(e.key)) {
      e.preventDefault();
    }
  };

  function handleKeyDown(e) {
    validateEcodeInput(e);
    if (e.key !== "Enter") return;
    const giftcardValue = e.target.value;
    if (!giftcardValue.trim()) return;
    setTags([...tags, giftcardValue]);
    e.target.value = "";
  }

  useEffect(
    () => {
      setFormData({ ...formData, tags });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index));
  }

  // Handle Uploaded file from local directory
  const handleFileUpload = event => {
    const newSelectedFiles = event.target.files[0];
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map(file => {
      return URL.createObjectURL(file);
    });

    setSelectedFiles(previousImages => previousImages.concat(imagesArray));

    let token = JSON.parse(localStorage.getItem("token"));

    const formData = new FormData();
    formData.append("cardImage", newSelectedFiles);

    axios
      .post("/rd/v1/giftcard/upload", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        // console.log(response.data.message);
        let giftCardUploadLink = response.data.link;
        setGiftcardLink(response.data.link);
        setGiftcardLink([...giftcardLink, giftCardUploadLink]);
      })
      .catch(e => {
        // console.log(e);
      });
  };
  useEffect(
    () => {
      setFormData({ ...formData, giftcardLink });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [giftcardLink]
  );

  const handleInput = () => {
    let formBody = {
      subcategory: formData.cardSubCategory,
      cardType: formData.type.replace("-", "").toLowerCase(),
      hasReceipt: formData.hasReceipt,
      receiptType:
        formData.receipt === "No Receipt" ||
        formData.receipt === "Not Available"
          ? "null"
          : formData.receipt.split(" ")[0].toLowerCase(),
      category: formData.cardCategory.toLowerCase(),
      naira: Number(formData.amount) * Number(formData.rate),
      amount:Math.round( ( Number(formData.amount) * Number(formData.rate) * currencyRate )),
      dollar: Number(formData.amount),
      rate: Number(formData.rate),
      country:
        formData.country?.country.toLowerCase() ||
        formData.card?.country.toLowerCase(),
      images: formData.giftcardLink,
      eCode: formData.tags,
      entry: "web",
    };
    axios
      .post("/rd/v1/giftcard/trade/start", formBody)
      .then(response => {
        // console.log(response.data);
        // console.log(response.data.message);
        Swal.fire({
          icon: "success",
          // title: "Airtime bought successfully",
          text: `${response.data.message}`,
        }).then(function () {
          window.location.href = "/giftcard";
        });
      })
      .catch(e => {
        // console.log(e);
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };
  // console.log(formData.hasReceipt);
  // console.log(formData.receipt);
  const renderPhotos = source => {
    return source.map(photo => {
      return (
        <>
          <img
            className="uploaded__image"
            src={photo}
            alt="uploaded card"
            key={photo}
            style={{ width: "20%", height: "180px" }}
          />
          <button
            className="close-image"
            onClick={() =>
              setSelectedFiles(selectedFiles.filter(e => e !== photo))
            }
          >
            <span>&times;</span>
          </button>
        </>
      );
    });
  };
  const renderPhotosMobile = source => {
    return source.map(photo => {
      return (
        <>
          <img
            className="mobile__uploaded__image"
            src={photo}
            alt="uploaded card"
            key={photo}
            style={{ width: "70%", height: "100px" }}
          />
          <button
            className="mobile-close-image"
            onClick={() =>
              setSelectedFiles(selectedFiles.filter(e => e !== photo))
            }
          >
            <span>&times;</span>
          </button>
        </>
      );
    });
  };
  const popupHandler = () => {
    setShowModal(true);
  };

  // Svg icon for small and large screen
  const agreedIcon = smallDevice ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.31"
      height="21.66"
      viewBox="0 0 29.31 21.66"
    >
      <g fill="#6c63ff">
        <path d="m10.54 6.94 2.32-2.31-1.55-1.54-7.7 7.71 10.53 10.54a1.1 1.1 0 0 0 1.54-1.54l-1.54-1.55a1.1 1.1 0 1 1 1.54-1.54l1.55 1.54a1.1 1.1 0 0 0 1.54-1.54l-1.54-1.54a1.1 1.1 0 0 1 1.54-1.54l1.54 1.54a1.1 1.1 0 0 0 1.54 0 1.1 1.1 0 0 0 0-1.54l-.51-.52-3.86-3.86-2.3 2.32a3.27 3.27 0 0 1-4.64-4.63ZM9.45 1.87l.32-.33L8.23 0 0 8.23l1.54 1.54Z" />
        <path d="M15.17 5.4 12.1 8.48a1.1 1.1 0 0 0 1.54 1.55l3.09-3.09a1.1 1.1 0 0 1 1.54 0l4.63 4.63.51.51.77.77 1.54-1.54-8.23-8.22ZM27.77 10.29l-8.74-8.74L20.57 0l8.74 8.74z" />
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.29"
      height="30.51"
      viewBox="0 0 41.29 30.51"
    >
      <g fill="#6c63ff">
        <path d="m14.85 9.78 3.26-3.26-2.18-2.17L5.07 15.2l14.85 14.85a1.54 1.54 0 0 0 2.17-2.17l-2.17-2.18a1.54 1.54 0 1 1 2.17-2.17l2.17 2.17a1.54 1.54 0 0 0 2.18-2.17l-2.18-2.17a1.54 1.54 0 0 1 2.18-2.18l2.17 2.18a1.54 1.54 0 0 0 2.17 0 1.54 1.54 0 0 0 0-2.17l-.72-.73-5.43-5.43-3.26 3.26a4.61 4.61 0 0 1-6.52-6.52ZM13.3 2.63l.46-.46L11.58 0 0 11.6l2.17 2.17Z" />
        <path d="m21.37 7.6-4.35 4.35a1.54 1.54 0 0 0 2.18 2.17l4.34-4.34a1.54 1.54 0 0 1 2.18 0l6.52 6.51.72.73 1.08 1.08 2.18-2.17-11.6-11.58ZM39.11 14.49 26.81 2.18 28.96 0l12.31 12.3z" />
      </g>
    </svg>
  );

  const numberedAmount = formData.amount;
  const numberedEquiv = formData.amount * formData.rate;

  return (
    <div className="card-upload mx-auto">
      <header className="upload_first-header">
        {agreedIcon}
        <h4>Summary of your trade</h4>
      </header>
      <section className="trade-summary">
        <div className="summary-content">
          <p>
            <span>Country</span>
            <span>
              {country?.country || formData.card?.country}
              {/* <span className="country-img-wrapper">
                <img src={country.icon} className="img-fluid" alt={country.name} />
              </span> */}
            </span>
          </p>
          <p>
            <span>Card type</span>
            <span>{type || formData.card?.type}</span>
          </p>
          <p>
            <span>Category</span>
            <span>
              {formData.cardCategory} [{formData.cardSubCategory}]
            </span>
          </p>
          <p>
            <span>Rate</span>
            {userCountry === "nigeria" && <span>{`₦ ${formData.rate}/$`}</span>}
            {userCountry === "kenya" && (
              <span>{`K ${ Math.round(formData.rate * currencyRate)}/$`}</span>
            )}
            {userCountry === "ghana" && (
              <span>{`₵ ${ Math.round(formData.rate * currencyRate)}/$`}</span>
            )}
          </p>
          <p>
            <span>Trade amount</span>
            <span>{`$${numberedAmount}`}</span>
          </p>
          <p>
            <span>Total equivalent </span>
            {userCountry === "nigeria" && (
              <span>NGN &nbsp;{Math.round(numberedEquiv).toLocaleString()}</span>
            )}
            {userCountry === "kenya" && (
              <span>K &nbsp;{Math.round(numberedEquiv * currencyRate).toLocaleString()}</span>
            )}
            {userCountry === "ghana" && (
              <span>₵ &nbsp;{Math.round(numberedEquiv * currencyRate).toLocaleString()}</span>
            )}
          </p>
        </div>
        <div className="card-img-wrapper giftcard__summary">
          {/* <img src={card.image} alt="" /> */}
          <img
            src={
              card.image ||
              require(`../../../asset/giftcards/${card.identifier.toLowerCase()}.png`)
            }
            alt={card.name || card.identifier}
          />
        </div>
      </section>
      {formData.type === "Physical" && (
        <>
          <header className="upload_second-header">
            <h4>Upload image </h4>
            <div className="upload_second-header__right">
              <p className="upload_second-header__right_p">
                Adding e-code might help your trade if your images are unclear
              </p>
              <div className="upload_second-header__right__sub">
                <div className="upload_second-header__right__sub_image">
                  <img src={add} alt="" />
                </div>
                <p
                  className="upload_second-header__right__sub_p"
                  onClick={popupHandler}
                >
                  Add e-code
                </p>
              </div>
            </div>
          </header>
          <div className="ecode__container">
            <EcodeModal
              showModal={showModal}
              setShowModal={setShowModal}
              closeImg={close}
            />
          </div>
          <section className="upload-content">
            {!smallDevice ? (
              <>
                <div className="img-upload-container">
                  <div className="img-wrapper">
                    <img src={dummyImg} className="img-fluid" alt="" />
                  </div>
                  <label>
                    Drag and drop an image or
                    <input
                      type="file"
                      name="file[]"
                      id="upload"
                      accept="image/png, image/jpeg, image/webp, image/jpg, image/svg"
                      multiple
                      onChange={handleFileUpload}
                    />
                  </label>
                  <p>Select/upload one image at a time</p>
                  <div
                    className="upload__image__container"
                    style={{ padding: "20px 10px" }}
                  >
                    <div> {renderPhotos(selectedFiles)} </div>
                  </div>
                </div>
                {/* <div className="code-input-wrapper">
                <div className="form-group">
                  <label htmlFor="eCode">Enter e-code</label>
                  <input type="text" name="eCode" id="eCode" placeholder="RTYY-U120-450L" />
                </div>
              </div> */}
              </>
            ) : (
              <>
                {/* <div className="form-group">
                <input type="text" name="eCode" id="eCode" placeholder="Paste code here" />
              </div>
              <p>Or</p> */}
                <div className="smallscreen_card-upload">
                  <input
                    type="file"
                    name="file[]"
                    id="upload"
                    accept="image/png, image/jpeg, image/webp, image/jpg, image/svg"
                    multiple
                    onChange={handleFileUpload}
                    className="upload-input"
                  />
                  <p>Upload giftcard image</p>
                </div>
                <div
                  className="giftcard__upload__mobile"
                  style={{ padding: "20px 10px" }}
                >
                  <div> {renderPhotosMobile(selectedFiles)}</div>
                  {/* <button  onChange={handleFileUpload}> princess is here</button> */}
                </div>
              </>
            )}
          </section>
          {formData.giftcardLink && formData.giftcardLink.length > 0 && (
            <div className="start__trade__button__section">
              <button
                className="start__trade__button"
                onClick={() => handleInput()}
              >
                Begin my trade
              </button>
            </div>
          )}
          {formData.giftcardLink && formData.giftcardLink.length < 1 && ""}
        </>
      )}

      {formData.type === "E-Code" && (
        <>
          <header className="upload_second-header">
            <h4>Enter E-Code </h4>
          </header>
          {/* <article className="ecode-modal_content"> */}
          {/* <div className="ecode__content__wrapper"> */}
          <div className="main__ecode__details__section__form">
            <div className="main__ecode__details__section__form__sub">
              <h3>Press enter when you're done typing</h3>
              <div className="main__tags-input-container">
                {tags.map((tag, index) => (
                  <div className="tag-item" key={index}>
                    <span className="text">{tag}</span>
                    <span className="close" onClick={() => removeTag(index)}>
                      &times;
                    </span>
                  </div>
                ))}
                <input
                  onKeyDown={handleKeyDown}
                  type="text"
                  className="main__tags-input"
                  // placeholder="Enter E-Code"
                />
              </div>
            </div>
            {formData.tags && formData.tags.length > 0 && (
              <div className="main__ecode__details__section__button__section">
                <button
                  type="button"
                  className="main__ecode__details__section__button"
                  onClick={() => handleInput()}
                >
                  Begin my trade
                </button>
              </div>
            )}
            {formData.tags && formData.tags.length < 1 && ""}
          </div>
          {/* </div> */}
          {/* // </article> */}
        </>
      )}
    </div>
  );
};

export default CardUpload;
