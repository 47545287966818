import { NOTIFICATION_LIST } from "../constants/action-types";

const initialState = {
    notificationsList: [],
    
}

export default function notificationReducer(state = initialState, action) {
    const {type, payload} = action;

    switch(type) {
        case NOTIFICATION_LIST:
            return {
                ...state,
                notificationsList: payload
            }
        default:
            return state;
    }
}