import React from "react";
// import { Link } from "react-router-dom";
import "../styles/help-email-main.css";
import MainHeader from "../../../layouts/header/components/MainHeader";
import useGlobalContext from "../../../custom-hook/Context";

const HelpEmailMain = () => {
  const { smallDevice } = useGlobalContext();

  const backTrackBtn = smallDevice ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.18"
      height="8.39"
      viewBox="0 0 5.18 8.39"
    >
      <path
        fill="#1b1c1d"
        d="m5.18 7.4-3.2-3.2L5.18.99 4.2 0 0 4.2l4.2 4.19Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.16"
      height="11.6"
      viewBox="0 0 7.16 11.6"
    >
      <path
        fill="#1b1c1d"
        d="M7.16 10.24 2.74 5.8l4.42-4.44L5.8 0 0 5.8l5.8 5.8Z"
      />
    </svg>
  );
  return (
    <main className="main">
      <MainHeader
        text="Email"
        backTrackBtn={backTrackBtn}
        buttonStyle={{
          alignSelf: "flex-start",
          width: "52px",
          height: "52px",
          backgroundColor: "#FDFDFD",
          border: "1px solid #1b1b1b",
          borderRadius: "50%",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
      <div className="trade-terms help__email__main" style={{ margin: "50px auto" }}>
        <div className="help__email__section">
          <div className="help__email__section__text">
            <h4 className="help__email__section-title">Reach us on our mail</h4>
            <p>
              Reach out to us through our email address and expect a very swift
              response to your messages.
            </p>
            <p>
              Our support team are always available to listen to your needs and
              tend to them.
              {/* <a className="email" href="mailto:support@getredem.io">
                &nbsp;
                <span>support@getredem.io </span>
              </a> */}
            </p>
          </div>

          <div className="help__email__section__button__section">
          <a className="email" href="mailto:support@getredem.io">
              <button
                type="button"
                className="help__email__section__button"
              >
                Reach Out
              </button>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default HelpEmailMain;
