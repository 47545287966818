import React from "react";
import "../styles/savedbanksmain.css";
// import { savedBankListDetails } from "../data/addBankDetails";

const SavedBankMain = ({ bankcode, bankname, bankholder, nuban }) => {
  // console.log(nuban)
  return (
    <section className="savedbank__info" >
    <div className="savedbank__carddetails">
      <div className="number__div">
        
      <img src={require(`../../asset/${bankcode}.svg`)} className="" alt="" />
        {/* <img src={bank} alt="" /> */}
      </div>
      {/* <img src={bank} alt="" /> */}
      <div className="savedbank__carddetails__info">
        <p className="savedbank__carddetails__info__name">
          {bankholder}
        </p>
        <p className="savedbank__carddetails__info__account">
          {nuban}
        </p>
      </div>
    </div>
  </section>
  );
};

export default SavedBankMain;
