import React, { useState, useEffect } from "react";
import padlock from "../../../asset/padlock.png";
import useGlobalContext from "../../../custom-hook/Context";
import axios from "../../../axios";
import Swal from "sweetalert2";
import Spinner from "../../../content/component/Spinner";

const AvatarImagesItem = ({
  levelName,
  image,
  level,
  status,
  icon,
  id,
  avatar,
  userLevel,
  selectedIndex,
  onSelect
}) => {
  const [active, setActive] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [avatarButton, setAvatarButton] = useState(false);
  const [loading, setLoading] = useState(false);
 


  useEffect(() => {
    if (userLevel >= level) {
      setShowOverlay(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLevel]);

  const { setMyRedemAvatarDetails, myRedemAvatarDetails, largeDevice } =
    useGlobalContext();

  const updateActiveElement = id => {
    if (userLevel < level) {
     return;
    }
    setActive(id);
    onSelect(id)
    setActive(!active);
    setAvatarButton(true);
    setMyRedemAvatarDetails({ ...myRedemAvatarDetails, image, avatar });
  };

  let token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      auth: `Bearer ${token}`,
    },
  };
  const updateRedemAvatar = () => {
    let formBody = {
      avatar: myRedemAvatarDetails.avatar,
      token: config.headers.auth,
    };
    setLoading(true);

    axios
      .post("/rd/v1/user/setAvatar", formBody)

      .then(response => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: `${response.data.message}`,
        }).then(function () {
          window.location.href = "/dashboard";
        });
      })
      .catch(e => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };

  return (
    <div className="avatar__list__section">
      <div className="avatar__list__sub__sub__section">
        <div className="pri">
          <p className="avatar__list__sub__sub__section__number">{level}</p>
          <p className="avatar__list__sub__sub__section__level">{levelName}</p>
        </div>
        <div className="avatar__list__sub__section__image">
          <img src={image} className="" alt="" />
        </div>
      </div>
      <div
        className="avatar__list__sub__section"
        active={active === id ? active : 0}
        onClick={() => updateActiveElement(id)}
        style={{
          border: selectedIndex === id ? "solid 1px #1B1C1D" : "none",
          borderRadius: selectedIndex === id ? "15px" : "none",
        }}
      >
        <>
          {showOverlay ? (
            <div className="avatar-image-clear-overlay" />
          ) : (
            <div className="avatar-image-overlay">
              <div className="avatar-image-clear-overlay-content">
                <img src={padlock} alt="" />
              </div>
            </div>
          )}
          <div
            className="avatar__list__section__image"
            style={{
              opacity: userLevel >= level ? "1" : "0.2",
            }}
          >
            <img src={image} alt="" className="avatar__list__section__img" />
          </div>
        </>
      </div>
      <div className="avatar__list__section__text">
        {/* <p className="avatar__list__section__first__text">{level}</p> */}
        <div className="avatar__list__section__section">
          <p className="avatar__list__section__second__text">{status}</p>
          <div className="avatar__list__section__section__image">
            <img
              src={icon}
              alt=""
              className="avatar__list__section__section__img"
            />
          </div>
        </div>
      </div>

      {avatarButton ? (
        <div
          className="chika"
          style={{ display: `${largeDevice ? "block" : "none"}` }}
        >
          {!loading && (
            <button
              type="button"
              className="avatar-profile__section__button"
              style={{ marginTop: "5px" }}
              onClick={() => updateRedemAvatar()}
            >
              Apply avatar
            </button>
          )}

          {loading && (
            <button
              type="button"
              className="avatar-profile__mobile__loading__button"
            >
              <Spinner />
            </button>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AvatarImagesItem;

