import React from "react";
import "../styles/ecode-modal.css";
import TagsInput from "./TagsInput";
// import { Link } from "react-router-dom";

const EcodeModal = ({
  // details,
  showModal,
  setShowModal,
  closeImg,
}) => {
  return (
    <div className={`ecode-modal_container ${showModal ? "show-modal" : ""}`}>
      <div className="ecode-modal">
        <div className="ecode-modal_header">
          {/* <p>Enter E-code</p> */}
          <button type="button" onClick={() => setShowModal(false)}>
            {closeImg ? <img src={closeImg} alt="Close icon" /> : "X"}
          </button>
        </div>
        <article className="ecode-modal_content">
          <div className="ecode__content__wrapper">
          <div className="ecode__details__section__form">
            <h3>Press enter when you're done typing</h3>
            <TagsInput setShowModal={setShowModal}/>
            </div>
            {/* <form className="ecode__form">
              <div className="ecode__details__section__form">
                <div className="ecode__details__section__form-group">
                  <div className="ecode-wrapper">
                    <label
                      htmlFor="ecodelabel"
                      className="ecode__details__section__account-label"
                    >
                      Separate multiple e-codes with full stop (.), coma (,) or
                      colon (:)
                    </label>
                  </div>
                  <input
                   
                    className="ecode__details__section__input"
                    
                    required
                  />
                </div>
                <div className="ecode__details__section__button__section">
                  <button
                    type="button"
                    className="ecode__details__section__button"
                    // onClick={() => handleInput()}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </form> */}
          </div>
        </article>
      </div>
    </div>
  );
};

export default EcodeModal;
