import React from "react";
import MainHeader from "../../../layouts/header/components/MainHeader";
import privacyIcon from "../../../asset/Group 162819.png";
import "../styles/privacy-main.css";

const PrivacyMain = () => {
  return (
    <main className="main">
      <MainHeader text="Privacy Policy" />
      <section className="privacy__section">
        <div className="privacy__section__header">
          <div className="privacy__section__header__image">
            <img src={privacyIcon} alt="privacy icon" />
          </div>
          <h3>Privacy & Terms of use</h3>
          <p>
          RIDIM LTD undertakes to use the virtual accounts provided by the
          Bank solely for the purpose of this Agreement and gives no assurance
          or guarantee as to the profitability or otherwise of such
          transactions. Every transaction with RIDIM LTD is between you
          and RIDIM with no interference to the bank. As such, the Bank shall
          not be liable for any loss of profit or investment on any transaction
          undertaken by you on the Bank’s infrastructure, unless such loss is
          occasioned by the willful negligence of the Bank.
          </p>
        </div>
        <div className="privacy__section__listings">
          <div className="privacy__section__listings__content">
          <h3 className="privacy__section__listings__heading"> 01. Data Protection</h3>
          <p className="privacy__section__listings__text">RIDIM LTD hereby agrees to obtain the consent of you to the
              sharing of the information (including personal information) to be
              provided to the Bank for the administration and operation of the
              transaction contemplated under this Agreement, and comply with
              other relevant provisions of the nigerian Data Protection
              Regulation, 2019 and agree that the relationship contemplated
              under this Agreement is non-exclusive and either Party may
              contract with as many additional clients, persons or companies as
              either Party in either Party’s sole discretion sees fit. Sequel to
              registration and opening an account to use RIDIM LTD
              SERVICES, you have unconditionally agreed to and accept the terms
              and conditions stated hereunder: You accept not to use products
              and services of RIDIM LTD to facilitate any illegal
              activities. The information contained on this website does not
              constitute accounting, legal, financial, consulting, investment,
              or other professional advice. You bear all the risks from any
              decision to use RIDIM LTD and RIDIM LTD shall not be
              liable for any loss suffered. Your account may be terminated if we
              discover that you have provided us with false or misleading
              information, or refuse to provide correct information about your
              true identity.</p>
              <p className="privacy__section__listings__text"> You agree and consent to receive correspondence from RIDIM
              LTD  via email, SMS, app notifications, social media,
              letters, or any other media we use. You are responsible for
              maintaining adequate security and control of any and all IDs,
              passwords, personal identification numbers (PINs), or any other
              codes or credentials that you use to access the services. You are
              not allowed to sell, borrow, share, or otherwise make available
              your account or any detail necessary to access your account to
              people or entities other than yourself. Your account shall not
              contain misleading or fraudulent information, including, but not
              LTD  to, having a non-personal phone number, creating fake
              reputation information for your account, faking your country of
              origin, or providing fraudulent identification documents. We may
              at any time require you to complete our ID verification process
              and may also require you to submit additional identification
              documents to us if we deem it necessary. Failing to complete ID
              verification will be considered a violation of these Terms. RIDIM
              LTD  may change these Terms of Use at any time for any reason
              without notice. RIDIM LTD may suspend your account at any
              time for any reason.</p>
          </div>
          <div className="privacy__section__listings__content">
          <h3 className="privacy__section__listings__heading"> 02. Acceptance and Change of Terms</h3>
          <p className="privacy__section__listings__text"> If you do not agree with any of these Terms of Service please do
              not use the RIDIM LTD website, app, and its services. By
              using this site or any of RIDIM RESOURCE services, you will be
              deemed to have irrevocably agreed to these terms and conditions.
              Please note that these Terms of Service may be revised and
              reissued without notice at any time. You should visit this page
              regularly, and before engaging in any trades, in particular, to
              review the current Terms of Service, since your continued use of
              the site will be deemed as an irrevocable acceptance of any
              revisions. Notwithstanding the above, we will strive to notify you
              of changes to these Terms of Service which significantly affect
              your rights and obligations. Such notices shall be sent to the
              email address you provided as part of your account settings and it
              is your responsibility to keep your contact information up to
              date.</p>
          </div>
          <div className="privacy__section__listings__content">
          <h3 className="privacy__section__listings__heading"> 03. Transactions</h3>
          <p className="privacy__section__listings__text"> By using RIDIM LTD, you accept the risk that transactions
              may be delayed and you agree not to hold RIDIM LTD liable
              for any damages or loss due to delays. Trade orders placed on
              RIDIM LTD are binding and cannot be canceled or reversed.
              You acknowledge that the gift cards you trade on RIDIM LTD
              belong to you and was purchased through proper channels without
              violating any law. This service is provided without warranty of
              any kind and RIDIM LTD does not guarantee that the site will
              be available 100% of the time. You acknowledge and accept that the
              website may become unavailable from time to time due to various
              circumstances and that RIDIM LTD will not be liable for any
              losses due to the system or service becoming unavailable. RIDIM
              LTD  cannot and does not guarantee the value of any traded
              item and has no obligation to buy back such traded items at any
              time. It is the customer's responsibility to pay for all
              transaction taxes.</p>
          </div>
          <div className="privacy__section__listings__content">
          <h3 className="privacy__section__listings__heading">04. Investigations</h3>
          <p className="privacy__section__listings__text"> RIDIM LTD reserves the right to investigate suspected
              violations of these Terms of Service, including without limitation
              any violation arising from any submission, posting, or e-mails you
              make or send to any forum on the site. RIDIM LTD may seek to
              gather information from the user who is suspected of violating
              these Terms of Service, and from any other user. RIDIM LTD
              may suspend any users whose conduct or postings are under
              investigation and may remove such material from its servers as it
              deems appropriate and without notice.</p>
          </div>
          <div className="privacy__section__listings__content">
          <h3 className="privacy__section__listings__heading">  05. Account Monitoring, Suspension, and Termination</h3>
          <p className="privacy__section__listings__text">  Upon probable cause to believe that you have acted in violation of
              these Terms of Service or: That any content or material submitted
              or shared by you in or through the Services violates these Terms
              of Service or the intellectual property rights, other property
              rights, or the right to privacy of any third party. We have reason
              to believe that your use of the services is in violation of any
              applicable law or regulation, or that you are using the services
              in a fraudulent manner or otherwise contrary to the purpose of
              this Agreement. We have reason to believe that you have supplied
              false, misleading, or deceptive information in connection with
              your registration, identity verification, transactions, or any
              other use of the services, either to us or to another user. You
              have not responded to our inquiries or supplied the information
              requested by us within a reasonable period of time</p>
              <p className="privacy__section__listings__text">  We are required to do so under any applicable law, regulation or
              an order issued by an authority If we determine that you have at
              any previous point in time violated this Agreement or any other
              agreement that you have entered into with us, Upon the occurrence
              of ANY of the above, RIDIM LTD shall immediately close,
              suspend, or limit your account. Valid payment details must be
              provided through an authorized RIDIM LTD web, app, or
              specified trading system. It is a violation of these Terms of
              Service to provide payment details through other unauthorized
              channels. Buyers must be able to, upon our request, provide
              adequate proof of payment for up to 180 days after trades have
              been completed.</p>
          </div>
          <div className="privacy__section__listings__content">
          <h3 className="privacy__section__listings__heading">   06. Prohibited Activities</h3>
          <p className="privacy__section__listings__text">RIDIM LTD strives to attain and maintain the highest
              security culture and compliance standards to government laws and
              regulations as well as the protection of our customers. Engaging
              in any of the prohibited activities specified below may give rise
              to prosecution by law enforcement agencies. The following acts are
              prohibited on RIDIM LTD’ web, mobile, app, or other
              platforms. Gathering, stealing, or engaging in unauthorized
              collection of information about users. Changing, amplifying, or
              modifying any part of the site or the services for any purpose
              whatsoever. Disguising identity or user credentials, use of
              another user's credentials in engaging with RIDIM LTD.
              Alter-engineering, reverse-engineering, or otherwise tampering
              with the RIDIM LTD platform in order to find limitations or
              vulnerabilities. Covert engineering of products/services for
              unauthorized purposes.</p>
          </div>
          <div className="privacy__section__listings__content">
          <h3 className="privacy__section__listings__heading">  07. Grievance Policy</h3>
          <p className="privacy__section__listings__text">   RIDIM LTD believes in providing excellent service and
              strives to be sincere and transparent in its approach to customer
              service. Your confidence in us is of paramount importance to us
              and we would never place our personal growth before your interest.
              This Policy aims to minimize instances of customer complaints,
              grievances, and disputes through a channelized approach of review
              and redress. Your feedback will help us in identifying
              shortcomings in our product features and service delivery. Your
              satisfaction is our main objective in our quest to build a healthy
              customer relationship and deliver excellent service. RIDIM
              LTD  has developed a procedure for the prompt attention to
              the grievances of its customers with respect to various issues by
              setting up a “customer support” and “a dispute resolution
              mechanism”. The following are the foundational principles of this
              policy: Transparency; Prompt, courteous and timeous response to
              all queries, inquiries, and complaints; and Constantly developing
              new and smarter mechanisms to receive and address customer
              grievances. The details of the dispute resolution mechanism are
              placed in the domain of public knowledge. A dispute may occur as a
              result of several reasons. It may be because of the difference in
              the service expected and the service delivered. A dispute may also
              arise as a result of technical or non-technical errors in the
              system or at times due to human inadvertence or error.</p>
              <p className="privacy__section__listings__text">RIDIM LTD provides a user-friendly platform to all its
              customers to file a complaint/ share feedback if they are
              disappointed by the services rendered. Customers can give their
              complaint/ feedback in sending an email to support@getredem.io If
              the user’s grievance is not resolved within the stipulated time
              frame, or if the user is not completely satisfied with the
              solution provided by RIDIM LTD, then they can approach our
              dispute resolution system with their complaint or explore other
              legal avenues available for dispute resolution. To make RIDIM
              LTD ' dispute resolution system more meaningful and
              effective, a structured system has been put in place. This system
              will ensure that the complaints are redressed seamlessly and well
              within the stipulated time frame. The expertise of staff in
              handling complaints Our staff undergo regular training to ensure
              that consumers' queries and grievances are handled properly. They
              are trained to work in a manner that enhances consumer trust and
              confidence in us. Our objective to achieve maximum customer
              satisfaction can be witnessed in both the operations as well as in
              customer communications. We analyze consumer queries and
              complaints and ensure the removal of such complaints from the
              root. This helps in improving the overall quality of the service
              levels gradually.</p>
          </div>
          <div className="privacy__section__listings__content">
          <h3 className="privacy__section__listings__heading"> 08. Introduction to our Dispute Resolution System</h3>
          <p className="privacy__section__listings__text"> The users’ experience is our focus and this is why we devote
              ourselves to listen to our users and take their concerns
              seriously. We positively analyze and scrutinize how you would feel
              from the time you make requests for our services until the time
              you’re done to evolve and enhance our services. We ensure a simply
              incredible and user-friendly experience to all our customers and
              in case of any complaint or concern, we do everything we can to
              fix them.</p>
          </div>
          <div className="privacy__section__listings__content">
          <h3 className="privacy__section__listings__heading">
              How to reach our support desk:</h3>
          <p className="privacy__section__listings__text"> For Exchange, Payment or Related Issues You can send an email to
              support@getredem.io</p>
          </div>
          <div className="privacy__section__listings__content">
          <h3 className="privacy__section__listings__heading">For Legal, Compliance or Related Issues</h3>
          <p className="privacy__section__listings__text">You can send an email to support@getredem.io It is also
              recommended that the customer provides a complaint reference
              number provided by our customer care team in all further
              communication with us regarding a particular issue. This enables
              us to get more details about the customer and the query quickly
              and helps in resolving the query faster. Suitable timelines have
              been set for every complaint depending on the nature of queries
              and the investigations lead time which would be involved in
              resolving the same. I acknowledge that I have read these terms and
              conditions and I agree to follow the guidelines stated by RIDIM
              LTD  during any trading or transactions on the website or
              App.</p>
          </div>
         
        </div>
      </section>
    </main>
  );
};

export default PrivacyMain;
