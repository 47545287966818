import React from "react";
import ForgotPasswordChangePasswordMain from "../content/forgot-password/components/ForgotPasswordChangePasswordMain";

const ForgotPasswordChangePassword = () => {
  return (
    <div>
      <ForgotPasswordChangePasswordMain />
    </div>
  );
};

export default ForgotPasswordChangePassword;
