import React, { useState, useRef, useEffect } from "react";
import "../styles/rates-card-slider.css";
import useGlobalContext from "../../../custom-hook/Context";


const RatesCardSlider = ({cards, next, prev}) => {

  const { setGiftcardName, } = useGlobalContext();
  // eslint-disable-next-line 
  const [cardSlides, setCardSlides] = useState(cards);
  const [current, setCurrent] = useState(cards[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const containerRef = useRef(null);

  useEffect(() => {
    setCurrent(cards[0]);
    setCurrentIndex(0);
    setGiftcardName(current);
    localStorage.setItem('selectedCard', cards[0].name)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards]);


  // Next and previous event handler
  const slideActionHandler = (action) => {
    //const slideContainer = containerRef.current;
  
    // Get current item index
    const index = cards.findIndex(card => card.id === current.id);
    
    if(action === "next") { 
      setCurrent(cardSlides[(index + 1) % cardSlides.length]);
      setCurrentIndex((index + 1) % cardSlides.length)
      localStorage.setItem('selectedCard', cards[(index + 1) % cardSlides.length].name)
    }

    if(action === "prev") {
      
      if((index - 1) < 0) {
        
        setCurrent(cardSlides[cardSlides.length - 1]);
        setCurrentIndex(cardSlides.length - 1);
        localStorage.setItem('selectedCard', cards[cardSlides.length - 1].name)
        return;
      } 

      setCurrent(cardSlides[index - 1]);
      setCurrentIndex(index - 1);
      localStorage.setItem('selectedCard', cards[index - 1].name)
    }
  }

  return (
    <article className="slide-container">
      <div className="slider-wrapper">
        <section className="slider" ref={containerRef}>
          <div className="slide-item first-prev-slide">
            <img
              src={cardSlides[((
                (currentIndex - 2) === -1
                ) ? cardSlides.length - 1 : (
                currentIndex - 2 === -2
              ) ? cardSlides.length - 2 : currentIndex - 2)].image} 
              alt="" 
            />
          </div>
          <div className="slide-item second-prev-slide">
            <img
              src={cardSlides[(((currentIndex - 1) < 0) ? cardSlides.length - 1 : currentIndex - 1)].image}
              alt="" 
            />
          </div>
          <div className="slide-item active">
            <img
              src={current.image} 
              alt="" 
              className="active"
            />
          </div>
          <div className="slide-item first-next-slide">
            <img 
              src={cardSlides[((currentIndex + 1) % cardSlides.length)].image} 
              alt="" 
            />
          </div>
          <div className="slide-item second-next-slide">
            <img 
              src={cardSlides[((currentIndex + 2) % cardSlides.length)].image} 
              alt="" 
            />
          </div>
        </section>
      </div>
      <div className="slider-controls">
        <button type="button" className="prev" onClick={ () => slideActionHandler("prev") }>
          {prev ? (
            <img src={prev} alt="" />
          ) : ""}
        </button>
        <input type="text" value={current.name} disabled />
        <button type="button" className="next" onClick={ () => slideActionHandler("next") }>
          {
            next ? (
              <img src={next} alt="" />
            ) : ""
          }
        </button>
      </div>
    </article>
  )
};

export default RatesCardSlider;