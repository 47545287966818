import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import imageRemove from "../../asset/image-removebg-preview@2x.png";
import "../styles/wallet-balance.css";
import LottieAnimation from "./LottieAnimation";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../js/actions/profile";

const WalletBalance = ({ getProfile, profile }) => {
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  useEffect(() => {
    getProfile();
  }, [getProfile]);

  return (
    <section className=" card wallet-balance">
      <header className="card-header">
        <div className="king-img">
          {/* <img src={imageRemove} className="img-fluid" alt="" /> */}
          <LottieAnimation />
        </div>
        <h3 style={{ textTransform: "capitalize", marginRight: "10px" }}>
          {profile.profile.avatarID}
        </h3>
      </header>
      <div className="card-body wallet__body">
        {/* <h4>Wallet Balance</h4> */}

        {userCountry === "nigeria" && (
          <p className="wallet-amount">₦ {profile.profile.wallet}.00</p>
        )}
        {userCountry === "kenya" && (
          <p className="wallet-amount">K {profile.profile.wallet}.00</p>
        )}
        {userCountry === "ghana" && (
          <p className="wallet-amount"> ₵ {profile.profile.wallet}.00</p>
        )}

        <Link to="/giftcard" className="btn trade__btn">
          Trade Giftcard
        </Link>
        {/* <p>Add a bank account for withdrawal</p> */}
      </div>
      <div className="custom-card-footer">
        <div className="card-btn">
          <Link to="/transfer" className="btn deposit-btn">
            Transfer
          </Link>
          <Link to="/withdraw" className="btn withdraw-btn">
            Withdraw
          </Link>
        </div>
      </div>
    </section>
  );
};

WalletBalance.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(WalletBalance);
