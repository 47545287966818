import React from "react";
import MainHeader from "../../layouts/header/components/MainHeader";
import { Link } from "react-router-dom";

//Styles

import Balance from "../../content/component/Balance";
import countryflag from "../../asset/Group-162718.svg";
import house from "../../asset/Group-162732.svg";
// import house from "../../asset/PMT_M131_010.svg";
// import Transfer from "../content/component/Transfer";
// import Withdraw from "../content/component/Withdraw";
// import Deposit from "../content/component/Deposit";
// import TransferToAnotherBank from "../content/component/TransferToAnotherBank";
import TransactionHistory from "../../content/component/TransactionHistory";
import "../styles/wallet-main.css";

const WalletMain = text => {


  return (
    <main className="main">
      <MainHeader text="My Wallet" />
      <div className="main-content">
        <div className="wallet__transfer__container">
          <div className="wallet__main__component">
            <div className="">
              <Balance />
            </div>
            <div className="transfer__withdraw__component__main">
              <Link to="/withdraw">
                <div className="WITHDRAW COMPONENT withdraw">
                  {/* <section className="">
                  <div className=" "> */}
                  <div className="withdraw__image__main">
                    <img src={countryflag} className="withdraw__image" alt="" />
                  </div>
                  <p className="title">Withdraw</p>
                  {/* </div>
                </section> */}
                </div>
              </Link>
              <Link to="/transfer">
                <div className="TRANSFER COMPONENT transfer">
                  {/* <section className="">
                  <div className=""> */}
                  <div className="transfer__image__main">
                    <img src={house} className="transfer__image" alt="" />
                  </div>
                  <p className="title">Transfer</p>

                  {/* </div>
                </section> */}
                </div>
              </Link>
            </div>
          </div>

          <TransactionHistory />
        </div>
      </div>
    </main>
  );
};

export default WalletMain;
