import React, { useEffect } from "react";
import useGlobalContext from "../../../custom-hook/Context";
import { Link } from "react-router-dom";
import MainHeader from "../../../layouts/header/components/MainHeader";
import Password from "../../component/Password";
import PinShield from "../../component/PinShield";
import padlock from "../../../asset/Group 163392.svg";
import "./../style/security-main.css";

const SecurityMain = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return (
    <main className="main">
      <MainHeader text="Change Password/Pin" />

      <section>
        <section className="credentials__main">
          <div className="change__password">
            <div className="change__password__icon">
              <Password />
              <p className="change__password__icon__text">Password</p>
            </div>
            <div>
              <p className="change__password__icon__info">
                Your password lets you access and log into your account. While
                resetting your password, ensure it's something secure and you
                can easily remember.
              </p>
            </div>
            <div className="">
              <Link to="/profile/request-code">
                <button type="button" className="change__password__button">
                  Change password
                </button>
              </Link>
            </div>
          </div>

          <div className="change__password">
            <div className="change__password__icon">
              <PinShield />
              <p className="change__password__icon__text">Pin</p>
            </div>
            <div>
              <p className="change__password__icon__info">
                Your pin lets us verify that you're the one trying to complete
                transactions like withdrawals, transfers etc. Redem would never
                ask you to share your secret pin.
              </p>
            </div>
            <div className="">
              <Link to="/profile/pin-request-code">
                <button type="button" className="change__password__button">
                  Change pin
                </button>
              </Link>
            </div>
          </div>
        </section>

        <div className="change__password__footer">
          <img src={padlock} className="" alt="" />
          <p>
            Your secret password/pin are private to you. Do not share with a
            third party.
          </p>
        </div>
      </section>
    </main>
  );
};

export default SecurityMain;
