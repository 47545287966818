import React from "react";
import AirtimeAccordion from "./Accordion/AirtimeAccordion";
import DataAccordion from "./Accordion/DataAccordion";
import ElectricAccordion from "./Accordion/ElectricAccordion";
import TvAccordion from "./Accordion/TvAccordion";
import TollAccordion from "./Accordion/TollAccordion";
import dropdown from "../../asset/Group 162893.svg";
// import dropdown from "../../../asset/Group 163431.svg";
import airtime from "../../asset/airtime.svg";
import subscription from "../../asset/subscription.svg";
import electricity from "../../asset/electricity.svg";
import tv from "../../asset/tv_bills.svg";
import toll from "../../asset/toll_gate.svg";


function BillsTab({ logo, content, icon, text }) {
  return (
    <div className="container ">
      <section className="container">
        <div className="accordion">
          <AirtimeAccordion
            logo={airtime}
            icon={dropdown}
            text="Airtime"
          />
          <DataAccordion
            logo={subscription}
            icon={dropdown}
            text="Data"
          />
           <ElectricAccordion
            logo={electricity}
            icon={dropdown}
            text="Electricity Bills"
          />
           <TvAccordion
            logo={tv}
            icon={dropdown}
            text="Tv Bills"
          />
           <TollAccordion
            logo={toll}
            icon={dropdown}
            text="Toll-Gate Fees"
          />
          
        </div>
      </section>
    </div>
  );
}
export default BillsTab;
