import { DATA_LIST } from "../constants/action-types";

const initialState = {
    dataList: [],
  
}

export default function billsReducer(state = initialState, action) {
    const {type, payload} = action;

    switch(type) {
        case DATA_LIST:
            return {
                ...state,
                dataList: payload
            }
        default:
            return state;
    }
}