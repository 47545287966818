import React, {useEffect} from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import MainHeader from "../../../layouts/header/components/MainHeader";
import useGlobalContext from "../../../custom-hook/Context";
// import ikoyi from "../../../asset/ikoyi.png";
import "./../styles/toll-details.css";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";


  const TollDetails = ({ getProfile, profile }) => {
    const { checkAuth, smallDevice  } = useGlobalContext();
    const userCountry = JSON.parse(localStorage.getItem("userCountry"));
    const kenyaWallet = JSON.parse(localStorage.getItem("kenyaWallet"));
    const ghanaWallet = JSON.parse(localStorage.getItem("ghanaWallet"));

    useEffect(() => {
      checkAuth();
    }, [checkAuth]);
    useEffect(() => {
      getProfile();
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  // const { formdata, setFormdata } = useGlobalContext();

  // const handleValueChange = e => {
  //   const { name, value } = e.target;
  //   setFormdata({ ...formdata, [name]: value });
  // };

 const { id } = useParams();
  const Location = useLocation();

  const backTrackBtn = smallDevice ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.18"
      height="8.39"
      viewBox="0 0 5.18 8.39"
    >
      <path
        fill="#1b1c1d"
        d="m5.18 7.4-3.2-3.2L5.18.99 4.2 0 0 4.2l4.2 4.19Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.16"
      height="11.6"
      viewBox="0 0 7.16 11.6"
    >
      <path
        fill="#1b1c1d"
        d="M7.16 10.24 2.74 5.8l4.42-4.44L5.8 0 0 5.8l5.8 5.8Z"
      />
    </svg>
  );
  return (
    <div>
      <MainHeader
        text="Toll subscription"
        backTrackBtn={backTrackBtn}
        buttonStyle={{
          alignSelf: "flex-start",
          width: "52px",
          height: "52px",
          backgroundColor: "#FDFDFD",
          border: "1px solid #1b1b1b",
          borderRadius: "50%",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />

      <div className="airtime__details__section__main">
        <div className="toll__details__section">
          <div className="toll__details__section__image">
          <img src={Location.state?.icon} alt="" className="" />
          </div>
          <div className="wallet__balance__component">
            <p className="wallet__balance">Wallet Balance</p>
            {userCountry === "nigeria" && (
              <p className="wallet__balance__amount">
                ₦ {profile.profile.wallet}.00
              </p>
            )}
              {userCountry === "kenya" && (
              <p className="wallet__balance__amount">
                K {kenyaWallet}.00
              </p>
            )}
            {userCountry === "ghana" && (
              <p className="wallet__balance__amount">
                ₵ {ghanaWallet}.00
              </p>
            )}
          </div>
        </div>

        <div className="toll__details__section__form">
          <div className="toll__details__section__form-group">
            <div className="label-wrapper">
              <label
                htmlFor="accountlabel"
                className="toll__details__section__account-label"
              >
                LCC Account Number
              </label>
            </div>
            <input
              type="number"
              name="phonenumber"
              className="toll__details__section__input"
              pattern="[0-9]*"
              required
            />
          </div>

          <div className="toll__details__section__form-group">
            <div className="label-wrapper">
              <label
                htmlFor="accountlabel"
                className="toll__details__section__account-label"
              >
                Amount
              </label>
            </div>
            <input
              type="number"
              name="amount"
              className="toll__details__section__input"
              placeholder="₦0.00"
              pattern="[0-9]*"
              required
            />
          </div>

          <div className="toll__details__section__button__section">
            <Link to={`/toll-details/${id}/pin`}>
              <button type="button" className="toll__details__section__button">
                Toll Payment
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

TollDetails.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(TollDetails);
