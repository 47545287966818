import React, { useEffect } from "react";
import WalletBalance from "./WalletBalance";
import QuickSeller from "./QuickSeller";
import GiftBox from "./GiftBox";
import LeaderBoard from "./LeaderBoard";
// import TradeVolume from "./TradeVolume";
import HottestRate from "./HottestRate";
// import PendingTrade from "../pending/components/PendingTrade";
import MainHeader from "../../layouts/header/components/MainHeader";
// import HomeMain from "../../content/component/HomeMain";
import axios from "../../axios";

//Styles
import "../styles/home-main.css";
import useGlobalContext from "../../custom-hook/Context";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../js/actions/profile";
import SetPin from "../../pages/SetPin";

const HomeMain = ({ getProfile, profile }) => {
  const { smallDevice } = useGlobalContext();

  const pinStatus = profile.profile.hasPin;
  const username = JSON.parse(localStorage.getItem("username"));
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));

  const getCurrencyRates = () => {
    axios
      .get("/rd/v1/general/rates")
      .then(response => {
        if (userCountry === "ghana") {
          localStorage.setItem(
            "currencyRate",
            JSON.stringify(response.data.data.ghana)
          );
        } else if (userCountry === "kenya") {
          localStorage.setItem(
            "currencyRate",
            JSON.stringify(response.data.data.kenya)
          );
        } else {
          localStorage.setItem("currencyRate", JSON.stringify(1));
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getCurrencyRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!username) {
    window.location.href = "/signin";
  }
  return pinStatus === false ? (
    <SetPin />
  ) : (
    <main className="main">
      <MainHeader text={`Hi ${username}!`} />
      <div className="main-content custom-row">
        <div className="column col-2">
          <WalletBalance />
          {userCountry === "nigeria" && <QuickSeller />}
        </div>
        <div className="column col-2">
          <HottestRate />
          {smallDevice && <LeaderBoard />}
          <GiftBox />
          {/* <TradeVolume /> */}
        </div>
        <div
          className="column col-2"
          style={{ minHeight: smallDevice ? "0vh" : "50vh" }}
        >
          {!smallDevice && <LeaderBoard />}
          {/* <PendingTrade /> */}
        </div>
      </div>
    </main>
  );
};

HomeMain.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(HomeMain);
