import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TabsSelect from "../../../../custom-hook/TabsSelect";
import "./BeneficiaryDetailsTab.css";
import Toggle from "../../../../ui/toggle/Toggle";
import NoBeneficiaryAnimation from "../../../component/NoBeneficiaryAnimation";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../../js/actions/profile";
import { getSavedBankList } from "../../../../js/actions/bankdetails";
import { getBankList } from "../../../../js/actions/bankdetails";
import axios from "../../../../axios";
import Swal from "sweetalert2";
import { getSavedBeneficiaries } from "../../../../js/actions/bankdetails";

const BeneficiaryDetailsTab = ({
  getProfile,
  profile,
  getSavedBankList,
  allSavedBankList,
  bankList,
  getBankList,
  getSavedBeneficiaries,
  savedBeneficiaries,
}) => {
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  const currencyRate = Number(JSON.parse(localStorage.getItem("currencyRate")));
  const kenyaWallet = JSON.parse(localStorage.getItem("kenyaWallet"));
  const ghanaWallet = JSON.parse(localStorage.getItem("ghanaWallet"));
  useEffect(() => {
    getProfile();
    getSavedBankList();
    getBankList();
    getSavedBeneficiaries();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const { id } = useParams();

  const Location = useLocation();
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = index => {
    setToggleState(index);
  };

  const [value, setValue] = useState(false);
  const [accountNumber, setAccountNumber] = useState(null);
  const [amount, setAmount] = useState(null);
  const [bankDetails, setBankDetails] = useState("");
  const [bankName, setBankName] = useState("");
  const [pin, setPin] = useState(null);
  const [nuBan, setNuban] = useState(null);
  const [bankCode, setBankCode] = useState(null);

  useEffect(() => {
    setNuban(Location.state?.nuban);
    setBankCode(Location.state?.bankcode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccountNumber = e => {
    let accountNumber = e.target.value;
    setAccountNumber(accountNumber);
  };

  const getAmount = e => {
    let amount = e.target.value;
    setAmount(amount);
  };

  const getPin = e => {
    let auth = e.target.value;
    setPin(auth);
  };

  const minimum = 1000;
  const maximum = 500000;
  // console.log(value, "pri")
  // const [isActive, setIsActive] = useState(false);

  const handleValueChange = e => {
    const { value } = e.target;
    const code = bankList.find(item => item.name === value).bankcode;
    const selectBankName = bankList.find(item => item.name === value).name;
    // console.log("princess is here", data);
    // console.log("princess is here", selectBankName);
    setBankName(selectBankName);
    setBankDetails(code);
  };

  const validate = e => {
    if (["e", ".", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleProceed = () => {
    let formBody = {
      nuban: nuBan,
      bankcode: bankCode,
      amount: amount,
      auth: Number(pin),
      bankname: bankName,
      save: false,
      type: "transfer",
    };
    axios
      .post("/rd/v1/payment/transfer", formBody)
      .then(response => {
        // console.log(response.data.message);
        Swal.fire({
          icon: "success",
          text: `${response.data.message}`,
        }).then(function () {
          window.location.href = "/dashboard";
        });
      })
      .catch(e => {
        // console.log(e);
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };

  const handleInput = () => {
    // console.log(amount, accountNumber, bankDetails, pin);
    let formBody = {
      nuban: accountNumber,
      bankcode: bankDetails,
      amount: amount,
      auth: Number(pin),
      bankname: bankName,
      save: value,
      type: "transfer",
    };

    // console.log(formBody)
    axios
      .post("/rd/v1/payment/transfer", formBody)
      .then(response => {
        // console.log(response.data.message);
        Swal.fire({
          icon: "success",
          // title: 'Data bought successfully',
          text: `${response.data.message}`,
        }).then(function () {
          window.location.href = "/dashboard";
        });
      })
      .catch(e => {
        // console.log(e);
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };
  return (
    <div className="tranfer__main">
      <div className="">
        <div className="">
          <div className="bloc-tabs">
            <button
              className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(1)}
            >
              Other banks
            </button>
            <button
              className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(2)}
            >
              Saved beneficiaries
            </button>
          </div>
        </div>
      </div>

      <div className="content-tabs">
        <div
          className={toggleState === 1 ? "content  active-content" : "content"}
        >
          <section className="">
            <div className="">
              <div className="wallet__balance__component">
                <p className="wallet__balance">Wallet Balance</p>
                {userCountry === "nigeria" && (
                  <p className="wallet__balance__amount">
                    ₦ {profile.profile.wallet}.00
                  </p>
                )}
                 {userCountry === "kenya" && (
              <p className="wallet__balance__amount">
                K {kenyaWallet}.00
              </p>
            )}
            {userCountry === "ghana" && (
              <p className="wallet__balance__amount">
                ₵ {ghanaWallet}.00
              </p>
            )}
              </div>
            </div>
          </section>
          <section className="">
            <form className="transfer__form">
              <div className="transfer__details__section__form">
                <div className="transfer__details__section__form-group">
                  <div className="label-wrapper">
                    <label
                      htmlFor="accountlabel"
                      className="transfer__details__section__account-label"
                    >
                      Account Number
                    </label>
                  </div>
                  <input
                    type="number"
                    name="phonenumber"
                    className="transfer__details__section__input"
                    onKeyUp={e => getAccountNumber(e)}
                    onKeyDown={e => validate(e)}
                    pattern="[0-9]*"
                    required
                  />
                </div>

                <div className="transfer__details__section__form-group">
                  <div className="label-wrapper">
                    <label
                      htmlFor="accountlabel"
                      className="transfer__details__section__account-label"
                    >
                      Select Bank
                    </label>
                  </div>
                  <TabsSelect
                    name="selectbank"
                    value={bankName}
                    onSelect={handleValueChange}
                    list={bankList}
                  />
                </div>

                <div className="transfer__details__section__form-group">
                  <div className="label-wrapper">
                    <label
                      htmlFor="accountlabel"
                      className="transfer__details__section__account-label princess"
                    >
                      Amount
                      {userCountry === "nigeria" && (
                        <span>
                          (Minimum: ₦{minimum / 1000}k Maximum: ₦
                          {maximum / 1000}k)
                        </span>
                      )}
                      {userCountry === "kenya" && (
                        <span>
                          (Minimum: K{minimum * currencyRate} Maximum: K
                          {Math.round((maximum * currencyRate) / 1000)}k)
                        </span>
                      )}
                      {userCountry === "ghana" && (
                        <span>
                          (Minimum: ₵{minimum * currencyRate} Maximum: ₵
                          {Math.round((maximum * currencyRate) / 1000)}k)
                        </span>
                      )}
                    </label>
                  </div>
                  <input
                    type="number"
                    name="amount"
                    className="transfer__details__section__input"
                    placeholder="0.00"
                    onKeyUp={e => getAmount(e)}
                    onKeyDown={e => validate(e)}
                    pattern="[0-9]*"
                    required
                  />
                </div>

                <div className="transfer__details__section__form-group">
                  <div className="label-wrapper">
                    <label
                      htmlFor="pinlabel"
                      className="transfer__details__section__account-label princess"
                    >
                      Enter Pin
                    </label>
                  </div>
                  <input
                    type="pin"
                    name="pin"
                    className="transfer__details__section__input"
                    onKeyUp={e => getPin(e)}
                    onKeyDown={e => validate(e)}
                    pattern="[0-9]*"
                    maxLength={4}
                    required
                  />
                </div>

                <div className="toggle__section">
                  <Toggle
                    isOn={value}
                    onColor="#6C63FF"
                    handleToggle={() => setValue(!value)}
                  />
                  <p className="toggle__section__text">
                    Add bank to saved beneficiaries
                  </p>
                </div>

                {/* <div className="transfer__form__response">
                  <p className="transfer__form__response__first-text">
                    Account Holder
                  </p>
                  <h5 className="transfer__form__response__second-text">
                    No account found. Enter bank details
                  </h5>
                </div> */}

                <div className="transfer__details__section__button__section">
                  <button
                    type="button"
                    className="transfer__details__section__button"
                    onClick={() => handleInput()}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </form>
          </section>
        </div>

        <div
          className={toggleState === 2 ? "content  active-content" : "content"}
        >
          <section className="">
            {savedBeneficiaries.length > 0 && (
              // <Link to={`/beneficiary-details/${id}/proceed`}>
              <div className="beneficiary__dey__section">
                <div className="beneficiary__dey__section__sub">
                  <section className="">
                    <div className="">
                      <div className="wallet__balance__component">
                        <p className="wallet__balance">Wallet Balance</p>
                        {userCountry === "nigeria" && (
                          <p className="wallet__balance__amount">
                            ₦ {profile.profile.wallet}.00
                          </p>
                        )}
                        {userCountry === "kenya" && (
                          <p className="wallet__balance__amount">
                            K {profile.profile.wallet}.00
                          </p>
                        )}
                        {userCountry === "ghana" && (
                          <p className="wallet__balance__amount">
                            ₵ {profile.profile.wallet}.00
                          </p>
                        )}
                      </div>
                    </div>
                  </section>

                {userCountry === "nigeria" ?   <div className="beneficiary__dey__card">
                    <div className="beneficiary__dey__card__text">
                      <p className="beneficiary__dey__card__text__first_text">
                        {Location.state?.nuban}
                      </p>
                      <p className="beneficiary__dey__card__text__second_text">
                        {Location.state?.bankholder}
                      </p>
                    </div>
                    <div className="beneficiary__dey__card__image">
                      <img
                        src={require(`../../../../asset/${Location.state?.bankcode}.svg`)}
                        alt="bank-icon"
                        className="beneficiary__dey__card__img"
                      />
                    </div>
                  </div> :  
                  // THIS IS FOR INTERNATIONAL 
                  <div className="beneficiary__dey__card">
                    <div className="beneficiary__dey__card__text">
                      <p className="beneficiary__dey__card__text__first_text">
                        {Location.state?.account_number}
                        
                      </p>
                      <p className="beneficiary__dey__card__text__second_text">
                        {Location.state?.customer_name}
                      </p>
                    </div>
                    <div className="beneficiary__dey__card__image">
                      <img
                        src={require(`../../../../asset/${Location.state?.currency_code}.svg`)}
                        alt="bank-icon"
                        className="beneficiary__dey__card__img"
                      />
                    </div>
                  </div>}
                </div>

                <div className="transfer__details__section__form-group">
                  <div className="label-wrapper">
                    <label
                      htmlFor="accountlabel"
                      className="transfer__details__section__account-label princess"
                    >
                      Amount
                      {userCountry === "nigeria" && (
                        <span>
                          (Minimum: ₦{minimum / 1000}k Maximum: ₦
                          {maximum / 1000}k)
                        </span>
                      )}
                      {userCountry === "kenya" && (
                        <span>
                          (Minimum: K{minimum * currencyRate} Maximum: K
                          {Math.round((maximum * currencyRate) / 1000)}k)
                        </span>
                      )}
                      {userCountry === "ghana" && (
                        <span>
                          (Minimum: ₵{minimum * currencyRate} Maximum: ₵
                          {Math.round((maximum * currencyRate) / 1000)}k)
                        </span>
                      )}
                    </label>
                  </div>
                  <input
                    type="number"
                    name="amount"
                    className="transfer__details__section__input"
                    placeholder="0.00"
                    onKeyUp={e => getAmount(e)}
                    onKeyDown={e => validate(e)}
                    pattern="[0-9]*"
                    required
                  />
                </div>

                <div className="transfer__details__section__form-group">
                  <div className="label-wrapper">
                    <label
                      htmlFor="pinlabel"
                      className="transfer__details__section__account-label princess"
                    >
                      Enter Pin
                    </label>
                  </div>
                  <input
                    type="pin"
                    name="pin"
                    className="transfer__details__section__input"
                    onKeyUp={e => getPin(e)}
                    onKeyDown={e => validate(e)}
                    pattern="[0-9]*"
                    maxLength={4}
                    required
                  />
                </div>

                <div className="transfer__details__section__button__section">
                  <button
                    type="button"
                    className="transfer__details__section__button"
                    onClick={() => handleProceed()}
                  >
                    Proceed
                  </button>
                </div>
              </div>

              //   </Link>
            )}
            {savedBeneficiaries && savedBeneficiaries.length < 1 && (
              <div className="no__beneficiary__section">
                <NoBeneficiaryAnimation />
                <div className="no__beneficiary__section__text">
                  <p className="no__beneficiary__section__first_text">
                    No Saved Beneficiaries
                  </p>
                  <p className="no__beneficiary__section__second_text">
                    You can send money faster when you add beneficiaries.
                  </p>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

BeneficiaryDetailsTab.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
  getSavedBankList: PropTypes.func,
  allSavedBankList: PropTypes.array,
  getBankList: PropTypes.func,
  bankList: PropTypes.array,
  savedBeneficiaries: PropTypes.array,
  getSavedBeneficiaries: PropTypes.func,
};

const mapStateToProps = state => ({
  profile: state.profile,
  allSavedBankList: state.bankdetails.allSavedBankList,
  bankList: state.bankdetails.bankList,
  savedBeneficiaries: state.bankdetails.savedBeneficiaries,
});

export default connect(mapStateToProps, {
  getProfile,
  getSavedBankList,
  getBankList,
  getSavedBeneficiaries,
})(BeneficiaryDetailsTab);
