import React from "react";
import useGlobalContext from "../../../custom-hook/Context";
import axios from "../../../axios";
import Swal from "sweetalert2";

const ActionButtons = ({
  selected, 
  cancel,
  close,
  smallDevice, 
  first, 
  setFirst, 
  last, 
  setLast,
  length,
  updateAction
}) => {
  const {selectedAvatarLabel} = useGlobalContext();
  const nextIcon = smallDevice ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="5.2" height="8.4" viewBox="0 0 5.2 8.4">
      <path className="controls-icon" d="m0 7.4 3.2-3.2L0 1l1-1 4.2 4.2L1 8.4Z"/>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="6.3" height="10.3" viewBox="0 0 6.3 10.3">
      <path className="controls-icon" d="m0 9 4-3.9-4-3.9L1.2 0l5.1 5.1-5 5.2Z"/>
    </svg>
  );

  const prevIcon = smallDevice ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="5.2" height="8.4" viewBox="0 0 5.2 8.4">
      <path className="controls-icon" d="M5.2 7.4 2 4.2 5.2 1l-1-1L0 4.2l4.2 4.2Z"/>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="6.3" height="10.3" viewBox="0 0 6.3 10.3">
      <path className="controls-icon" d="M6.3 9 2.4 5.2l4-3.9L5 0 0 5.1l5.1 5.2Z"/>
    </svg>
  );

  const next = () => {
    setFirst( prev => prev + 1 );
    setLast( prev => prev + 1 );
  };

  const prev = () => {
    setFirst( prev => prev - 1 );
    setLast( prev => prev - 1 );
  };
  let token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      auth: `Bearer ${token}`,
    },
  };
  const useAvatar = () => {
    cancel();
    close();
    updateAction("Change picture");

    let formBody = {
     avatar: selectedAvatarLabel,
     token: config.headers.auth,
    };

    axios
      .post("/rd/v1/user/setAvatar", formBody)
      .then(response => {
        Swal.fire({
          icon: "success",
          text: `${response.data.message}`,
        }).then(function() {
          window.location.href = '/dashboard';
      })
      })
      .catch(e => {
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  }

  return (
    <section className="btn-container">
      {
        selected ? (
          <div className="confirm-selection">
            <button type="button" onClick={cancel}>
              Cancel
            </button>
            <button type="button" onClick={useAvatar}>
              Use avatar
            </button>
          </div>
        ) : (
          <div className="slide-controls">
            <button 
              type="button" 
              onClick={prev} 
              disabled={first <= 0 ? true : false}
            >
              {prevIcon}
            </button>
            <button 
              type="button" 
              onClick={next} 
              disabled={last === (length - 1) ? true : false}
            >
              {nextIcon}
            </button>
          </div>
        )
      }
    </section>
  )
};

export default ActionButtons;