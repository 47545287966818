import React, { useState, useCallback } from "react";
import useGlobalContext from "../../../custom-hook/Context";
import { addAvatar } from "../../../js/actions/action";
import ActionButtons from "./ActionButtons";

import "../style/avatar-choice.css";

const AvatarChoice = ({avatars, number, updateAvatars}) => {
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(number - 1);
  const [selected, setSelected] = useState(false);

  const { smallDevice, dispatchProfile, closeModal, updateAction, setSelectedAvatarLabel} = useGlobalContext();

  // Each Avatar width by dividing the total container width by number of avatar to display
  const avatarWidth = 100 / number;

  // Select avatar, show selected icon and add to user profile data
  const selectAvatar = useCallback((target, id) => {

    // Updated the checked value of selected avatar
    const updatedAvatars = avatars.map(avatar => {
      if(avatar.id === id) {
        return {...avatar, checked: true};
      }
      return {...avatar, checked: false};
    });

    updateAvatars(updatedAvatars);

    // Update to true if selected
    setSelected(true);

    // Find and Update the value of selected avatar in the profile data
    const selectedAvatar = avatars.find(avatar => avatar.id === id);
    const {label, image} = selectedAvatar;
    setSelectedAvatarLabel(selectedAvatar.label);
    dispatchProfile({type: addAvatar, payload: {label, image}});
  }, [dispatchProfile, avatars, updateAvatars, setSelectedAvatarLabel]);

  // Cancel avatar selection
  const cancelSelection = () => {
    setSelected(false);
  }

  return (
    <section className="avatar-choice">
      <div className="avatar-list">
        {
          avatars.map((avatar, avatarIndex) => {
              const { id, label, image, checked } = avatar;
      
              const {img} = avatar.selected;
              let position = 100;

              if(avatarIndex <= lastIndex) {
                position = (avatarIndex - firstIndex) * avatarWidth;
                if(position < 0) {
                  position = position * (-1);
                }
              }

              if(avatarIndex < firstIndex) {
                position = -100;
              }

            return (
              <div 
                className="avatar-item"
                key={id}
                id={id}
                style={{
                  width: `${smallDevice ? avatarWidth - 6 : avatarWidth - 3}%`,
                  left: `${position}%`
                }} 
                onClick={(e) => selectAvatar(e.currentTarget, id)}
               
              >
              
                <img 
                  className="img-fluid" 
                  style={{
                    borderColor: checked && "#459F45",
                  }} 
                  src={image} 
                  alt="" 
                />
                <p>{label}</p>
                <img 
                  src={img} 
                  className={`ticked ${checked ? "display" : ""}`} 
                  alt="" 
                />
              </div>
            )
          })
        }
        <div className={`avatar-choice-cover ${selected ? "show-cover" : ""}`}></div>
      </div>
      <ActionButtons 
        selected={selected}
        cancel={cancelSelection}
        close={closeModal}
        first={firstIndex}
        setFirst={setFirstIndex}
        last={lastIndex}
        setLast={setLastIndex}
        smallDevice={smallDevice} 
        length={avatars.length}
        updateAction={updateAction}
      />
    </section>
  )
};

export default AvatarChoice;