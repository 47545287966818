import axios from "../../axios";
import { WITHDRAWAL_HISTORY_LIST } from "../constants/action-types";

export const getWithdrawalHistory = () => async dispatch => {
  let token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      auth: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get("/rd/v1/transactions/withdrawal/all", {
      config,
    });

    // console.log(response.data);
    dispatch({
      type: WITHDRAWAL_HISTORY_LIST,
      payload: response.data,
    });

    // console.log(response.data);
  } catch (error) {
    // console.log(error);
  }
};
