import { LOADING_BUTTON } from "../constants/action-types";

const initialState = {
    loading_button: false
}

export default function utilsReducer(state = initialState, action) {
    const {type, payload} = action;

    switch(type) {
        case LOADING_BUTTON:
            return {
                ...state,
                loading_button: payload
            }
        default:
            return state;
    }
}