import axios from '../../axios';
import { GET_LEADERBOARD_MONTLY_TRADERS, GET_MY_LEADERBOARD_DATA } from '../constants/action-types';

export const getLeaderboardMonthlyData = () => async dispatch => {
    try {
        const response  = await axios.get("/rd/v1/transaction/leaderboard/month");
        dispatch({
            type: GET_LEADERBOARD_MONTLY_TRADERS,
            payload: response.data.allUserData
        })
        // console.log( response.data.allUserData);
    } catch (error) {
        // console.log(error)
    }
}

export const getMyLeaderboardData = () => async dispatch => {
    try {
        const response  = await axios.get("/rd/v1/transaction/leaderboard/month");
        dispatch({
            type: GET_MY_LEADERBOARD_DATA,
            payload: response.data.singleUserData
        })
        // console.log( response.data.singleUserData);
    } catch (error) {
        // console.log(error)
    }
}