import React from "react";
import ForgotPasswordMain from "../content/forgot-password/components/ForgotPasswordMain";

const ForgotPassword = () => {
  return (
    <div>
      <ForgotPasswordMain />
    </div>
  );
};

export default ForgotPassword;
