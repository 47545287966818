import React, { useEffect } from "react";
import WalletMain from "../content/component/WalletMain";
import useGlobalContext from "../custom-hook/Context";

const Wallet = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return (
    <div>
      <WalletMain />
    </div>
  );
};

export default Wallet;
