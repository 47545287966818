import React, { useEffect } from "react";
import "../styles/AvatarHeader.css";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";

const AvatarHeader = ({ getProfile, 
  profile}) => {
  useEffect(() => {
    getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="redem-avatar-header">
      <div>
        <h1 className="redem-avatar-header-rank">Redem Avatars</h1>
      </div>
      <div className="card-btn redem-avatar-button">
        <p className="avatar_span_hide redem__avatar__text">
          Trade more to unlock avatars and covers. Make your profile look badass
        </p>
        <button className="btn">
          <Link to="/giftcard">
            <span className="avatar_span_hide">Trade to unlock</span>
          </Link>
        </button>
        <div className="avatar_span">
          <p className="current__level">Current level</p>
          <p className="current__level__text">{profile.profile.level} -  {profile.profile.avatarID}</p>
        </div>
      </div>
    </div>
  );
};


AvatarHeader.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(AvatarHeader);
