import React, { useEffect } from "react";
import useGlobalContext from "../custom-hook/Context";
import FaqsMain from "../content/help/components/FaqsMain";

const HelpFaqs = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return (
    <div>
      <FaqsMain />
    </div>
  );
};

export default HelpFaqs;
