import React from "react";
import { NavLink } from "react-router-dom";
import useGlobalContext from "../../custom-hook/Context";
// import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import "../styles/sub-menu.css"
// import logo from "../../asset/Redem Logo.png";

import homeIcon from "../../asset/house.svg";
import wallet from "../../asset/wallet.svg";
import giftcards from "../../asset/Giftcards.svg";
// import stars from "../../asset/star.svg";
import moneybag from "../../asset/money-bag.svg"
import fire from "../../asset/fire (1) (1).svg";
import calculator from "../../asset/calculator-svgrepo-com.svg";

const SubMenu = () => {
  const {
    // showSubmenu, 
    smallDevice,
    mediumDevice,
    // showSubmenuDropdown, 
    // toggleSubmenuDropdown,
    // closeSubmenu
  } = useGlobalContext();

  // const submenuDropdown = (showSubmenuDropdown && (
  //   <ul className="dropdown_list">
  //     <li className="dropdown_item">
  //       <NavLink to="/all-rates" className="dropdown_link" onClick={closeSubmenu}>All Rates Calculator</NavLink>
  //     </li>
  //     <li className="dropdown_item">
  //       <NavLink to="/hottest-rates" className="dropdown_link" onClick={closeSubmenu}>Hottest Rates</NavLink>
  //     </li>
  //   </ul>
  // ))

  // const dropdownIcon = (
  //   showSubmenuDropdown ? (
  //     <svg xmlns="http://www.w3.org/2000/svg" width="9.72" height="6" viewBox="0 0 9.72 6">
  //       <path fill="#d3d3d3" d="M8.57 6 4.86 2.29 1.14 6 0 4.86 4.86 0l4.86 4.86Z"/>
  //     </svg>
  //   ) : (
  //     <svg xmlns="http://www.w3.org/2000/svg" width="9.72" height="6" viewBox="0 0 9.72 6">
  //       <path fill="#fdfdfd" d="m1.14 0 3.72 3.71L8.58 0l1.14 1.14L4.86 6 0 1.14Z"/>
  //     </svg>
  //   )
  // )

  // const history = useHistory();

  // function logOut() {
  //   localStorage.clear();
  //   history.push("/signin");
  // }

  return (
    <aside className={`submenu-overlay ${smallDevice || mediumDevice ? "show" : ""}`}>
      <div className={`submenu ${smallDevice || mediumDevice ? "show-submenu" : ""}`}>
        {/* <header className="submenu-header">
          <img src={logo} className="img-fluid" alt="Redem Logo" />
        </header> */}
        <ul className="submenu-list">
          {/* <li className="submenu-item">
            <NavLink
              to="/dashboard" 
              exact 
              className="submenu-link" 
              onClick={closeSubmenu}
            >Home</NavLink>
          </li> */}
          <li className="submenu-item">
        <NavLink to="/dashboard" exact className="submenu-link">
        <div className="submenu__image">
          <img src={homeIcon} alt="Home icon" />
          </div>
          <span>Home</span>
        </NavLink>
      </li>
          {/* <li className="submenu-item">
            <NavLink
              to="/wallet" 
              className="submenu-link" 
              onClick={closeSubmenu}
            >Wallet</NavLink>
          </li> */}
             <li className="submenu-item">
        <NavLink to="/wallet" exact className="submenu-link">
        <div className="submenu__image">
          <img src={wallet} alt="Home icon" />
          </div>
          <span>Wallet</span>
        </NavLink>
      </li>
          {/* <li className="submenu-item">
            <NavLink
              to="/giftcard" 
              className="submenu-link" 
              onClick={closeSubmenu}
            >Giftcards</NavLink>
          </li> */}
             <li className="submenu-item">
        <NavLink to="/giftcard" exact className="submenu-link">
        <div className="submenu__image">
          <img src={giftcards} alt="Home icon" />
          </div>
          <span>Giftcards</span>
        </NavLink>
      </li>
          {/* <li className="submenu-item">
            <NavLink
              to="/hottest-rates" 
              className="submenu-link" 
              onClick={closeSubmenu}
            >Hottest Rates</NavLink>
          </li> */}
             <li className="submenu-item">
        <NavLink to="/hottest-rates" exact className="submenu-link">
        <div className="submenu__image">
        <img src={fire} alt="Home icon" />
        </div>
          <span>Hottest rates</span>
        </NavLink>
      </li>
          {/* <li className="submenu-item">
            <NavLink
              to="/all-rates" 
              className="submenu-link" 
              onClick={closeSubmenu}
            >All Rates Calculator</NavLink>
          </li> */}
             <li className="submenu-item">
        <NavLink to="/all-rates" exact className="submenu-link">
        <div className="submenu__image">
          <img src={calculator} alt="Home icon" />
          </div>
          <span>Calculator</span>
        </NavLink>
      </li>
         
     
         
          {/* <li className="submenu-item">
            <NavLink
              to="/transactions" 
              className="submenu-link" 
              onClick={closeSubmenu}
            >Transaction</NavLink>
          </li> */}
             <li className="submenu-item">
        <NavLink to="/transactions" exact className="submenu-link">
        <div className="submenu__image">
          <img src={moneybag} alt="Home icon" />
          </div>
          <span>Transactions</span>
        </NavLink>

      </li>

    
       
        </ul>
      </div>
    </aside>
  )
}
//  {/* <li className="submenu-item">
//             <div className="dropdown-toggler" onClick={toggleSubmenuDropdown}>
//               <p style={{marginRight: "0.5rem"}}>Rates</p>
//               {dropdownIcon}
//             </div>
//             <ReactCSSTransitionGroup 
//               transitionName="dropdown-menu"
//               transitionAppear={true}
//               transitionEnterTimeout={500}
//               transitionAppearTimeout={500}
//               transitionLeaveTimeout={300}
//             >
//               {submenuDropdown}
//             </ReactCSSTransitionGroup>
//           </li> */}
export default SubMenu;
