export const LOGIN_SUCCESS ="LOGIN_SUCCESS";
export const SIGNUP_SUCCESS ="SIGNUP_SUCCESS";
export const GET_PROFILE = "GET_PROFILE";
export const AUTH_STATUS = "AUTH_STATUS";
export const BANK_LIST = "BANK_LIST";
export const ADD_BANK = "ADD_BANK";
export const SAVED_BANK = "SAVED_BANK";
export const VALIDATE_BANK = "VALIDATE_BANK";
export const DATA_LIST = "DATA_LIST";
export const AIRTIME_LIST = "AIRTIME_LIST";
export const WITHDRAWAL_HISTORY_LIST = "WITHDRAWAL_HISTORY_LIST";
export const NOTIFICATION_LIST = "NOTIFICATION_LIST";
export const GIFTCARDS_LIST = "GIFTCARDS_LIST";
export const GET_HOTTEST_RATES = "GET_HOTTEST_RATES";
export const GIFTCARDS_HISTORY = "GIFTCARDS_HISTORY";
export const PENDING_GIFTCARDS_TRADES = "PENDING_GIFTCARDS_TRADES";
export const SAVED_BENEFICIARIES = "SAVED_BENEFICIARIES";
export const GET_LEADERBOARD_MONTLY_TRADERS = "GET_LEADERBOARD_MONTLY_TRADERS";
export const GET_MY_LEADERBOARD_DATA = "GET_MY_LEADERBOARD_DATA";
export const LOADING_BUTTON = "LOADING_BUTTON";
export const INTERNATIONAL_SAVED_BANK = "INTERNATIONAL_SAVED_BANK";
