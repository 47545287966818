import { combineReducers } from "redux";
// import alert from "./alert"
import auth from './auth'
import profile from './profile'
import bankdetails from './bankdetails'
import bills from './bills'
import withdrawal from './withdrawal'
import notifications from './notifications'
import giftcards from './giftcards'
import hottestrates from './hottestrates'
import leaderboard from './leaderboard'
import utils from './utils'
export default combineReducers({
    auth,
    profile,
    bankdetails,
    bills,
    withdrawal,
    notifications,
    giftcards,
    hottestrates,
    leaderboard,
    utils
    // alert
});