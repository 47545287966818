import React, { useState } from "react";
import AvatarImages from "../AvatarImages";
import CoverImages from "../CoverImages";
import "./RedemAvatarsTab.css";

function RedemAvatarsTab() {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = index => {
    setToggleState(index);
  };

  return (
    <div className="avatar__main">
      <div className=" ">
        <div className="">
          <div className="avatar-bloc-tabs">
            <button
              className={toggleState === 1 ? "avatar-tabs active-avatar-tabs" : "avatar-tabs"}
              onClick={() => toggleTab(1)}
            >
              Avatars
            </button>
            <button
              className={toggleState === 2 ? "avatar-tabs active-avatar-tabs" : "avatar-tabs"}
              onClick={() => toggleTab(2)}
            >
              Covers
            </button>
          </div>
        </div>
      </div>

      <div className="content-avatar-tabs">
        <div
          className={toggleState === 1 ? "avatar-content  active-avatar-content" : "avatar-content"}
        >
          <section className="container content__container redem__avatar__top">
            {/* <p className="redem__avatar__top__title">Levels</p> */}
            <AvatarImages />
          </section>
        </div>

        <div
          className={toggleState === 2 ? "avatar-content  active-avatar-content" : "avatar-content"}
        >
          <section className="container content__container">
            <CoverImages />
          </section>
        </div>
      </div>
    </div>
  );
}

export default RedemAvatarsTab;
