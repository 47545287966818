import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import MainHeader from "../../../layouts/header/components/MainHeader";
import "../styles/withdraw-main.css";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";
import { getSavedBankList } from "../../../js/actions/bankdetails";
import useGlobalContext from "../../../custom-hook/Context";

const WithdrawMainDetails = ({
  getProfile,
  profile,
  bank,
  name,
  getSavedBankList,
  allSavedBankList,
}) => {
  const { withdrawalDetails, setWithdrawalDetails, checkAuth } =
    useGlobalContext();
  const currencyRate = Number(JSON.parse(localStorage.getItem("currencyRate")));
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  const kenyaWallet = JSON.parse(localStorage.getItem("kenyaWallet"));
  const ghanaWallet = JSON.parse(localStorage.getItem("ghanaWallet"));

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  const Location = useLocation();
  useEffect(() => {
    getProfile();
    getSavedBankList();
    setWithdrawalDetails({
      ...withdrawalDetails,
      bankcode: Location.state?.bankcode,
      bankholder: Location.state?.bankholder,
      bankname: Location.state?.bankname,
      nuban: Location.state?.nuban,
      currency_code: Location.state?.currency_code,
      customer_name: Location.state?.customer_name,
      network: Location.state?.network,
      account_number: Location.state?.account_number,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAmount = e => {
    let amount = e.target.value;
    setWithdrawalDetails({ ...withdrawalDetails, amount });
  };

  const validate = e => {
    if (["e", ".", "+", "-", "!"].includes(e.key)) {
      e.preventDefault();
    }
  };
  const minimum = 1000;
  const maximum = 500000;

  return (
    <main className="main">
      <MainHeader text="Withdraw" />
      <section className="withdraw__section__main">
        <div className="wallet__balance__component">
          <p className="wallet__balance">Wallet Balance</p>
          {userCountry === "nigeria" && (
            <p className="wallet__balance__amount">
              ₦ {profile.profile.wallet}.00
            </p>
          )}
           {userCountry === "kenya" && (
              <p className="wallet__balance__amount">
                K {kenyaWallet}.00
              </p>
            )}
            {userCountry === "ghana" && (
              <p className="wallet__balance__amount">
                ₵ {ghanaWallet}.00
              </p>
            )}
        </div>

       {userCountry === "nigeria" ?  <div className="withdraw__bank__details__text__section">
          {/* <h4 className="withdraw__bank__details__text">
            Select from added banks
          </h4> */}

          <div className="beneficiary__dey__card">
            <div className="beneficiary__dey__card__text">
              <p className="beneficiary__dey__card__text__first_text">
                {Location.state?.nuban}
              </p>
              <p className="beneficiary__dey__card__text__second_text">
                {Location.state?.bankholder}
              </p>
            </div>
            <div className="beneficiary__dey__card__image">
              <img
                src={require(`../../../asset/${Location.state?.bankcode}.svg`)}
                alt="bank-icon"
                className="beneficiary__dey__card__img"
              />
            </div>
          </div>
        </div>: 
        // THIS PART IS FOR INTERNATIONAL TRANSACTION
        <div className="withdraw__bank__details__text__section">
          {/* <h4 className="withdraw__bank__details__text">
            Select from added banks
          </h4> */}

          <div className="beneficiary__dey__card">
            <div className="beneficiary__dey__card__text">
              <p className="beneficiary__dey__card__text__first_text">
                {Location.state?.account_number}
              </p>
              <p className="beneficiary__dey__card__text__second_text">
                {Location.state?.customer_name}
              </p>
            </div>
            <div className="beneficiary__dey__card__image">
              <img
                src={require(`../../../asset/${Location.state?.currency_code}.svg`)}
                alt="bank-icon"
                className="beneficiary__dey__card__img"
              />
            </div>
          </div>
        </div>}

        <div className="transfer__details__section__form-group">
          <div className="label-wrapper">
            <label
              htmlFor="accountlabel"
              className="transfer__details__section__account-label princess"
            >
              Amount
              {userCountry === "nigeria" && (
                <span>
                  (Minimum: ₦{minimum / 1000}k Maximum: ₦{maximum / 1000}k)
                </span>
              )}
              {userCountry === "kenya" && (
                <span>
                  (Minimum: K{minimum * currencyRate} Maximum: K
                  {Math.round((maximum * currencyRate) / 1000)}k)
                </span>
              )}
              {userCountry === "ghana" && (
                <span>
                  (Minimum: ₵{minimum * currencyRate} Maximum: ₵
                  {Math.round((maximum * currencyRate) / 1000)}k)
                </span>
              )}
            </label>
          </div>
          <input
            type="number"
            name="amount"
            className="transfer__details__section__input"
            placeholder="0.00"
            onKeyUp={e => getAmount(e)}
            onKeyDown={e => validate(e)}
            pattern="[0-9]*"
            required
          />
        </div>

        <Link
          to={{
            pathname: "/withdrawal-summary",
          }}
          className="airtime__transaction__body__link"
        >
          <div className="transfer__details__section__button__section">
            <button
              type="button"
              className="transfer__details__section__button"
            >
              Proceed
            </button>
          </div>
        </Link>
      </section>
    </main>
  );
};

WithdrawMainDetails.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
  getSavedBankList: PropTypes.func,
  allSavedBankList: PropTypes.array,
};

const mapStateToProps = state => ({
  profile: state.profile,
  allSavedBankList: state.bankdetails.allSavedBankList,
});

export default connect(mapStateToProps, { getProfile, getSavedBankList })(
  WithdrawMainDetails
);
