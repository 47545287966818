import React, { useState } from "react";
import "../styles/forgot-password.css";
import Password from "../../component/Password";
import { Link } from "react-router-dom";
import axios from "../../../axios";
import Swal from "sweetalert2";


const ForgotPasswordMain = () => {

  const [firstEmail, setFirstEmail] = useState(null);

  const getEmail = e => {
    let myEmail = e.target.value;
    setFirstEmail(myEmail);
  };

  localStorage.setItem("myEmail", JSON.stringify(firstEmail));
  // let myEmail = JSON.parse(localStorage.getItem('myEmail'))

  const handleInput = () => {
    let formBody = {
      email: firstEmail,
    };
    // console.log(formBody);

    axios
      .post("/rd/v1/auth/password/reset/out/init", formBody)
      .then(response => {
        // console.log(response.data.message);
        Swal.fire({
          icon: "success",
          // title: 'Pin set successfully',
          text: `${response.data.message}`,
        }).then(function() {
          window.location.href = '/forgot-password-validate-email';
      })
      })
      .catch(e => {
        // console.log(e);
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };






  return (
    <section className="">
      {/* <div className="">
        <div className=""> */}
      <div className="child">
        <div className="change__pass__form__icon forgot__password__lottie">
          <Password />
        </div>
        <div className="forgot__password__home">
          <h3 className="forgot__password__heading">Forgot Password</h3>
          <p className="forgot__password__sub__heading">
            Enter your email address to receive a code that will enable you
            reset your password.
          </p>

          <div className="forgot__password__input__container">
            <div className="tv__details__section__form-group forgot__password__input">
              <div className="label-wrapper">
                <label className="forgot__pass__form__label">Enter Email</label>
              </div>
              <input
                type="email"
                name="email"
                className="tv__details__section__input"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                onKeyUp={e => getEmail(e)}
                required
              />
            </div>
            <div className="change__pass__buttons">
              <Link to="/signin">
                <button className="forgot__password__button">
                  Return to SignIn
                </button>
              </Link>
              <button
                type="submit"
                className="change__pass__button"
                onClick={() => handleInput()}
              >
                Send
              </button>
            
            </div>
          </div>
        </div>
      </div>
      {/* </div>
      </div> */}
    </section>
  );
};

export default ForgotPasswordMain;
