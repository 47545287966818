import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";
import "../styles/profile-dropdown.css";

const ProfileDropdown = ({ 
  isVisible,
  hidDropdown,
  profileImg,
  name,
  getProfile, 
  profile
}) => {
  useEffect(() => {
    getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


 
  const optionsArrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
      <g data-name="Group 163348" transform="translate(-302.77 -278.77)">
        <rect width="23" height="23" fill="#f5f5f7" data-name="Rectangle 147710" rx="5" transform="translate(302.77 278.77)"/>
        <path fill="#1b1c1d" d="m312.66 292.23 2.1-2.1-2.1-2.1.65-.65 2.75 2.75-2.75 2.75Z"/>
      </g>
    </svg>
  ); 

  const loginLogoutIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path fill="none" d="M0 0h20v20H0z"/>
      <path fill="red" d="m8.87 9.27 2.76-2.75a.73.73 0 1 0-1.03-1.03l-4 4a.73.73 0 0 0 0 1.03l4 4a.73.73 0 1 0 1.03-1.03l-2.76-2.76h8.06a.73.73 0 0 0 0-1.46Zm1.52-5.81a.73.73 0 1 0 0-1.46H3.47a1.82 1.82 0 0 0-1.82 1.82v12.36A1.82 1.82 0 0 0 3.48 18h6.9a.73.73 0 1 0 0-1.46h-6.9a.37.37 0 0 1-.37-.36V3.82a.37.37 0 0 1 .37-.37Z"/>
    </svg>
  );

  const history = useHistory();

  function logOut() {
    localStorage.clear();
    history.push("/signin");
    window.location.reload(false);
  }

  return (
    <article className={`dropdown-container ${isVisible ? "show-dropdown" : ""}`}>
      <header className="dropdown_header">
        <div className="dropdown-header_top">
          <div>
            <div className="profile_img-wrapper">
              <img src={profile.profile.profile_pics} className="img-fluid" alt="" />
            </div>
            <button type="button" onClick={hidDropdown}>
              <svg xmlns="http://www.w3.org/2000/svg" width="10.11" height="10.11" viewBox="0 0 10.11 10.11">
                <path fill="#fdfdfd" d="M10.11 1.02 9.1 0 5.06 4.04 1.02 0 0 1.02l4.04 4.04L0 9.09l1.02 1.02 4.04-4.04 4.03 4.04 1.02-1.02-4.04-4.03Z"/>
              </svg>
            </button>
          </div>
          <div className="dropdown-profile">
          <p className="user-username">{`${profile.profile.firstname}  ${profile.profile.lastname}`}</p>
            <Link to="/user-details" className="btn">Edit personal details</Link>
          </div>
        </div>
      </header>
      <section className="dropdown_content">
        <div className="user-profile">
          <h4>profile</h4>
          <ul>
          <Link to="/profile/bank-details" >
            <li>
              <span className="user-profile-span">Bank details</span>{optionsArrow}
            </li>
            </Link>
            <Link to="/redem-avatars">
            <li>
              <span className="user-profile-span">Redem avatars</span>{optionsArrow}
            </li>
            </Link>
            <Link to="/leaderboard">
            <li>
              <span className="user-profile-span">Leaderboard</span>{optionsArrow}
            </li>
            </Link>
          </ul>
        </div>
        <div className="user-account">
          <h4>account</h4>
          <ul>
          <Link to="/profile/change-credentials">
            <li>
              <span className="user-profile-span">Change password/pin</span>{optionsArrow}
            </li>
            </Link>
            <Link to="/settings">
            <li>
              <span className="user-profile-span">Settings</span>{optionsArrow}
            </li>
            </Link>
            <Link to="/privacy-policy">
            <li>
              <span className="user-profile-span">Privacy policy</span>{optionsArrow}
            </li>
            </Link>
            <Link to="/help">
            <li>
              <span className="user-profile-span">Help</span>{optionsArrow}
            </li>
            </Link>
          </ul>
        </div>
      </section>
      <section className="dropdown_footer">
        <div>
          <button type="button" className="btn">
            {loginLogoutIcon}
            <span onClick={logOut}>Log out</span>
          
          </button>
        </div>
      </section>
    </article>
  );
}


ProfileDropdown.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(ProfileDropdown);