import React from "react";
import PropTypes from "prop-types";

const TextInput = ({ 
  label, 
  type,
  name, 
  value, 
  onChange, 
  error 
}) => {

  return (
    <div className="form-group">
      <input 
        type={type} 
        name={name}
        id={name} 
        value={value} 
        onChange={onChange}
      />
      <label htmlFor={name} className="profile_input-placeholder">{label}</label>
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired
}

export default TextInput;