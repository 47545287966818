import React, { useEffect } from "react";
import "../styles/leaderboardgiftcardimages.css";
import greenArrowdown from "../../../asset/green-arrow-down.png";
import icon from "../../../asset/trophy-svgrepo-com.svg";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getMyLeaderboardData } from "../../../js/actions/leaderboard";

const MyLeaderBoard = ({ myLeaderboardData, getMyLeaderboardData }) => {
  useEffect(() => {
    getMyLeaderboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section className="my__leaderboard__giftcard__list__main">
      <div className="avatar__list__main__section">
        <ul className="">
          {myLeaderboardData &&
            Object.entries(myLeaderboardData).map(index => {
              return (
                <div
                  className="leaderboard__giftcard__list__section"
                  key={index}
                >
                  <div className="leaderboard__giftcard__list__section__details__image">
                    <img src={myLeaderboardData.profile_pics} alt="" />
                  </div>
                  <div className="leaderboard__giftcard__list__section__details">
                    <div className="leaderboard__giftcard__list__section__details__text">
                      <p className="leaderboard__giftcard__list__section__details__text__name">
                        {myLeaderboardData.username}
                      </p>
                      <div className="leaderboard__list__section__section">
                        <p>{`${myLeaderboardData.points} pts`}</p>
                        <img src={icon} alt="" />
                      </div>
                    </div>
                    <div className="leaderboard__giftcard__level__section">
                      <p className="leaderboard__giftcard__level">
                        {myLeaderboardData.rank}
                      </p>
                      <div className="leaderboard__giftcard__list__section__details__icon">
                        <img src={greenArrowdown} alt="" className="" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </ul>
      </div>
    </section>
  );
};

MyLeaderBoard.propTypes = {
  getMyLeaderboardData: PropTypes.func.isRequired,
  //   myLeaderboardData: PropTypes.array,
};

const mapStateToProps = state => ({
  myLeaderboardData: state.leaderboard.myLeaderboardData,
});

export default connect(mapStateToProps, { getMyLeaderboardData })(
  MyLeaderBoard
);
