import React, {useState, useEffect} from 'react'
import useGlobalContext from "../../../custom-hook/Context";
import MainHeader from "../../../layouts/header/components/MainHeader";
import axios from "../../../axios";
import Swal from "sweetalert2";
import emailBox from "../../../asset/Email.png";
import { useHistory } from "react-router-dom";


const ChangePasswordVerifyCode = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
    let email = JSON.parse(localStorage.getItem('email'))
    const [verifyCode, setVerifyCode] = useState(null);

    const history = useHistory();
  
    const getVerificationCode = e => {
      let verificationCode = e.target.value;
      setVerifyCode(verificationCode);
    };
    localStorage.setItem("verifyInAppCode", JSON.stringify(verifyCode));

    const handleInput = () => {
        history.push("/profile/change-password");
      };

      let token = JSON.parse(localStorage.getItem("token"));
      const config = {
        headers: {
          auth: `Bearer ${token}`,
        },
      };
    
      const handleResend = () => {
        let formBody = {
          token: config.headers.auth,
        };
        // console.log(formBody)
        axios
          .post("/rd/v1/auth/password/reset/in/init", formBody)
          .then(response => {
            // console.log(response.data.message);
            Swal.fire({
              icon: "success",
              // title: 'Pin set successfully',
              text: `${response.data.message}`,
            }).then(function() {
                  window.location.href = '/profile/request-code';
              })
          })
          .catch(e => {
            // console.log(e);
            Swal.fire({
              icon: "error",
              title: "Forbidden",
              text: `${e.response.data.message}`,
            });
          });
      };


  return (
    <main className="main">
    <MainHeader text="Change Password" />
    <div className="feedback__home">
      <section className="change__pass">
      <div className="emailbox">
          <img src={emailBox} alt="email box" />
        </div>
        <div className="forgot__password__home">
          <h3 className="forgot__password__heading">Change Your Password</h3>
          <p className="forgot__password__validate__email__sub__heading">
            We sent a code to <span>{`${email}`}.</span>
            Enter the code to change your password.
          </p>

          <div className="forgot__password__input__container">
            <div className="tv__details__section__form-group forgot__password__input">
              <input
                type="number"
                name="number"
                className="tv__details__section__input"
                pattern="[0-9]*"
                onKeyUp={e => getVerificationCode(e)}
                required
              />
            </div>
            <div className="change__pass__buttons">
              <button
                type="submit"
                className="change__pass__button"
                onClick={() => handleInput()}
              >
                Proceed
              </button>
            </div>

            <p className="forgot__password__sub__heading resend__code">
              Didn't get code? Check your spam folder or <span onClick={() => handleResend()}>Resend</span>
            </p>
          </div>
        </div>
    </section>
    </div>
    </main>
  )
}

export default ChangePasswordVerifyCode