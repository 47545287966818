import React, {useState, useEffect} from "react";
import { AvatarImagesItemData } from "../../data/avatars";
import "../styles/avatar-images.css";
import AvatarImagesItem from "./AvatarImagesItem";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";

const AvatarImages = ({  getProfile, profile,}) => {
  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [selectedAvatarIndex, setSelectedAvatarIndex] = useState(0);

  return (
    <section className="avatar__list__main">
      <div className="avatar__list__main__section">
        <ul className="avatar__list">
          {AvatarImagesItemData.map((data, id) => {
            const { image, levelName, level, status, icon,  avatar } = data;

            return (
              <AvatarImagesItem
                key={id}
                image={image}
                level={level}
                status={status}
                icon={icon}
                levelName={levelName}
                id={id}
                avatar={avatar}
                userLevel={profile && profile.profile.level}
                selectedIndex={selectedAvatarIndex}
                onSelect= {setSelectedAvatarIndex}
              />
            );
          })}
        </ul>
      </div>
    </section>
  );
};


AvatarImages.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(AvatarImages);

