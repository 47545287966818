import React, {useEffect} from "react";
// import { LeaderboardTopTradersItemData } from "../../data/traders";
// import "../../redeem-avatars/styles/avatar-images.css";
import "../styles/leaderboardgiftcardimages.css";
import arrowdown from "../../../asset/red-arrow-down.png";
// import arrowup from "../../../asset/green-arrow-down.png";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getLeaderboardMonthlyData } from "../../../js/actions/leaderboard";
import icon from "../../../asset/trophy-svgrepo-com.svg";

const LeaderboardGiftcardImages = ({ leaderboardMonthlyList, getLeaderboardMonthlyData}) => {
  useEffect(() => {
    getLeaderboardMonthlyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    
    <section className="leaderboard__giftcard__list__main">
      <div className="avatar__list__main__section">
        <ul className="">
          {leaderboardMonthlyList &&  Array.from(leaderboardMonthlyList).map((data, index) => {
            const { profile_pics, username, points, rank } = data;

            return (
              <div className="leaderboard__giftcard__list__section" key={index}>
                <div className="leaderboard__giftcard__list__section__details__image" >
                  <img src={profile_pics} alt="" />
                </div>
                <div className="leaderboard__giftcard__list__section__details">
                  <div className="leaderboard__giftcard__list__section__details__text">
                 <p className="leaderboard__giftcard__list__section__details__text__name">{username}</p>
                   <div className="leaderboard__list__section__section">
                      <p>{`${points} pts`}</p>
                      <img src={icon} alt="" />
                      </div>
                  </div >
                  <div className="leaderboard__giftcard__level__section">
                  <p className="leaderboard__giftcard__level">{rank}</p>
                  <div className="leaderboard__giftcard__list__section__details__icon">
                  <img src={arrowdown} alt="" className="" />
                  </div>
                  </div>
              
                </div>
              </div>
            );
          })}
        </ul>
      </div>
    </section>
  );
};


LeaderboardGiftcardImages.propTypes = {
  getLeaderboardMonthlyData: PropTypes.func.isRequired,
  leaderboardMonthlyList: PropTypes.array,

};

const mapStateToProps = state => ({
  leaderboardMonthlyList: state.leaderboard.leaderboardMonthlyList,

});

export default connect(mapStateToProps, { getLeaderboardMonthlyData })(
  LeaderboardGiftcardImages);
