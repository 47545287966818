/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./signup.css";
import { Link } from "react-router-dom";
import { signup } from "../../js/actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import arrow from "../../asset/ic_keyboard_arrow_down_24px.svg";
import axios from "../../axios";
import logo from "../../asset/Default Redem Logo (New).png";
import Spinner from "../../content/component/Spinner";

const SignUp = ({ signup, loading }) => {
  useEffect(() => {
    let telephone = document.querySelector("input[type=tel]");
    telephone.onkeypress = event =>
      event.charCode >= 48 && event.charCode <= 57;
    telephone.required = true;
  }, []);

  function showPassword() {
    document.querySelector(".toggle-password").classList.toggle("fa-eye-slash");
    document.querySelector(".toggle-password").classList.toggle("fa-eye");
    let x = document.getElementById("password-field");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  const [ref, setRef] = useState("");
  const [catchError, setCatchError] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [isCheckedPromos, setIsCheckedPromos] = useState(true);
  const [isCheckedTerms, setIsCheckedTerms] = useState(true);

  const handleInputChange = e => {
    let referral = e.target.value;
    setRef(referral);
  };
  const verifyReferral = () => {
    let formBody = {
      username: ref,
    };
    axios
      .get(`/rd/v1/referral/user/${ref}`, formBody)
      .then(response => {
        setResponseStatus(response.status);
        setCatchError(response.data.message);
      })
      .catch(error => {
        setResponseStatus(error.response.status);
        setCatchError(error.response.data.message);
      });
  };
  const toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 1000,
  });

  window.toast = toast;

  // function handlePhoneNumber(e) {
  //   if (e.target.value[0] === "0") {
  //     e.target.value = "";
  //   }
  // }

  return (
    <Formik
      initialValues={{
        fname: "",
        lname: "",
        email: "",
        password: "",
        username: "",
        country: "nigeria",
        pnumber: "",
        ref: "",
      }}
      validationSchema={Yup.object({
        fname: Yup.string()
          .matches(/^[A-Za-z]{5}/, "Minimum of 5 letters")
          .required("Required"),

        lname: Yup.string()
          .matches(/^[A-Za-z]{5}/, "Minimum of 5 letters")
          .required("Required"),
        username: Yup.string().required("Required"),
        password: Yup.string()

          // .matches(
          //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
          //   "Minimum 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number"
          // )
          .required("Required")
          .typeError("try this"),
        email: Yup.string().email("Invalid email address").required("Required"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        let telephone = document.querySelector("input[type=tel]");
        let telephone_value = telephone.value.replace(/ /g, "");
      

        if (telephone_value.length < 9 || telephone_value.length > 15) {
          alert("phone number is incomplete");
          return;
        }

        values.pnumber = telephone_value;
        const {
          email,
          password,
          fname,
          lname,
          username,
          country,
          pnumber,
          ref,
        } = values;

        signup({
          email,
          password,
          fname,
          lname,
          username,
          country,
          pnumber,
          ref,
          isCheckedPromos,
          isCheckedTerms,
        })
          .then(res => {
            if (res) {
              return;
            }
          })
          .catch(e => {});
      }}
    >
      <section className="">
        <div className="">
          <div className="">
            <div className="child">
              <a
                href="https://getredem.io/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="redirect__to__landing__page">
                  <img src={logo} alt="" />
                </div>
              </a>
              <h2 className="signup-right--section--h2">Sign Up</h2>
              <p className="signup-right--section--p">
                Welcome, Let's get you signed up
              </p>
              <Form>
                <div className="d-flex justify-content-between align-items-center gap-3">
                  <div className="d-flex justify-content-center flex-column">
                    <div className=" signup-form-container ">
                      <label htmlFor="fname">First Name</label>
                      <Field
                        name="fname"
                        type="text"
                        className="form-control signup-form-control"
                        required="required"
                        placeholder="First Name"
                        id="fname"
                        // onChange={e => handleInputChange(e)}
                      />
                    </div>
                    <ErrorMessage
                      name="fname"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="d-flex justify-content-center flex-column">
                    <div className="signup-form-container">
                      <label htmlFor="lname">Last Name</label>
                      <Field
                        name="lname"
                        type="text"
                        className="form-control signup-form-control"
                        placeholder="Last Name"
                        required="required"
                        // onChange={e => handleInputChange(e)}
                      />
                    </div>

                    <ErrorMessage
                      name="lname"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div
                  className="signup-form-container signup-input-form-container d-flex"
                  id="phone"
                >

                  {/* <div className=""> */}
                  <Field
                    as="select"
                    className="form-control select-form-control"
                    name="country"
                  >
                    <option value="nigeria">+234</option>
                    <option value="ghana">+233</option>
                    <option value="kenya">+254</option>
                  </Field>
                  <div className="arrow-down">
                    <img src={arrow} alt="arrow down" />
                  </div>

                  {/* </div> */}

                  <label htmlFor="pnumber">Phone Number</label>
                  <input
                    name="pnumber"
                    type="tel"
                    className="form-control signup-form-control telephony"
                    placeholder="Phone Number"
                    required="required"
                    // onInput={e => handlePhoneNumber(e)}
                  />
                </div>
                <div className="signup-form-container">
                  <label htmlFor="email">Email Address</label>
                  <Field
                    name="email"
                    type="email"
                    className="form-control signup-form-control"
                    placeholder="Email"
                    required="required"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="signup-form-container">
                  <label htmlFor="username">Username</label>
                  <Field
                    name="username"
                    type="text"
                    className="form-control signup-form-control "
                    placeholder="Username"
                    required="required"
                    // onChange={e => handleInputChange(e)}
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="signup-form-container">
                  <label htmlFor="password">Password</label>
                  <Field
                    name="password"
                    type="password"
                    className="form-control signup-form-control"
                    id="password-field"
                    required="required"
                    placeholder="Password"
                    // onChange={e => handleInputChange(e)}
                  />
                  <span
                    toggle="#password-field"
                    className="fa fa-eye-slash field-con toggle-password"
                    onClick={showPassword}
                  ></span>
                </div>
                <div className="signup-form-container">
                  <ErrorMessage
                    name="password"
                    component="div"
                    className=" errorMessage error"
                  />
                </div>
                <div className="signup-form-container">
                  <label htmlFor="referral">Referrer name (optional)</label>
                  <Field
                    name="ref"
                    type="text"
                    // value={ref || ''}
                    className="form-control signup-form-control referral"
                    placeholder="Referrer name (optional)"
                    onKeyUp={e => handleInputChange(e)}
                  />
                  {ref && (
                    <button
                      className="referral__button"
                      onClick={verifyReferral}
                    >
                      verify
                    </button>
                  )}
                </div>

                {responseStatus && (
                  <div className="response__status">
                    <p
                      style={{
                        backgroundColor: `${
                          responseStatus === 200 ? "green" : "red"
                        }`,
                        borderRadius: "5px",
                      }}
                    >
                      {catchError}
                    </p>
                  </div>
                )}

                <div className=" d-flex justify-content-between align-items-center text-center">
                  <div className="checker">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={isCheckedTerms}
                        id="flexCheckDefault"
                        required="required"
                        checked={isCheckedTerms}
                        onChange={() => setIsCheckedTerms(!isCheckedTerms)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                        style={{textAlign: "left"}}
                      >
                        I agree to the
                        <a
                          href="https://getredem.io/termsandconditions"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>terms and conditions</span>
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
                <div className=" d-flex justify-content-between align-items-center text-center">
                  <div className="checker">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={isCheckedPromos}
                        id="flexCheckDefault"
                        checked={isCheckedPromos}
                        onChange={() => setIsCheckedPromos(!isCheckedPromos)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                        style={{textAlign: "left"}}
                      >
                        Inform me about Giveaways and Promotions
                      </label>
                    </div>
                  </div>
                </div>
                <div className=" justify-content-center align-items-center text-center">
                  {!loading && (
                    <button type="submit" className="sign--up--button">
                      Sign up
                    </button>
                  )}
                  {loading && (
                    <button type="submit" className="signup__loading__button">
                      <Spinner />
                    </button>
                  )}
                  <div className="d-flex flex-column justify-content-center align-items-center text-center bottom">
                    <h4 className="already">
                      I already have an account, let me
                    </h4>
                    <Link to="/signin" className="bottom-link">
                      Signin
                    </Link>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </Formik>
  );
};

const mapStateToProps = state => ({
  loading: state.utils.loading_button,
});

SignUp.propTypes = {
  signup: PropTypes.func,
  loading: PropTypes.bool,
};
export default connect(mapStateToProps, { signup })(SignUp);
