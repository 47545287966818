import React, { useEffect } from "react";
import useGlobalContext from "../../../custom-hook/Context";
import MainHeader from "../../../layouts/header/components/MainHeader";
import emailBox from "../../../asset/Email.png";
import axios from "../../../axios";
import Swal from "sweetalert2";


const ChangePasswordRequestCode = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  let token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      auth: `Bearer ${token}`,
    },
  };

  const handleInput = () => {
    let formBody = {
      token: config.headers.auth,
    };
    // console.log(formBody)
    axios
      .post("/rd/v1/auth/password/reset/in/init", formBody)
      .then(response => {
        // console.log(response.data.message);
        Swal.fire({
          icon: "success",
          // title: 'Pin set successfully',
          text: `${response.data.message}`,
        }).then(function() {
              window.location.href = '/profile/verify-code';
          })
      })
      .catch(e => {
        // console.log(e);
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };
  return (
    <main className="main">
      <MainHeader text="Change Password" />
      <section className="change__pass">
        <div className="emailbox">
          <img src={emailBox} alt="email box" />
        </div>
        <div className="forgot__password__home">
          <h3 className="forgot__password__heading">Change Your Password</h3>
          <p className="forgot__password__validate__email__sub__heading">
            Click the button below to request a verification code in your email
            to reset your password.
          </p>

          <div className="forgot__password__input__container">
            <div className="change__pass__buttons">
              <button
                type="submit"
                className="change__pass__button"
                onClick={() => handleInput()}
              >
                Request code
              </button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ChangePasswordRequestCode;
