// import steam from "../../asset/steam-giftcard.png";
// import amazon from "../../asset/amazon-card.png";
// import itunes from "../../asset/itunes-card.png";
// import macy from "../../asset/macy-card.png";
// import nordstrom from "../../asset/nordstrom-card.png";
// import xbox from "../../asset/xbox-card.png";
// import ebay from "../../asset/ebay-card.png";
// import googlePlayCard from "../../asset/google-play-card.png";
//import amazon from "../../asset/amazon card@2x.png";
import amazon_icon from "../../asset/amazon-icon-1.svg";
import steam_icon from "../../asset/steam-1.svg";
import apple_icon from "../../asset/apple-13.svg";
// import xbox_icon from "../../asset/xbox-1.svg";
import ebay_icon from "../../asset/ebay.svg";
import macy_icon from "../../asset/macys-logo-1.svg";
import footerlocker_icon from "../../asset/Web-Icons/Footlocker.svg";
// import bestbuy_icon from "../../asset/Web-Icons/Bestbuy.svg";
// import gamestop_icon from "../../asset/Web-Icons/Gamestop.svg";
import jcpenney_icon from "../../asset/Web-Icons/JCPenney.svg";
import nike_icon from "../../asset/Web-Icons/Nike.svg";
import nordstrom_icon from "../../asset/Web-Icons/Nordstrom.svg";
// import samclub_icon from "../../asset/Web-Icons/SamClub.svg";
import sephora_icon from "../../asset/Web-Icons/Sephora.svg";
import target_icon from "../../asset/Web-Icons/Target.svg";
import visa_icon from "../../asset/Web-Icons/Visa.svg";
import walmart_icon from "../../asset/Web-Icons/Walmart.svg";



export const cardList = [
  {
    id: 1,
    name: "Amazon",
    icon: amazon_icon,
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1641820310/amazon_card_epnq6q.png",
    bgcolor: "#EAAC11",
    sales: 20,
    rated: 1
  },
  {
    id: 2,
    name: "Itunes",
    icon: apple_icon,
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1641820449/itunes_card_zejr7q.png",
    bgcolor: "#F30E7E",
    sales: 50,
    rated: 2
  },
  {
    id: 3,
    name: "Steam",
    icon: steam_icon,
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1641820531/steam_giftcard_y1kjal.png",
    bgcolor: "#092648",
    sales: 1,
    rated: 3
  },
  {
    id: 4,
    name: "Ebay",
    icon: ebay_icon,
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1641820646/ebay_card_tfxrv7.png",
    bgcolor: "#0975FF",
    sales: 10,
    rated: 4
  },
  // {
  //   id: 5,
  //   name: "XBOX",
  //   icon: xbox_icon,
  //   image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1648470296/xbox_card_gri1qh.png",
  //   bgcolor: "#459F45",
  //   sales: 2,
  //   rated: 5
  // },
 {
  id: 6,
  name: "GooglePlay",
  icon: apple_icon,
  image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1641825650/google_play_card_ij8qks.png",
  bgcolor: "#1F88BF",
  sales: 100,
  rated: 6
},
{
  id: 7,
  name: "Nordstrom ",
  icon: nordstrom_icon,
  image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1641825624/nordstrom_card_odmc6l.png",
  bgcolor: "#F30E7E",
  sales: 10,
  rated: 5
},
  {
    id: 8,
    name: "Macys",
    icon: macy_icon,
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1641820718/macy_card_bam3v5.png",
    bgcolor: "#092648",
    sales: 40,
    rated: 9
  },
  {
    id: 9,
    name: "Amex Gold",
    icon: amazon_icon,
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1638366768/amex_2x_chbzlu.png",
    bgcolor: "#EAAC11",
    sales: 20,
    rated: 1
  },
  // {
  //   id: 10,
  //   name: "Best Buy",
  //   icon: bestbuy_icon,
  //   image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1638366765/best_buy_2x_djqqaj.png",
  //   bgcolor: "#F30E7E",
  //   sales: 50,
  //   rated: 2
  // },
  {
    id: 11,
    name: "Footlocker",
    icon: footerlocker_icon,
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1638366766/footlocker_2x_k1zeve.png",
    bgcolor: "#092648",
    sales: 1,
    rated: 3
  },
  // {
  //   id: 12,
  //   name: "Game stop",
  //   icon: gamestop_icon,
  //   image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1648460113/gamestop_yb6aag.png",
  //   bgcolor: "#0975FF",
  //   sales: 10,
  //   rated: 4
  // },
 
 {
  id: 14,
  name: "Nike",
  icon: nike_icon,
  image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1648469342/nike_card_j4uq5o.png",
  bgcolor: "#1F88BF",
  sales: 100,
  rated: 6
},
{
  id: 13,
  name: "JcPenny ",
  icon: jcpenney_icon,
  image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1638366763/JcPenny_bnqomk.png",
  bgcolor: "#459F45",
  sales: 2,
  rated: 5
},
{
  id: 17,
  name: "Target",
  icon: target_icon,
  image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1647860826/target_card_oyptie.png",
  bgcolor: "#0975FF",
  sales: 10,
  rated: 4
},
// {
//   id: 15,
//   name: "Samsclub",
//   icon: apple_icon,
//   image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1648460242/sams_club_ijnyf5.png",
//   bgcolor: "#F30E7E",
//   sales: 10,
//   rated: 5
// },
  {
    id: 16,
    name: "Sephora",
    icon: sephora_icon,
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1643108153/sephora_card_riafz7.png",
    bgcolor: "#092648",
    sales: 40,
    rated: 9
  },
 
  {
    id: 18,
    name: "Apple Itunes",
    icon: apple_icon,
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1643287099/usa_apple_store_rcmigm.png",
    bgcolor: "#459F45",
    sales: 2,
    rated: 5
  },
 {
  id: 19,
  name: "Vanilla Visa",
  icon: visa_icon,
  image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1647860827/vanilla_card_uvpgsj.png",
  bgcolor: "#1F88BF",
  sales: 100,
  rated: 6
},
{
  id: 20,
  name: "Visa",
  icon: visa_icon,
  image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1647860826/visa_card_bdq3ka.png",
  bgcolor: "#F30E7E",
  sales: 10,
  rated: 5
},
  {
    id: 21,
    name: "Walmart Visa",
    icon: walmart_icon,
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1648460482/walmart_card_f2mox3.png",
    bgcolor: "#092648",
    sales: 40,
    rated: 9
  },
  {
    id: 22,
    name: "RazerGold",
    icon: walmart_icon,
    image: "https://res.cloudinary.com/dpqxraalv/image/upload/v1653310712/razer_gold_card_d9vgta.png",
    bgcolor: "#F30E7E",
    sales: 30,
    rated: 3
  }
]


export const sortCardsData = [
  {
    id: "a-z",
    value: "Name (A-Z)",
  },
  {
    id: "hot-rates",
    value: "Hot rates",
  },
  {
    id: "most-traded",
    value: "Most traded",
  }
]