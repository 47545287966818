import React, {useEffect} from "react";
import useGlobalContext from "../custom-hook/Context";
import HelpEmailMain from '../content/help/components/HelpEmailMain'

const HelpEmail = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return (
    <div><HelpEmailMain/></div>
  )
}

export default HelpEmail