import React, { useState } from "react";
import MainHeader from "../../../layouts/header/components/MainHeader";
import useGlobalContext from "../../../custom-hook/Context";
import "../styles/withdraw-summary-main.css";
import axios from "../../../axios";
import Swal from "sweetalert2";

const WithdrawSummaryMain = ({ name }) => {
  const { smallDevice, withdrawalDetails } = useGlobalContext();
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  // const currencyRate = Number(JSON.parse(localStorage.getItem("currencyRate")));

  // Svg icon for small and large screen
  const agreedIcon = smallDevice ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.31"
      height="21.66"
      viewBox="0 0 29.31 21.66"
    >
      <g fill="#6c63ff">
        <path d="m10.54 6.94 2.32-2.31-1.55-1.54-7.7 7.71 10.53 10.54a1.1 1.1 0 0 0 1.54-1.54l-1.54-1.55a1.1 1.1 0 1 1 1.54-1.54l1.55 1.54a1.1 1.1 0 0 0 1.54-1.54l-1.54-1.54a1.1 1.1 0 0 1 1.54-1.54l1.54 1.54a1.1 1.1 0 0 0 1.54 0 1.1 1.1 0 0 0 0-1.54l-.51-.52-3.86-3.86-2.3 2.32a3.27 3.27 0 0 1-4.64-4.63ZM9.45 1.87l.32-.33L8.23 0 0 8.23l1.54 1.54Z" />
        <path d="M15.17 5.4 12.1 8.48a1.1 1.1 0 0 0 1.54 1.55l3.09-3.09a1.1 1.1 0 0 1 1.54 0l4.63 4.63.51.51.77.77 1.54-1.54-8.23-8.22ZM27.77 10.29l-8.74-8.74L20.57 0l8.74 8.74z" />
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.29"
      height="30.51"
      viewBox="0 0 41.29 30.51"
    >
      <g fill="#6c63ff">
        <path d="m14.85 9.78 3.26-3.26-2.18-2.17L5.07 15.2l14.85 14.85a1.54 1.54 0 0 0 2.17-2.17l-2.17-2.18a1.54 1.54 0 1 1 2.17-2.17l2.17 2.17a1.54 1.54 0 0 0 2.18-2.17l-2.18-2.17a1.54 1.54 0 0 1 2.18-2.18l2.17 2.18a1.54 1.54 0 0 0 2.17 0 1.54 1.54 0 0 0 0-2.17l-.72-.73-5.43-5.43-3.26 3.26a4.61 4.61 0 0 1-6.52-6.52ZM13.3 2.63l.46-.46L11.58 0 0 11.6l2.17 2.17Z" />
        <path d="m21.37 7.6-4.35 4.35a1.54 1.54 0 0 0 2.18 2.17l4.34-4.34a1.54 1.54 0 0 1 2.18 0l6.52 6.51.72.73 1.08 1.08 2.18-2.17-11.6-11.58ZM39.11 14.49 26.81 2.18 28.96 0l12.31 12.3z" />
      </g>
    </svg>
  );

  const [pin, setPin] = useState(null);

  const getPin = e => {
    let auth = e.target.value;
    setPin(auth);
  };
  const validate = e => {
    if (["e", ".", "+", "-", "!"].includes(e.key)) {
      e.preventDefault();
    }
  };
  const charges = 30.0;

  const handleInput = () => {
    let formBody = {
      nuban: withdrawalDetails.nuban,
      bankcode: withdrawalDetails.bankcode,
      amount: withdrawalDetails.amount,
      auth: Number(pin),
      bankname: withdrawalDetails.bankname,
      save: false,
      type: "withdraw",
    };

    axios
      .post("/rd/v1/payment/transfer", formBody)
      .then(response => {
        Swal.fire({
          icon: "success",

          text: `${response.data.message}`,
        }).then(function () {
          window.location.href = "/dashboard";
        });
      })
      .catch(e => {
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };
  // THIS IS FOR INTERNATIONAL TRANSFER
  const handleInternationalInput = () => {
    let formBody = {
      account_number: withdrawalDetails.account_number,
      amount: withdrawalDetails.amount,
      auth: Number(pin),
      network: withdrawalDetails.network,
      type: "mobile",
    };

    axios
      .post("/rd/v1/intl/payment/transfer", formBody)
      .then(response => {
        Swal.fire({
          icon: "success",
          text: `${response.data.message}`,
        }).then(function () {
          window.location.href = "/dashboard";
        });
      })
      .catch(e => {
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };

  return (
    <main className="main">
      <MainHeader text="Withdraw" />
      {userCountry === "nigeria" ? (
        <section className="withdraw__summary__main">
          <div className="withdraw__summary__main__section">
            <header className="upload_first-header">
              {agreedIcon}
              <h4>Withdrawal summary</h4>
            </header>
            <section className="withdraw-summary">
              <div className="withdraw-summary-content">
                <p>
                  <span>From</span>
                  <span>My Wallet</span>
                </p>
                <p>
                  <span>To</span>
                  <span>{withdrawalDetails.bankholder}</span>
                </p>
                <p>
                  <span>Amount</span>

                  <span> ₦{withdrawalDetails.amount}</span>
                </p>
                <p>
                  <span>Charge</span>
                  <span>{`₦${charges}`}</span>
                </p>
                <p>
                  <span>Total amount</span>

                  <span>
                    {`₦${
                      parseInt(withdrawalDetails.amount) + parseInt(charges)
                    }`}
                  </span>
                </p>
              </div>
              <div className="card-img-wrapper">
                <img
                  src={require(`../../../asset/${withdrawalDetails.bankcode}.svg`)}
                  className=""
                  alt=""
                />
              </div>
            </section>
            <div className="transfer__details__section__form-group">
              <div className="label-wrapper">
                <label
                  htmlFor="pinlabel"
                  className="transfer__details__section__account-label princess"
                >
                  Enter Pin
                </label>
              </div>
              <input
                type="pin"
                name="pin"
                className="transfer__details__section__input"
                onKeyUp={e => getPin(e)}
                onKeyDown={e => validate(e)}
                pattern="[0-9]*"
                maxLength={4}
                required
              />
            </div>
            <div className="transfer__details__section__button__section">
              <button
                type="button"
                className="transfer__details__section__button"
                onClick={() => handleInput()}
              >
                Proceed
              </button>
            </div>
          </div>
        </section>
      ) : (
        // THIS PART IS FOR INTERNATIONAL TRANSACTIONS
        <section className="withdraw__summary__main">
          <div className="withdraw__summary__main__section">
            <header className="upload_first-header">
              {agreedIcon}
              <h4>Withdrawal summary</h4>
            </header>
            <section className="withdraw-summary">
              <div className="withdraw-summary-content">
                <p>
                  <span>From</span>
                  <span>My Wallet</span>
                </p>
                <p>
                  <span>To</span>
                  <span>{withdrawalDetails.customer_name}</span>
                </p>
                <p>
                  <span>Amount</span>
                  {userCountry === "kenya" && (
                    <span> K{withdrawalDetails.amount}</span>
                  )}
                  {userCountry === "ghana" && (
                    <span> ₵{withdrawalDetails.amount}</span>
                  )}
                </p>
                {/* <p>
                <span>Charge</span>
                {userCountry === "kenya" && <span>{`K${charges * currencyRate}`}</span>}
                {userCountry === "ghana" && <span>{`₵${charges * currencyRate}`}</span>}
              </p> */}
                <p>
                  <span>Total amount</span>

                  {userCountry === "kenya" && (
                    <span>{`K${parseInt(withdrawalDetails.amount)}`}</span>
                  )}
                  {userCountry === "ghana" && (
                    <span>{`₵${parseInt(withdrawalDetails.amount)}`}</span>
                  )}
                </p>
              </div>
              <div className="card-img-wrapper">
                <img
                  src={require(`../../../asset/${withdrawalDetails.currency_code}.svg`)}
                  className=""
                  alt=""
                />
              </div>
            </section>
            <div className="transfer__details__section__form-group">
              <div className="label-wrapper">
                <label
                  htmlFor="pinlabel"
                  className="transfer__details__section__account-label princess"
                >
                  Enter Pin
                </label>
              </div>
              <input
                type="pin"
                name="pin"
                className="transfer__details__section__input"
                onKeyUp={e => getPin(e)}
                onKeyDown={e => validate(e)}
                pattern="[0-9]*"
                maxLength={4}
                required
              />
            </div>
            <div className="transfer__details__section__button__section">
              <button
                type="button"
                className="transfer__details__section__button"
                onClick={() => handleInternationalInput()}
              >
                Proceed
              </button>
            </div>
          </div>
        </section>
      )}
    </main>
  );
};

export default WithdrawSummaryMain;
