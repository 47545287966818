import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import MainHeader from "../../../layouts/header/components/MainHeader";
import { cardList } from "../../data/giftcardData";
import useGlobalContext from "../../../custom-hook/Context";
import GiftCardFormDetails from "./GiftCardFormDetails";
import FormProgress from "../../component/FormProgress";
import TradeTerms from "./TradeTerms";
import "../styles/giftcard-details.css";
import user from "../../../asset/user-image x2.png";
import CardUpload from "./CardUpload";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getHottestRates } from "../../../js/actions/hottestrates";

function GiftCardDetails({ getHottestRates, hottestrateslist }) {
  const { checkAuth, mediumDevice, smallDevice, formData } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  useEffect(() => {
    getHottestRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [cards, setCards] = useState(cardList);
  const [card, setCard] = useState(null);
  const [step, setStep] = useState(1);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.hottestrateslist) {
      setCards(hottestrateslist);
    } else {
      setCards(cardList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentCard = cards.find(card => card.id === +id);
    setCard(currentCard);

    formData.card = currentCard;
  }, [id, cards, formData]);

  useEffect(() => {
    if (step <= 0) {
      history.push("/giftcard");
    }

    if (step <= 0 && location?.state?.hottestrateslist) {
      history.push("/hottest-rates");
    }
  });

  const nextStep = () => {
    setStep(prev => prev + 1);
  };

  const prevStep = () => {
    setStep(prev => prev - 1);
  };

  const renderDetails = () => {
    switch (step) {
      case 1:
        return <GiftCardFormDetails card={card} next={nextStep} />;
      case 2:
        return <TradeTerms next={nextStep} data={formData} />;
      case 3:
        return <CardUpload next={nextStep} data={formData} />;
      default:
        return;
    }
  };

  const backTrackBtn = smallDevice ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.18"
      height="8.39"
      viewBox="0 0 5.18 8.39"
    >
      <path
        fill="#1b1c1d"
        d="m5.18 7.4-3.2-3.2L5.18.99 4.2 0 0 4.2l4.2 4.19Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.16"
      height="11.6"
      viewBox="0 0 7.16 11.6"
    >
      <path
        fill="#1b1c1d"
        d="M7.16 10.24 2.74 5.8l4.42-4.44L5.8 0 0 5.8l5.8 5.8Z"
      />
    </svg>
  );

  return (
    <main className="main">
      {mediumDevice && <MainHeader user={user} />}
      <div className="progress-header">
        <button className="progress-btn" onClick={prevStep}>
          {backTrackBtn}
        </button>
        <FormProgress
          step={step}
          first="Giftcard detail"
          second="Trade Terms"
          third="Upload Card/E-code"
        />
      </div>
      <div className="main-content">{renderDetails()}</div>
    </main>
  );
}

GiftCardDetails.propTypes = {
  getHottestRates: PropTypes.func,
  hottestrateslist: PropTypes.array,
};
const mapStateToProps = state => ({
  hottestrateslist: state.hottestrates.hottestrateslist,
});

export default connect(mapStateToProps, { getHottestRates })(GiftCardDetails);
