import React, { useEffect } from "react";
import MainHeader from "../../../layouts/header/components/MainHeader";
import "../styles/notifications.css";
// import { elList } from "./data/notiData";
import { getNotificationsList } from "../../../js/actions/notifications";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Moment from "react-moment";

const NotificationsMain = ({ getNotificationsList, notificationsList }) => {
  useEffect(() => {
    getNotificationsList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <main className="main">
        <MainHeader text="Notifications" />
        <section className="notification__main">
          {notificationsList.map((data, index) => {
            const { title, message, time} = data;

            return (
              <div className="notification__main__section" key={index}>
                <div className="notification__main__section__sub">
                  <p className="notification__main__section__title">{title}</p>
                  <p className="notification__main__section__date"><Moment format="MMMM Do,  YYYY">{time}</Moment></p>
                  
                </div>
                <div>
                  <p className="notification__main__section__body">{message}</p>
                  
                </div>
              </div>
            );
          })}
        </section>
      </main>
    </>
  );
};

NotificationsMain.propTypes = {
  getNotificationsList: PropTypes.func,
  notificationsList: PropTypes.array,
};
const mapStateToProps = state => ({
  notificationsList: state.notifications.notificationsList,
});

export default connect(mapStateToProps, { getNotificationsList })(NotificationsMain);
