import { edit, addAvatar } from "../actions/action";

const profileFormReducer = (state, action) => {

  if(action.type === edit) {
    return { ...state, [action.field]: action.payload }
  }

  if(action.type === addAvatar) {
    return { ...state, avatar: action.payload }
  }

  if(action.type === "REMOVE_PROFILE_AVATAR") {
    return {...state, avatar: {}}
  }

  if(action.type === "SHOW_CAPTURED_IMAGE") {
    return { ...state, avatar: action.payload }
  }

  if (action.type === "UPLOAD_IMAGE") {
    return { ...state, avatar: action.payload }
  }

  return state;
}

export default profileFormReducer;