import React from "react";
import Moment from "react-moment";
import success from "../../../asset/success.svg";
import failed from "../../../asset/danger.svg";
import tooltip from "../../../asset/ic_info_outline_24px.svg";

const SingleTransactionDetails = ({ details, setShowModal }) => {
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  const currencyRate = Number(JSON.parse(localStorage.getItem("currencyRate")));

  const popupHandler = () => {
    setShowModal(true);
  };

  return (
    <article
      className={`single-transaction-detail ${details && "show-detail"}`}
    >
      {details && (
        <section>
          <section className="transaction-period">
            {/* <div className="icon-wrapper">
                <img src={details?.icon} alt={details?.title} />
              </div> */}
            <div className="icon-wrapper">
              <img
                src={require(`../../../asset/giftcardshistory/${details?.category.toLowerCase()}.png`)}
                className=""
                alt={details?.category}
              />
            </div>
            <div className="details-title">
              <p>{details?.category}</p>
              {/* <p>{details?.category}</p> */}
              <p>
                ({details?.category} {details?.cardType})
              </p>
            </div>
            <div className="details-time">
              <p>
                <Moment format="MMM Do">{details?.created_at}</Moment>
              </p>
              <p>
                <Moment format="hh:mm a">{details?.created_at}</Moment>
              </p>
            </div>
          </section>
          <section className="trade-info">
            <h5>Trade info</h5>
            <div>
              <p>
                <span>rate</span>

                {userCountry === "nigeria" && (
                  <span>{`₦${details.rate}/$`}</span>
                )}
                {userCountry === "kenya" && (
                  <span>{`K${details.rate * currencyRate}/$`}</span>
                )}
                {userCountry === "ghana" && (
                  <span>{`₵${details.rate * currencyRate}/$`}</span>
                )}
              </p>
              <p>
                <span>trade amount</span>
                <span>{`$${details?.dollar}`}</span>
              </p>
              <p>
                {userCountry === "nigeria" && <span>naira equivalent</span>}
                {userCountry === "kenya" && <span>K equivalent</span>}
                {userCountry === "ghana" && <span>₵ equivalent</span>}
  
                {userCountry === "nigeria" && (
                  <span>{`₦${details.rate}/$`}</span>
                )}
                {userCountry === "kenya" && (
                  <span>{`K${details.rate * currencyRate}/$`}</span>
                )}
                {userCountry === "ghana" && (
                  <span>{`₵${details.rate * currencyRate}/$`}</span>
                )}
              </p>
            </div>
          </section>
          <section className="feedback">
            <div className="transaction__tooltip__container">
              <h5>Feedback</h5>
              <a
                href="https://intercom.help/redem/en/articles/6000169-wondering-why-your-gift-card-might-get-declined"
                target="_blank"
                rel="noreferrer"
              >
                <div className="transaction__tooltip">
                  <p>Learn why your trade could fail</p>
                  <button
                    type="button"
                    className=""
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Learn why your trade could fail"
                  >
                    <div className="transaction__tooltip__image">
                      <img src={tooltip} alt="" />
                    </div>
                  </button>
                </div>
              </a>
            </div>
            <div className="feedback-container">
              <div className="feedback-info">
                <div className="feedback-info_content">
                  <div className="icon-wrapper" onClick={popupHandler}>
                    {details?.badImages.map((item, reference) => (
                      <img src={item} alt="" key={reference} />
                    ))}
                    {/* <img src={details?.badImages} alt="" /> */}
                  </div>
                </div>
              </div>

              <div
                className="feedback-msg"
                style={{
                  backgroundColor: `${
                    details?.status === "successful"
                      ? "rgb(206, 245, 209)"
                      : "rgb(255, 247, 248)"
                  }`,
                }}
              >
                <img
                  src={details?.status === "successful" ? success : failed}
                  className="img-fluid check "
                  alt=""
                />
                <p
                  style={{
                    color: `${
                      details?.status === "successful" ? "#25bb32" : "#EC1A3F"
                    }`,
                  }}
                >
                  {details?.status === "successful" ? "successful" : "failed"}
                </p>
              </div>
            </div>
            <div>
              <p>{details?.comment}</p>
            </div>
          </section>
        </section>
      )}
    </article>
  );
};

export default SingleTransactionDetails;
