import axios from "../../axios";
// import Platform from "react-platform-js";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./otpAuthentication.css";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { LOGIN_SUCCESS } from "../../js/constants/action-types";

const OtpAuthentication = () => {
  const [otpVerificationCode, setOtpVerificationCode] = useState();
  // const [ip, setIp] = useState();
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(59);

  const dispatch = useDispatch();

  // const getIp = async () => {
  //   // Connect ipapi.co with fetch()
  //   const response = await fetch("https://ipapi.co/json/");
  //   const data = await response.json();
  //   setIp(data.ip);
  // };
  let email = JSON.parse(localStorage.getItem("email"));
  let device_type = JSON.parse(localStorage.getItem("device_type"));
  let device_model = JSON.parse(localStorage.getItem("device_model"));
  let device_id = JSON.parse(localStorage.getItem("device_id"));
  let device_name = JSON.parse(localStorage.getItem("device_name"));
  let ip_address = JSON.parse(localStorage.getItem("ip_address"));

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleInput = () => {
    setLoading(true);
    axios
      .post(
        "/rd/v1/auth/signin/verify",
        {
          email,
          auth_code: otpVerificationCode,
        },
        {
          headers: {
            device_type,
            device_model,
            device_id,
            device_name,
            ip_address,
          },
        }
      )
      .then(response => {
        if (response.data.status) {
          setLoading(false);
          localStorage.setItem(
            "username",
            JSON.stringify(response.data.data.profile.username)
          );
          localStorage.setItem("token", JSON.stringify(response.data.token));
          localStorage.setItem(
            "email",
            JSON.stringify(response.data.data.profile.email)
          );
          localStorage.setItem(
            "userCountry",
            JSON.stringify(response.data.data.profile.country)
          );
          localStorage.setItem(
            "ghanaWallet",
            JSON.stringify(response.data.data.profile.ghs_wallet)
          );
          localStorage.setItem(
            "kenyaWallet",
            JSON.stringify(response.data.data.profile.kes_wallet)
          );

          localStorage.removeItem("device_model");
          localStorage.removeItem("device_id");
          localStorage.removeItem("device_name");
          localStorage.removeItem("device_type");
          localStorage.removeItem("ip_address");
          const toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 1000,
          });
          window.toast = toast;
          toast.fire({
            icon: "success",
            title: "Redirecting you to your dashboard",
          });
          dispatch({
            type: LOGIN_SUCCESS,
            payload: response.data,
          });
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 1000);
        } else {
          Swal.fire({
            icon: "error",
            title: "Forbidden",
            text: `${response.data.message}`,
          });
          setLoading(false);
        }
      })
      .catch(e => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };

  return (
    <div className="otpVerification">
      <div className="otpVerification__heading">
        <h2 className="right--section--h2">New Device or Location?</h2>
        <p className="otpVerification_subText">
          We noticed you're logging from a new device or location. To confirm
          this is you, we've sent an email with an authorization code to &nbsp;
          <span className="authorization_email">{`${email}`}</span>
        </p>
      </div>
      <div className="form-group change__pass__form__input">
        <input
          name="otp"
          id="otp"
          type="text"
          className="change__pass__form__input__input"
          maxLength={6}
          onChange={e => setOtpVerificationCode(e.target.value)}
          required
        />
        {/* <div className=""> */}
        <button
          type="submit"
          className="verify_otp_button"
          onClick={() => handleInput()}
        >
          {loading ? "Verifying" : "Verify"}
        </button>
        {/* </div> */}
        <p>
          Resend code in &nbsp;
          <span style={{ color: "green", fontWeight: "bold" }}>
            00:{counter}
          </span>
        </p>
        {!counter > 0 && <Link to="/signin">Resend OTP</Link>}
      </div>
      <p className="cantFindAuthorization">
        Can't find the authorization code? Please check your spam folder.
      </p>
    </div>
  );
};

export default OtpAuthentication;
