import React, { useEffect, useState } from "react";
import MainHeader from "../../../layouts/header/components/MainHeader";
import AvatarHeader from "./AvatarHeader";
import "../styles/RedeemAvatarsMain.css";
import RedemAvatarsTab from "./Tabs/RedemAvatarsTab";
// import profileImg from "../../../asset/disciple.png";
import useGlobalContext from "../../../custom-hook/Context";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";
import axios from "../../../axios";
import Swal from "sweetalert2";
import Spinner from "../../../content/component/Spinner";

const RedeemAvatarsMain = ({ getProfile, profile }) => {
  const { checkAuth, largeDevice, myRedemAvatarDetails } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  const [style, setStyle] = useState({});
  const [loading, setLoading] = useState(false);




  // setTimeout(() => {
  //   const newStyle = {
  //     content: "",
  //     position: "absolute",
  //     top: "0",
  //     left: "0",
  //     height: "100%",
  //     backgroundColor: "#459f45",
  //     width: `${profile.profile.reward_percentage}%`,
  //   };
  //   setStyle(newStyle);
  // }, 1000);




  useEffect(() => {
    const timer = setTimeout(() => {
      const newStyle = {
        content: "",
        position: "absolute",
        top: "0",
        left: "0",
        height: "100%",
        backgroundColor: "#459f45",
        width: `${profile.profile.reward_percentage}%`,
      };
      setStyle(newStyle);
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  

  useEffect(() => {
    getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      auth: `Bearer ${token}`,
    },
  };
  const updateRedemAvatar = () => {
    let formBody = {
      avatar: myRedemAvatarDetails.avatar,
      token: config.headers.auth,
    };
    // console.log(formBody)
    setLoading(true);

    axios
      .post("/rd/v1/user/setAvatar", formBody)

      .then(response => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: `${response.data.message}`,
        }).then(function () {
          window.location.href = "/dashboard";
        });
      })
      .catch(e => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };

  return (
    <main className="main">
      <MainHeader />
      <AvatarHeader />
      <div className="main-content ">
        <div className="redem__avatar__main">
          <div className="">
            <RedemAvatarsTab />
          </div>
          <div
            className="redem__avatar__avatar__section"
            style={{ display: `${largeDevice ? "none" : "block"}` }}
          >
            <div>
              <div className="avatar_img-wrapper">
                <img
                  src={
                    myRedemAvatarDetails.image || profile.profile.profile_pics
                  }
                  className="img-fluid"
                  alt=""
                />
                <div className="avatar-profile">
                  <p className="user-username">{`${profile.profile.firstname}  ${profile.profile.lastname}`}</p>
                </div>
              </div>
            </div>
            <div className="avatar-profile__section">
              <div className="avatar-profile__section__first">
                <p className="avatar-profile__section__level">Current level</p>
                <p className="avatar-profile__section__level__name">
                  {profile.profile.level} - {profile.profile.avatarID}
                </p>
              </div>
              <div className="avatar-profile__section__second">
                <p className="avatar-profile__section__level">
                  Avatars unlocked
                </p>
                <p className="avatar-profile__section__level__name">-</p>
              </div>

              <div className="next-avatar-rank">
                <p className="avatar-profile__section__level">
                  Next avatar unlocks at
                </p>
                <div className="next-avatar-rank__span">
                  <span style={style}></span>
                </div>

                <p className="avatar-profile__section__level__name">
                  {`${profile.profile.level_amount_left_raw}`} trade
                </p>
              </div>
            </div>
            <div className="avatar-profile__section__button__section">
              {!loading && (
                <button
                  type="button"
                  className="avatar-profile__section__button"
                  onClick={() => updateRedemAvatar()}
                >
                  Apply avatar
                </button>
              )}

              {loading && (
                <button
                  type="button"
                  className="avatar-profile__loading__button"
                >
                  <Spinner />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

RedeemAvatarsMain.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(RedeemAvatarsMain);
