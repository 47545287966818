import React, {useEffect} from "react";
import "../styles/leaderboardheader.css";
// import { Link } from "react-router-dom";
import tooltip from "../../../asset/ic_info_outline_24px.svg";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";

const LeaderBoardHeader = ({ getProfile, 
  profile}) => {
  useEffect(() => {
    getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="redem-avatar-header">
      <div>
        <h1 className="redem-avatar-header-rank">Leaderboard</h1>
      </div>
      <div className="card-btn leaderboard-button">
        <p className="avatar_span_hide redem__avatar__text">
          Learn more about leaderboards
        </p>
        <button
          type="button"
          className="btn "
          data-toggle="tooltip"
          data-placement="top"
          title="Learn more about leaderboards"
        >
          <img src={tooltip} alt=""/>
        </button>
        {/* <button className="btn">
          <Link to="/giftcard">
            <span className="avatar_span_hide">Trade to unlock</span>
          </Link>
        </button> */}
        <div className="avatar_span">
          <p className="current__level">Current level</p>
          <p className="current__level__text">   {profile.profile.level} -  {profile.profile.avatarID}</p>
        </div>
      </div>
    </div>
  );
};


LeaderBoardHeader.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(LeaderBoardHeader);
