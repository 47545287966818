import React, { useEffect } from "react";
import useGlobalContext from "../../../custom-hook/Context";
import MainHeader from "../../../layouts/header/components/MainHeader";
import BeneficiaryDetailsTab from "./Tabs/BeneficiaryDetailsTab";

const BeneficiaryDetails = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return (
    <main className="main">
      <MainHeader text="Transfer" />
      <section className="transfer__section__main">
        <BeneficiaryDetailsTab />
      </section>
    </main>
  );
};

export default BeneficiaryDetails;
