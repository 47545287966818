import React from "react";
import "../styles/cover-images.css";

const CoverImagesItem = ({ image}) => {
  return (
    <div className="cover__list__section">
      <div className="cover__list__section__image">
        <img src={image} alt="" className="cover__list__section__img" />
      </div>
    </div>
  );
};

export default CoverImagesItem;
