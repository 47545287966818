import React, { useEffect } from "react";
import GiftCardMain from "../content/giftcard/components/GiftCardMain";
import useGlobalContext from "../custom-hook/Context";



const Giftcards = (text) => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return (
    <main className="main">
      <GiftCardMain />
    </main>
  )
}

export default Giftcards