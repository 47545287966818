import React, {useEffect} from "react";
import useGlobalContext from "../custom-hook/Context";
import WithdrawMain from '../content/withdraw/components/WithdrawMain'

const Withdraw = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return (
    <><WithdrawMain/></>
  )
}

export default Withdraw