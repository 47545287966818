import axios from '../../axios';
import { GET_PROFILE } from '../constants/action-types';

export const getProfile = () => async dispatch => {
    try {
        const response  = await axios.get("/rd/v1/user/profile");
        dispatch({
            type: GET_PROFILE,
            payload: response.data.data
        })
        // console.log( response.data.data);
    } catch (error) {
        // console.log(error)
    }
}