import React, { useState } from "react";
import "../styles/faqs-tabs.css";
import Accordion from "../components/Accordion";
import {
  accordionData,
  giftcardAccordionData,
  accountsAccordionData,
  walletAccordionData,
  // extrasAccordionData,
} from "../../data/help";

function Tabs() {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = index => {
    setToggleState(index);
  };

  return (
    <div className="">
      <div className=" ">
        <div className="col-sm-12 col-xs-12">
          <div className="faqs__tabs">
            <button
              className={
                toggleState === 1 ? "faqs__tab active-faq-tabs" : "faqs__tab"
              }
              onClick={() => toggleTab(1)}
            >
              All
            </button>
            <button
              className={
                toggleState === 2 ? "faqs__tab active-faq-tabs" : "faqs__tab"
              }
              onClick={() => toggleTab(2)}
            >
              Giftcards
            </button>
            <button
              className={
                toggleState === 3 ? "faqs__tab active-faq-tabs" : "faqs__tab"
              }
              onClick={() => toggleTab(3)}
            >
              Account
            </button>
            <button
              className={
                toggleState === 4 ? "faqs__tab active-faq-tabs" : "faqs__tab"
              }
              onClick={() => toggleTab(4)}
            >
              Transactions
            </button>
            {/* <button
              className={
                toggleState === 5 ? "faqs__tab active-faq-tabs" : "faqs__tab"
              }
              onClick={() => toggleTab(5)}
            >
              Extras
            </button> */}
          </div>
        </div>
      </div>

      <div className="">
        <div
          className={
            toggleState === 1
              ? "faqs__content  active-content"
              : "faqs__content"
          }
        >
          <section className="container">
            <div className="accordion">
              {accordionData.map(({ title, content, index }) => (
                <Accordion key={index} title={title} content={content} />
              ))}
            </div>
          </section>
        </div>

        <div
          className={
            toggleState === 2
              ? "faqs__content  active-content"
              : "faqs__content"
          }
        >
          <section className="container">
            <div className="accordion">
              {giftcardAccordionData.map(({ title, content, index }) => (
                <Accordion key={index} title={title} content={content} />
              ))}
            </div>
          </section>
        </div>

        <div
          className={
            toggleState === 3
              ? "faqs__content  active-content"
              : "faqs__content"
          }
        >
          <section className="container">
            <div className="accordion">
              {accountsAccordionData.map(({ title, content, index }) => (
                <Accordion key={index} title={title} content={content} />
              ))}
            </div>
          </section>
        </div>

        <div
          className={
            toggleState === 4
              ? "faqs__content  active-content"
              : "faqs__content"
          }
        >
          <section className="container">
            <div className="accordion">
              {walletAccordionData.map(({ title, content, index }) => (
                <Accordion key={index} title={title} content={content} />
              ))}
            </div>
          </section>
        </div>

        {/* <div
          className={
            toggleState === 5
              ? "faqs__content  active-content"
              : "faqs__content"
          }
        >
          <section className="container">
            <div className="accordion">
              {extrasAccordionData.map(({ title, content, index }) => (
                <Accordion key={index} title={title} content={content} />
              ))}
            </div>
          </section>
        </div> */}
      </div>
    </div>
  );
}

export default Tabs;
