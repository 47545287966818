import React from "react";
import MainHeader from "../../layouts/header/components/MainHeader";
import BillsTab from "../bills/BillsTab";
import "../styles/airtime-bills.css";

const AirtimeBills = () => {
  return (
    <div>
      <main className="main">
        <MainHeader text="Subscription" />
        <div className="main-content">
          <section className="airtime__data__main">
            <div className="airtime__data__first__section">
              <p className="airtime__data__first__section__text">
                Subscribe or buy airtime from your favourite provider at no
                charge
              </p>
            </div>
            <BillsTab />
          </section>
        </div>
      </main>
    </div>
  );
};

export default AirtimeBills;
