import React from "react";
import "../styles/trade-terms.css";
import pen from "../../../asset/Black Pen.png";
import useGlobalContext from "../../../custom-hook/Context";

const TradeTerms = ({next, data}) => {
  const {formData } = useGlobalContext();

  const {card, type, country} = data;

  // console.log(data)
  // console.log(formData.card.country)
  // console.log(formData.amount)
  // console.log(card)
  // console.log(formData.cardCategory)
  // console.log(formData.cardSubCategory)

  return (
    <div className="trade-terms">
      <header className="terms-header">
        <h4>Important</h4>
      </header>
      <section className="terms-content">
        <p>1. The transaction is for {country?.name.toUpperCase()} {card?.name} {formData.card?.country} {card?.identifier} giftcard with receipt credit trade.</p>
        <p>2. The acceptable denomination for this trade is {formData.cardSubCategory} only.</p>
        <p>3. Ensure to send {type.toLowerCase()} clear pictures of the receipt & giftcard.</p>
        <p>4. Ensure to send receipts before sending the giftcard.</p>
        <p>5. {card?.name || card?.identifier} transaction takes about 2-20mins to process successfully, please exercise patience during the trade.</p>
        <p>Payment will be sent immediately the trade is completed.</p>
      </section>
      <section className="img-wrapper">
        <span className="terms-pen">
          <img src={pen} className="img-fluid" alt="Black pen" />
        </span>
      </section>
      <section className="btn-wrapper">
        <button 
          className="giftcard-btn trade-btn"
          onClick={next}
        >
          proceed
        </button>
      </section>
    </div>
  )
};

export default TradeTerms;