import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import { useParams, Link, useLocation } from "react-router-dom";
import MainHeader from "../../../layouts/header/components/MainHeader";
import useGlobalContext from "../../../custom-hook/Context";
// import { dataList } from "../../data/subscriptionData";
import DataSelect from "../../../custom-hook/DataSelect";
import "./../styles/data-details.css";
import { getDataList } from "../../../js/actions/bills";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "../../../axios";
import { getProfile } from "../../../js/actions/profile";
import Swal from "sweetalert2";

const DataDetails = ({
  getDataList,
  dataList,
  // provider,
  getProfile,
  profile,
}) => {
  // const { id } = useParams();
  const { smallDevice, checkAuth } = useGlobalContext();
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  const kenyaWallet = JSON.parse(localStorage.getItem("kenyaWallet"));
  const ghanaWallet = JSON.parse(localStorage.getItem("ghanaWallet"));

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  useEffect(() => {
    getProfile();
    const provider = window.location.pathname.slice(
      window.location.pathname.lastIndexOf("/") + 1
    );
    getDataList(provider.toLowerCase());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 1000,
  });

  window.toast = toast;

  const validate = e => {
    if (["e", ".", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const [DataDetails, setDataDetails] = useState({ selectData: "", data: {} });
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [pin, setPin] = useState(null);

  const handleValueChange = e => {
    const { value } = e.target;
    const data = dataList.find(item => item.plan_code === value);
    // console.log(DataDetails);
    setDataDetails({ ...DataDetails, data });
  };

  const handleInput = () => {
    let formBody = {
      recipient: phoneNumber,
      network: window.location.pathname
        .slice(window.location.pathname.lastIndexOf("/") + 1)
        .toLowerCase(),
      plan: DataDetails.data.plan_code,
      amount: DataDetails.data.amount,
      auth: Number(pin),
    };

    axios
      .post("/rd/v1/bills/buy/data", formBody)
      .then(response => {
        // console.log(response.data.message);
        Swal.fire({
          icon: "success",
          title: "Data bought successfully",
          text: `${response.data.message}`,
        }).then(function () {
          window.location.href = "/dashboard";
        });
      })
      .catch(e => {
        // console.log(e)
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };
  const Location = useLocation();
  const getPhoneNumber = e => {
    let phoneNumber = e.target.value;
    setPhoneNumber(phoneNumber);
  };

  const getPin = e => {
    let auth = e.target.value;
    setPin(auth);
  };

  const backTrackBtn = smallDevice ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.18"
      height="8.39"
      viewBox="0 0 5.18 8.39"
    >
      <path
        fill="#1b1c1d"
        d="m5.18 7.4-3.2-3.2L5.18.99 4.2 0 0 4.2l4.2 4.19Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.16"
      height="11.6"
      viewBox="0 0 7.16 11.6"
    >
      <path
        fill="#1b1c1d"
        d="M7.16 10.24 2.74 5.8l4.42-4.44L5.8 0 0 5.8l5.8 5.8Z"
      />
    </svg>
  );
  return (
    <div>
      <MainHeader
        text={`Buy ${Location.state?.provider} data`}
        backTrackBtn={backTrackBtn}
        buttonStyle={{
          alignSelf: "flex-start",
          width: "52px",
          height: "52px",
          backgroundColor: "#FDFDFD",
          border: "1px solid #1b1b1b",
          borderRadius: "50%",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />

      <div className="airtime__details__section__main">
        <div className="data__details__section">
          <div className="data__details__section__image">
            <img src={Location.state?.icon} alt="" className="" />
          </div>
          <div className="wallet__balance__component">
            <p className="wallet__balance">Wallet Balance</p>
            {userCountry === "nigeria" && (
              <p className="wallet__balance__amount">
                ₦ {profile.profile.wallet}.00
              </p>
            )}
            {userCountry === "kenya" && (
              <p className="wallet__balance__amount">
                K {kenyaWallet}.00
              </p>
            )}
            {userCountry === "ghana" && (
              <p className="wallet__balance__amount">
                ₵ {ghanaWallet}.00
              </p>
            )}
          </div>
        </div>

        <div className="data__details__section__form">
          <div className="data__details__section__form-group">
            <div className="label-wrapper">
              <label
                htmlFor="accountlabel"
                className="data__details__section__account-label"
              >
                Phone Number
              </label>
            </div>
            <input
              type="number"
              name="phonenumber"
              className="data__details__section__input"
              // value={phoneNumber}
              onKeyUp={e => getPhoneNumber(e)}
              onKeyDown={e => validate(e)}
              pattern="[0-9]*"
              required
            />
          </div>

          <div className="data__details__section__form-group">
            <div className="label-wrapper">
              <label
                htmlFor="accountlabel"
                className="data__details__section__account-label"
              >
                Amount
              </label>
            </div>
            <DataSelect
              name="selectData"
              // label="Select Bank"
              // value={DataDetails.selectData}
              onSelect={handleValueChange}
              list={dataList}
            />
          </div>

          <div className="data__details__section__form-group">
            <div className="label-wrapper">
              <label
                htmlFor="accountlabel"
                className="data__details__section__account-label"
              >
                Plan Price
              </label>
            </div>
            <input
              type="number"
              name="amount"
              className="data__details__section__input"

              value={DataDetails.data.amount}
              onChange={e => DataDetails(e.target.value)}
              disabled
            />
          </div>

          <div className="data__details__section__form-group">
            <div className="label-wrapper">
              <label
                htmlFor="accountlabel"
                className="data__details__section__account-label"
              >
                Enter Pin
              </label>
            </div>
            <input
              type="pin"
              name="pin"
              className="data__details__section__input"
              onKeyUp={e => getPin(e)}
              pattern="[0-9]*"
              onKeyDown={e => validate(e)}
              maxLength={4}
              required
            />
          </div>
          {/* <div className="data__details__section__button__section">
            <Link to={`/data-details/${id}/success`}>
              <button type="button" className="data__details__section__button">
                Enter
              </button>
            </Link>
          </div> */}
          <div className="data__details__section__button__section">
            {/* <Link to=> */}
            <button
              type="button"
              className="data__details__section__button"
              onClick={() => handleInput()}
            >
              Buy data
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

DataDetails.propTypes = {
  getDataList: PropTypes.func,
  dataList: PropTypes.array,
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};
const mapStateToProps = state => ({
  dataList: state.bills.dataList,
  profile: state.profile,
});

export default connect(mapStateToProps, { getDataList, getProfile })(
  DataDetails
);
