import React from "react";
import DashboardIndex from "../content/component/HomeMain";

const Home = () => {

  return (
    <>
      <DashboardIndex />
    </>
  )
}

export default Home;