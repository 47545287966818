import React from "react";
import MainHeader from "../../../layouts/header/components/MainHeader";
import Tabs from "../components/Tabs/Tabs";

import "../styles/transfer-main.css";

const TransferMain = () => {
  return (
    <main className="main">
      <MainHeader text="Transfer" />
      <section className="transfer__section__main">
        <Tabs />
      </section>
    </main>
  );
};

export default TransferMain;
