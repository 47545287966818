import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import MainHeader from "../../layouts/header/components/MainHeader";
import "../styles/bankdetailsmain.css";
import BankIcon from "./Bank-icon";
import { getSavedBankList } from "../../js/actions/bankdetails";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SavedBanks from "../bank-details/SavedBanks";
import { getInternationalSavedBankList } from "../../js/actions/bankdetails";

const BankDetailsMain = ({ getSavedBankList, allSavedBankList, getInternationalSavedBankList, allInternationalSavedBankList}) => {
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  useEffect(() => {
    getSavedBankList();
    getInternationalSavedBankList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
  {userCountry === "nigeria" ?   <main className="main">
      <MainHeader text="Bank details" />

      {allSavedBankList.length > 0 && <SavedBanks />}

      {allSavedBankList.length < 1 && (
        <section className="bank-details">
          <div className="bank-icon">
            <BankIcon />
          </div>
          <h4 className="bank-details__header">No Bank Account Added</h4>
          <p className="bank-details__sub-header">
            Add your bank account to start making withdrawals. You can add more
            than one bank account.
          </p>
          <Link to="/profile/bank-details/add-bank">
            <button type="button" className="bank-details__button">
              Add bank account
            </button>
          </Link>
        </section>
      )}
    </main> :   <main className="main">
      <MainHeader text="Bank details" />

      {allInternationalSavedBankList.length > 0 && <SavedBanks />}

      {allInternationalSavedBankList.length < 1 && (
        <section className="bank-details">
          <div className="bank-icon">
            <BankIcon />
          </div>
          <h4 className="bank-details__header">No Bank Account Added</h4>
          <p className="bank-details__sub-header">
            Add your bank account to start making withdrawals. You can add more
            than one bank account.
          </p>
          <Link to="/profile/bank-details/add-bank">
            <button type="button" className="bank-details__button">
              Add bank account
            </button>
          </Link>
        </section>
      )}
    </main>}
  </>
  );
};



BankDetailsMain.propTypes = {
  getSavedBankList: PropTypes.func,
  allSavedBankList: PropTypes.array,
  getInternationalSavedBankList: PropTypes.func,
  allInternationalSavedBankList: PropTypes.array,
};
const mapStateToProps = state => ({
  allSavedBankList: state.bankdetails.allSavedBankList,
  allInternationalSavedBankList: state.bankdetails.allInternationalSavedBankList,
});

export default connect(mapStateToProps, { getSavedBankList, getInternationalSavedBankList })(BankDetailsMain);