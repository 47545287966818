import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MainHeader from "../../../layouts/header/components/MainHeader";
import useGlobalContext from "../../../custom-hook/Context";
// import ikedc from "../../../asset/ikedc.png";
import { electricList } from "../../data/subscriptionData";
import ElectricSelect from "../../../custom-hook/ElectricSelect";
import "./../styles/electric-details.css";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getProfile } from "../../../js/actions/profile";
import axios from "../../../axios";
import Swal from "sweetalert2";

const ElectricDetails = ({ getProfile, profile }) => {
  const { checkAuth, smallDevice } = useGlobalContext();
  const userCountry = JSON.parse(localStorage.getItem("userCountry"));
  const kenyaWallet = JSON.parse(localStorage.getItem("kenyaWallet"));
  const ghanaWallet = JSON.parse(localStorage.getItem("ghanaWallet"));

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  useEffect(() => {
    getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = e => {
    if (["e", ".", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };
  // const { id } = useParams();
  const Location = useLocation();

  const backTrackBtn = smallDevice ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.18"
      height="8.39"
      viewBox="0 0 5.18 8.39"
    >
      <path
        fill="#1b1c1d"
        d="m5.18 7.4-3.2-3.2L5.18.99 4.2 0 0 4.2l4.2 4.19Z"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.16"
      height="11.6"
      viewBox="0 0 7.16 11.6"
    >
      <path
        fill="#1b1c1d"
        d="M7.16 10.24 2.74 5.8l4.42-4.44L5.8 0 0 5.8l5.8 5.8Z"
      />
    </svg>
  );
  const [meterNumber, setMeterNumber] = useState(null);
  const [electricAmount, setElectricAmount] = useState(null);
  const [meterDetails, setMeterDetails] = useState(null);
  const [pin, setPin] = useState(null);

  const getMeterNumber = e => {
    let meterNumber = e.target.value;
    setMeterNumber(meterNumber);
  };

  const getAmount = e => {
    let amount = e.target.value;
    setElectricAmount(amount);
  };

  const getPin = e => {
    let auth = e.target.value;
    setPin(auth);
  };

  const handleValueChange = e => {
    const { value } = e.target;
    const meter = electricList.find(item => item.name === value).name;
    // console.log(meter);
    setMeterDetails(meter);
  };

  const handleInput = () => {
    let formBody = {
      meter_number: Number(meterNumber),
      service: window.location.pathname
        .slice(window.location.pathname.lastIndexOf("/") + 1)
        .toLowerCase(),
      meter_type: meterDetails,
      amount: Number(electricAmount),
      auth: Number(pin),
    };
    // console.log(formBody)

    axios
      .post("/rd/v1/bills/buy/electricity", formBody)
      .then(response => {
        // console.log(response.data.message);
        Swal.fire({
          icon: "success",
          title: "Data bought successfully",
          text: `${response.data.message}`,
        }).then(function () {
          window.location.href = "/dashboard";
        });
      })
      .catch(e => {
        // console.log(e)
        Swal.fire({
          icon: "error",
          title: "Forbidden",
          text: `${e.response.data.message}`,
        });
      });
  };

  return (
    <div>
      <MainHeader
        text={`Buy ${Location.state?.provider} Electric`}
        backTrackBtn={backTrackBtn}
        buttonStyle={{
          alignSelf: "flex-start",
          width: "52px",
          height: "52px",
          backgroundColor: "#FDFDFD",
          border: "1px solid #1b1b1b",
          borderRadius: "50%",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />

      <div className="airtime__details__section__main">
        <div className="electric__details__section">
          <div className="electric__details__section__image">
            <img src={Location.state?.icon} alt="" className="" />
          </div>
          <div className="wallet__balance__component">
            <p className="wallet__balance">Wallet Balance</p>
            {userCountry === "nigeria" && (
              <p className="wallet__balance__amount">
                ₦ {profile.profile.wallet}.00
              </p>
            )}
          {userCountry === "kenya" && (
              <p className="wallet__balance__amount">
                K {kenyaWallet}.00
              </p>
            )}
            {userCountry === "ghana" && (
              <p className="wallet__balance__amount">
                ₵ {ghanaWallet}.00
              </p>
            )}
          </div>
        </div>

        <div className="electric__details__section__form">
          <div className="electric__details__section__form-group">
            <div className="label-wrapper">
              <label
                htmlFor="accountlabel"
                className="electric__details__section__account-label"
              >
                Meter Number
              </label>
            </div>
            <input
              type="number"
              name="phonenumber"
              className="electric__details__section__input"
              onKeyUp={e => getMeterNumber(e)}
              onKeyDown={e => validate(e)}
              pattern="[0-9]*"
              required
            />
          </div>

          <div className="electric__details__section__form-group">
            <div className="label-wrapper">
              <label
                htmlFor="accountlabel"
                className="electric__details__section__account-label"
              >
                Select Package
              </label>
            </div>
            <ElectricSelect
              name="selectbank"
              onSelect={handleValueChange}
              list={electricList}
            />
          </div>

          <div className="electric__details__section__form-group">
            <div className="label-wrapper">
              <label
                htmlFor="accountlabel"
                className="electric__details__section__account-label"
              >
                Amount
              </label>
            </div>
            <input
              type="number"
              name="amount"
              className="electric__details__section__input"
              onKeyUp={e => getAmount(e)}
              placeholder="₦0.00"
              onKeyDown={e => validate(e)}
              pattern="[0-9]*"
              required
            />
          </div>

          <div className="electric__details__section__form-group">
            <div className="label-wrapper">
              <label
                htmlFor="accountlabel"
                className="electric__details__section__account-label"
              >
                Enter Pin
              </label>
            </div>
            <input
              type="pin"
              name="pin"
              className="electric__details__section__input"
              onKeyUp={e => getPin(e)}
              pattern="[0-9]*"
              onKeyDown={e => validate(e)}
              maxLength={4}
              required
            />
          </div>

          <div className="electric__details__section__button__section">
            {/* <Link to={`/electric-details/${id}/pin`}> */}
            <button
              type="button"
              className="electric__details__section__button"
              onClick={() => handleInput()}
            >
              Proceed
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

ElectricDetails.propTypes = {
  getProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfile })(ElectricDetails);
