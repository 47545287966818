import React from "react";
import AllRatesMain from "../content/all-rates/components/AllRatesMain";

function AllRatesCalculator () {

  return (
    <AllRatesMain />
  )
}

export default AllRatesCalculator;