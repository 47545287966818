import React, { useEffect } from "react";
import useGlobalContext from "../../../custom-hook/Context";
import MainHeader from "../../../layouts/header/components/MainHeader";
import RatesCalculatorContent from "./RatesCalculatorContent";
import "../../styles/home-main.css";
import "../styles/rates-calculator.css";

// import user from "../../../asset/user-image x2.png";

function AllRatesMain() {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return (
    <main className="main">
      <MainHeader text="All Rates Calculator" />
      <div className="main-content row">
        <div className="col-12 col-md-11 mx-auto">
          <RatesCalculatorContent />
        </div>
      </div>
    </main>
  );
}

export default AllRatesMain;
