import React, { useEffect } from "react";
import useGlobalContext from "../../custom-hook/Context";
import MainHeader from "../../layouts/header/components/MainHeader";
import SavedBanks from "./SavedBanks";

const SavedBanksMain = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return (
    <main className="main">
      <MainHeader text="Bank details" />
      <SavedBanks />
    </main>
  );
};

export default SavedBanksMain;
