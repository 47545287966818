import React, {useEffect} from 'react'
import useGlobalContext from "../custom-hook/Context";
import BankDetailsMain from '../content/component/BankDetailsMain'

const BankDetails = () => {
    const { checkAuth } = useGlobalContext();

    useEffect(() => {
      checkAuth();
    }, [checkAuth]);
    return (
        <div>
            <BankDetailsMain/>
        </div>
    )
}

export default BankDetails
