import React from 'react'
import { useLottie } from "lottie-react";
import animationData from './Alpha-Level-Coin.json';

export default function LottieAnimation() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
      const { View } = useLottie(defaultOptions);
      return (
        <div style={{ width: "100px", height: "100px" }} >
          {View}
        </div>
      );
  }
