import React from "react";
import MainHeader from "../../../layouts/header/components/MainHeader";
import useGlobalContext from "../../../custom-hook/Context";
import ProfileModal from "./ProfileModal";


import "../style/edit-profile.css";

// import user from "../../../asset/user-image x2.png";
import ProfileForm from "./ProfileForm";
import ProfileHeader from "./ProfileHeader";

const EditProfileMain = () => {

  const {profileState, hideDropdown} = useGlobalContext();

  const selectAvatar = (id) => {

  } 

  return (
    <main>
         <MainHeader text="Edit Profile" />
         <section className="profile-wrapper" onClick={hideDropdown}>
        <section className="profile-content mx-auto">
          <ProfileHeader 
            avatar={profileState.avatar.image}
          />
          <ProfileForm/>
        </section>
        <ProfileModal 
          avatar={profileState.avatar.image}
          selectAvatar={selectAvatar}
        />
      </section>
    </main>
  )
}


export default EditProfileMain;
