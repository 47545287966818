import React from "react";
import icon from "../../asset/trophy-svgrepo-com.svg";

const LeaderList = ({ profile_pics, username, points, rank }) => {

  return (  
    <li className="leader-item">
      <div className="leader-avatar-wrapper">
        <img src={profile_pics} className="" alt="" />
      </div>
      <div className="leader-name">
        <p>{username}</p>
       <div className="leader-name-con"> <p>{`${points} pts`}</p>
           <img src={icon} className="" alt="" /></div>
      </div>
      <div className="leader-icon-wrapper">
        <p>{rank}</p>
     
      </div>
    </li>
  )
}

export default LeaderList;