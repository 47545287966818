import React, { useEffect } from "react";
import useGlobalContext from "../custom-hook/Context";
import PrivacyMain from "../content/privacy/components/PrivacyMain";

const Privacy = () => {
  const { checkAuth } = useGlobalContext();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  return (
    <div>
      <PrivacyMain />
    </div>
  );
};

export default Privacy;
