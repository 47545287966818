import React from "react";
import SidebarLink from "./sidebarLink";
import "../styles/sidebar.css";
import logo from "../../../asset/Alternate Redem Logo (New).png";
import appDownload from "../../../asset/Download The App.png";
import useGlobalContext from "../../../custom-hook/Context";

const SideBar = () => {
  const { showSidebar } = useGlobalContext();

  return (
    <aside className={`sidebar ${showSidebar ? "show-sidebar" : ""}`}>
      <div className="sidebar-wrapper">
        <header className="logo">
          <img src={logo} alt="Redem Logo" />
        </header>
        <SidebarLink />
        <div className="sidebar-footer">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="sidebar-footer-content">
                  <img src={appDownload} alt="" />
                  <p>Download the app</p>
                </div>
              </div>
              <div className="carousel-item">
                <div className="sidebar-footer-content">
                  <img src={appDownload} alt="" />
                  <p>Download the app</p>
                </div>
              </div>
              <div className="carousel-item">
                <div className="sidebar-footer-content">
                  <img src={appDownload} alt="" />
                  <p>Download the app</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
